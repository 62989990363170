<template>
  <footer class="footer">
    <div class="wrapper">
      <div>
        <img src="/img/logo_footer.svg" alt="">
      </div>
      <div>
        <router-link to="schools">Школы</router-link >
        <router-link to="courses">Курсы</router-link >
        <router-link to="for-business">Business</router-link >
      </div>
      <div>
        <div class="group">
          <span>Адрес:</span>
          <a href="https://goo.gl/maps/iS5SteSszTM5yBCw8" target="_blank">г. Алматы, улица Байзакова 280, 34 офис</a>
        </div>
        <div class="group">
          <span>Контакты:</span>
          <a href="tel:+7 747 858 95 95">+7 747 858 95 95</a>
        </div>
      </div>
      <div>
        <div class="group">
          <span>Техническая поддержка:</span>
          <a href="tel:+77082624586">+7 708 262 4586</a>
        </div>
        <div class="group">
          <span>Электронная почта:</span>
          <a href="mailto:info@codetau.kz">info@codetau.kz</a>
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="group social">
            <a href="https://instagram.com/aclass.kz?utm_medium=copy_linkl" target="_blank">
              <SvgIcon name="instagram" :viewboxHeight="31" :viewboxWidth="32" :height="31" :width="32" fill="#fff" />
            </a>
            <a href="https://www.youtube.com/channel/UCDX42MoqVOICyqL-D0eWqVw" target="_blank">
              <SvgIcon name="youtube" :viewboxHeight="32" :viewboxWidth="48" :height="32" :width="48" fill="#fff"/>
            </a>
          </div>
          <div class="docs">
            <button @click="show.policy = true">Политика конфиденциальности</button>
            <button @click="show.contract = true">Договор оферты</button>
          </div>
        </div>
        <div class="group">
          <span>Все права защищены.</span>
          <a class="codetau" href="https://codetau.com" target="_blank">© разработка. ТОО CodeTau</a>
        </div>
      </div>
    </div>
  </footer>
  <PolicyModal v-if="show.policy" @hide="show.policy = false"/>
  <ContractOfferModal v-if="show.contract" @hide="show.contract = false"/>
</template>

<script setup>
import { reactive } from 'vue-demi'
import ContractOfferModal from '../Docs/ContractOfferModal.vue'
import PolicyModal from '../Docs/PolicyModal.vue'

const show = reactive({
  policy: false,
  contract: false
})
</script>

<style scoped>
.footer {
  width: 100%;
  background: #161931;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
img {
  width: 80px;
}
span, b, h2, a {
  color: #fff;
  position: relative;
  width: fit-content;
}
a::after {
  content: '';
  position: absolute;
  display: block;
  height: 0.15em;
  width: 0%;
  background-color: #fff;
  transition: all ease-in-out .1s;
}
a:hover::after {
  width: 100%;
}
a.router-link-active::after {
  width: 100%;
}
.group {
  gap: 0!important;
  justify-content: initial!important;
}
.wrapper div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}
.group.social {
  flex-direction: row;
}
.group.social a::after {
  content: none;
}
.flex {
  display: flex;
  flex-direction: row!important;
}
.docs {
  display: flex;
  gap: 7px!important;
}
.docs button {
  text-align: start;
  color: #fff;
}
</style>
