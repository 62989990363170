<template>
  <div class="prefered-days" :class="{notEditable: !editable}">
    <div class="morning">Утро</div>
    <div class="day">День</div>
    <div class="evening">Вечер</div>

    <span class="empty"></span>
    <span
      class="headers"
      v-for="day of weekdays"
      :key="day"
    >
      {{ day }}
    </span>
    <div
      class="cell"
      v-for="day of weekdaysEng"
      :key="'morning--' + day"
    >
      <div
        v-if="group"
        :class="getPercentClass(morning[day].percent)"
        :data-tooltip="`${morning[day].count} студент(a/ов) предпочитают это время (${morning[day].percent}%)`"
        :data-tooltip-location="day === 'su' ? 'left' : 'top'"
      />
      <div v-else>
        <input
          :key="`morning-${day}`"
          :id="`morning-${day}`"
          type="checkbox"
          class="checkbox"
          v-model="preferedTimes.morning[day]"
        >
        <label
          :key="day"
          :for="`morning-${day}`"
          v-wave
        />
      </div>
    </div>

    <div
      class="cell"
      v-for="day of weekdaysEng"
      :key="'day--' + day"
    >
      <div
        v-if="group"
        :class="getPercentClass($props.day[day].percent)"
        :data-tooltip="`${$props.day[day].count} студент(a/ов) предпочитают это время (${$props.day[day].percent}%)`"
        :data-tooltip-location="day === 'su' ? 'left' : 'top'"
      />
      <div v-else>
        <input
          :key="`day-${day}`"
          :id="`day-${day}`"
          type="checkbox"
          class="checkbox"
          v-model="preferedTimes.day[day]"
        >
        <label
          :key="day"
          :for="`day-${day}`"
          v-wave
        />
      </div>
    </div>

    <div
      class="cell"
      v-for="day of weekdaysEng"
      :key="'evening--' + day"
    >
      <div
        v-if="group"
        :class="getPercentClass(evening[day].percent)"
        :data-tooltip="`${evening[day].count} студент(a/ов) предпочитают это время (${evening[day].percent}%)`"
        :data-tooltip-location="day === 'su' ? 'left' : 'top'"
      />
      <div v-else>
        <input
          :key="`evening-${day}`"
          :id="`evening-${day}`"
          type="checkbox"
          class="checkbox"
          v-model="preferedTimes.evening[day]"
        >
        <label
          :key="day"
          :for="`evening-${day}`"
          v-wave
        />
      </div>
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue-demi'

const props = defineProps({
  morning: {
    type: [Array, Object]
  },
  day: {
    type: [Array, Object]
  },
  evening: {
    type: [Array, Object]
  },
  editable: {
    type: Boolean,
    default: true
  },
  group: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits([
  'update:morning',
  'update:day',
  'update:evening'
])

const weekdays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
const weekdaysEng = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
const preferedTimes = ref({
  morning: { mo: false, tu: false, we: false, th: false, fr: false, sa: false, su: false },
  day: { mo: false, tu: false, we: false, th: false, fr: false, sa: false, su: false },
  evening: { mo: false, tu: false, we: false, th: false, fr: false, sa: false, su: false }
})

const getPreferedTimes = preferedTimes => {
  if (props.group) return preferedTimes
  return {
    morning: Object.keys(preferedTimes.morning).filter(key => preferedTimes.morning[key]),
    day: Object.keys(preferedTimes.day).filter(key => preferedTimes.day[key]),
    evening: Object.keys(preferedTimes.evening).filter(key => preferedTimes.evening[key])
  }
}

const setPreferedTimes = modelValue => {
  if (props.group) return modelValue
  const result = {
    morning: {},
    day: {},
    evening: {}
  }
  weekdaysEng.forEach(day => {
    result.morning[day] = modelValue.morning.includes(day)
    result.day[day] = modelValue.day.includes(day)
    result.evening[day] = modelValue.evening.includes(day)
  })
  return result
}

const getPercentClass = percent => {
  if (percent < 30) return 'low'
  else if (percent < 60) return 'medium'
  else return 'high'
}

watch(
  () => preferedTimes.value,
  () => {
    const emittedValue = getPreferedTimes(preferedTimes.value)
    emit('update:morning', emittedValue.morning)
    emit('update:day', emittedValue.day)
    emit('update:evening', emittedValue.evening)
  },
  {
    deep: true
  }
)

onMounted(() => {
  preferedTimes.value = setPreferedTimes({
    morning: props.morning,
    day: props.day,
    evening: props.evening
  })
})
</script>

<style scoped>
.prefered-days {
  display: grid;
  grid-template-columns: repeat(8, auto);
  gap: 20px;
  padding: 10px;
}
.prefered-days.notEditable {
  pointer-events: none
}

span {
  justify-self: center;
}
.morning {
  background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0666 24.6464C19.828 24.6464 23.6879 20.7865 23.6879 16.0251C23.6879 11.2637 19.828 7.40381 15.0666 7.40381C10.3052 7.40381 6.44531 11.2637 6.44531 16.0251C6.44531 20.7865 10.3052 24.6464 15.0666 24.6464Z' fill='%23FFC833'/%3E%3Cpath d='M21.4373 27.5959C22.8269 27.597 24.1894 27.2105 25.3715 26.4798C26.5535 25.7491 27.5083 24.7031 28.1286 23.4596C28.7489 22.216 29.01 20.8241 28.8826 19.4402C28.7552 18.0564 28.2444 16.7355 27.4075 15.6261C26.5706 14.5167 25.4409 13.6627 24.1453 13.16C22.8497 12.6574 21.4396 12.5261 20.0734 12.781C18.7073 13.0358 17.4394 13.6666 16.4121 14.6024C15.3848 15.5383 14.6389 16.7422 14.2582 18.0787C13.5843 17.6164 12.8113 17.319 12.0014 17.2103C11.1915 17.1017 10.3673 17.1848 9.59544 17.4531C8.82355 17.7213 8.12549 18.1672 7.55754 18.7547C6.98958 19.3423 6.56762 20.055 6.32567 20.8356C6.08372 21.6161 6.02854 22.4426 6.1646 23.2484C6.30066 24.0541 6.62413 24.8167 7.10895 25.4745C7.59377 26.1323 8.22637 26.667 8.95576 27.0355C9.68514 27.404 10.4909 27.5959 11.3081 27.5959H21.4373Z' fill='%23B7C5FF'/%3E%3C/svg%3E%0A");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 48px;
  grid-column-start: 1;
  grid-row-start: 2;
  align-self: center;
  width: fit-content;
}
.day {
  background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2596 27.7413C22.9484 27.7413 27.5602 23.1296 27.5602 17.4407C27.5602 11.7519 22.9484 7.14014 17.2596 7.14014C11.5707 7.14014 6.95898 11.7519 6.95898 17.4407C6.95898 23.1296 11.5707 27.7413 17.2596 27.7413Z' fill='%23FFC833'/%3E%3C/svg%3E%0A");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 48px;
  grid-column-start: 1;
  grid-row-start: 3;
  align-self: center;
  width: fit-content;
}
.evening {
  background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.1906 19.774C23.6792 19.7752 22.1867 19.4379 20.8228 18.7867C19.4589 18.1355 18.2582 17.187 17.309 16.0109C16.3597 14.8348 15.686 13.461 15.3374 11.9904C14.9887 10.5198 14.9739 8.98976 15.2941 7.5127C13.5311 7.92793 11.9117 8.80864 10.605 10.0629C9.29826 11.3173 8.35202 12.8992 7.86498 14.6438C7.37795 16.3884 7.36795 18.2317 7.83603 19.9815C8.30411 21.7313 9.23313 23.3234 10.5262 24.5918C11.8192 25.8602 13.4289 26.7585 15.1874 27.1928C16.9459 27.6271 18.7887 27.5817 20.5236 27.0612C22.2585 26.5407 23.8219 25.5642 25.0509 24.2336C26.2798 22.9029 27.1292 21.2669 27.5104 19.4962C26.7504 19.6778 25.972 19.771 25.1906 19.774Z' fill='%236583FE'/%3E%3C/svg%3E%0A");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 48px;
  grid-column-start: 1;
  grid-row-start: 4;
  align-self: center;
  width: fit-content;
}

.cell {
  position: relative;
}
input {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  opacity: 0;
}
input + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  width: 100%;
}

input:checked + label::after,
.high {
  background: rgba(0, 219, 127, 0.3);
  border: 1px solid rgba(0, 133, 77, 0.5);
  box-shadow: 0px 3px 5px rgba(166, 168, 180, 0.1);
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='17' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9.072L7.12874 15.0513L21.18 1' stroke='%2300854D' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center center;
  background-repeat: no-repeat;
  padding: 23px 0;
  width: 100%;
}
.medium {
  content: "";
  background: rgba(255, 177, 64, 0.2);
  border: 1px solid rgba(255, 177, 64, 0.5);
  box-shadow: 0px 3px 5px rgba(166, 168, 180, 0.1);
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711ZM10.2929 11.7071L20.2929 21.7071L21.7071 20.2929L11.7071 10.2929L10.2929 11.7071ZM20.2929 0.292893L10.2929 10.2929L11.7071 11.7071L21.7071 1.70711L20.2929 0.292893ZM10.2929 10.2929L0.292893 20.2929L1.70711 21.7071L11.7071 11.7071L10.2929 10.2929Z' fill='%23FFB140'/%3E%3C/svg%3E%0A");
  background-position: center center;
  background-repeat: no-repeat;
  padding: 23px 0;
  cursor: default;
  width: 100%;
}
.high, .low {
  cursor: default!important;
}
label::after,
.low {
  content: "";
  background: rgba(255, 126, 184, 0.2);
  border: 1px solid rgba(255, 126, 184, 0.5);
  box-shadow: 0px 3px 5px rgba(166, 168, 180, 0.1);
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711ZM10.2929 11.7071L20.2929 21.7071L21.7071 20.2929L11.7071 10.2929L10.2929 11.7071ZM20.2929 0.292893L10.2929 10.2929L11.7071 11.7071L21.7071 1.70711L20.2929 0.292893ZM10.2929 10.2929L0.292893 20.2929L1.70711 21.7071L11.7071 11.7071L10.2929 10.2929Z' fill='%23FF7EB8'/%3E%3C/svg%3E%0A");
  background-position: center center;
  background-repeat: no-repeat;
  padding: 23px 0;
  cursor: pointer;
  width: 100%;
}
</style>
