<template>
  <div class="main">
    <h1>
      <span class="title">Помощь</span>
    </h1>
  </div>
</template>

<style scoped>
.main {
  padding: 20px 30px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 20px;
}
</style>
