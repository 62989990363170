<template>
  <div class="view-type">
    <button class="grid" :class="{active: viewTypeGrid}" @click="() => { viewTypeGrid = true }">
      <SvgIcon name="button-grid" />
    </button>
    <button class="list" :class="{active: !viewTypeGrid}" @click="() => { viewTypeGrid = false }">
      <SvgIcon name="button-list"/>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean
  }
})

const emit = defineEmits(['update:modelValue'])

const viewTypeGrid = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emit('update:modelValue', value)
  }
})
</script>

<style scoped lang="scss">
.view-type {
  display: flex;
  align-items: center;
  gap: 10px;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 4px;
    transition: all .2s ease;

    &.active {
      background: #F1F3F4;
      & svg {
        fill: #000;
      }
    }
  }

  & svg {
    fill: rgba(22, 25, 49, 0.5);
    transition: all .2s ease;
  }
}
</style>
