<template>
  <div class="wrapper-system">
    <main>
      <div class="right-content">
        <h2>Список чеков на оплату</h2>
        <table class="cart-list" v-if="isLoaded.orders">
          <tr>
            <th>Курс</th>
            <th>Сумма</th>
            <th></th>
          </tr>
          <tr v-for="order of orders" :key="order.id">
            <td>{{ order.payable?.name }}</td>
            <td>{{ order.amount }}</td>
            <td class="btns">
              <button class="btn pay" :class="{ disabled: isLoading.payment }" @click="payCourse(order)">
                <div class="lds-facebook" v-if="isLoading.payment === order.id"><div></div><div></div><div></div></div>
                <span v-else>Оплатить</span>
              </button>
              <button class="btn delete" @click="showDeleteOrderModal(order)">
                <span>Удалить</span>
              </button>
            </td>
            <hr>
          </tr>
        </table>
        <div class="empty orders" v-if="!orders.length && isLoaded.orders">
          <span>Пусто</span>
        </div>
        <table class="cart-list disabled" v-if="!isLoaded.orders">
          <tr>
            <th><span class="skeleton small-text"></span></th>
            <th><span class="skeleton small-text"></span></th>
            <th></th>
          </tr>
          <tr v-for="item in 7" :key="item">
            <td><span class="course-name skeleton middle-text"></span></td>
            <td><span class="skeleton small-text"></span></td>
            <td class="btns">
              <button class="btn pay">
                <span class="skeleton small-text"></span>
              </button>
              <button class="btn delete">
                <span class="skeleton small-text"></span>
              </button>
            </td>
            <hr>
          </tr>
        </table>

        <h2>Добавить карту</h2>
        <div class="addCardInner"  v-if="isLoaded.cards">
          <button class="addCard" :class="{ disabled: isLoading.addCard }" @click="addCard()">
            <SvgIcon name="add" :viewboxWidth="17" :width="17" :viewboxHeight="17" :height="17" v-if="!isLoading.addCard"/>
            <div class="lds-ellipsis" v-else><div></div><div></div><div></div><div></div></div>
          </button>
          <CardsList :cards="cards" />
        </div>
        <div class="addCardInner disabled" v-else>
          <button class="addCard skeleton">
          </button>
        </div>

      </div>
      <div>
        <h2>Список платежей</h2>
        <section class="payment-history" v-if="isLoaded.payments">
          <div class="view-type">
            <button class="week" :class="{active: activePaymentDateRange === 'week'}" @click="() => { getOrdersListAtRange([moment().subtract(1, 'w').format(), moment().format()]); activePaymentDateRange = 'week'}">
              Неделя
            </button>
            <button class="month" :class="{active: activePaymentDateRange === 'month'}" @click="() => { getOrdersListAtRange([moment().subtract(1, 'M').format(), moment().format()]); activePaymentDateRange = 'month'}">
              Месяц
            </button>
            <button class="three-months" :class="{active: activePaymentDateRange === 'three-months'}" @click="() => { getOrdersListAtRange([moment().subtract(3, 'M').format(), moment().format()]); activePaymentDateRange = 'three-months'}">
              Три месяца
            </button>
            <button class="half-a-year" :class="{active: activePaymentDateRange === 'half-a-year'}" @click="() => { getOrdersListAtRange([moment().subtract(2, 'Q').format(), moment().format()]); activePaymentDateRange = 'half-a-year'}">
              Полгода
            </button>
            <!-- <button class="period">
              Период
            </button> -->
          </div>
          <div>
            <!-- <div class="receipts" v-for="value in 2" :key="value">
              <div class="date">25 августа</div> -->
              <div v-if="payments.length">
                <div class="receipt" v-for="item in payments" :key="item">
                  <div class="name">
                    <div class="preview">
                      <SvgIcon name="card" :viewboxWidth="20" :width="20" :viewboxHeight="20" :height="20" />
                    </div>
                    <h4 class="title">{{ item.payable?.name }}</h4>
                  </div>
                  <div class="price">
                    <SvgIcon name="watch" :viewboxWidth="16" :width="16" :viewboxHeight="16" :height="16" />
                    <div class="total">{{ item.amount }} тг</div>
                  </div>
                </div>
              </div>
              <div class="empty" v-else>
                <span>Пусто</span>
              </div>
            <!-- </div> -->
          </div>
        </section>
        <section class="payment-history disabled" v-else>
          <div class="view-type">
            <button class="week" :class="{active: activePaymentDateRange === 'week'}">
              Неделя
            </button>
            <button class="month" :class="{active: activePaymentDateRange === 'month'}">
              Месяц
            </button>
            <button class="three-months" :class="{active: activePaymentDateRange === 'three-months'}">
              Три месяца
            </button>
            <button class="half-a-year" :class="{active: activePaymentDateRange === 'half-a-year'}">
              Полгода
            </button>
            <!-- <button class="period">
              Период
            </button> -->
          </div>
          <div>
            <div class="receipts">
              <div class="receipt" v-for="item in 10" :key="item">
                <div class="name">
                  <div class="preview skeleton">
                  </div>
                  <h4 class="title skeleton middle-text"></h4>
                </div>
                <div class="price">
                  <div class="total skeleton small-text"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <transition name="modal" mode="out-in">
      <Modal
        :hide="() => deleteOrderModalIsActive = false"
        :footer="true"
        :onOk="deleteOrder"
        v-if="deleteOrderModalIsActive"
      >
        <h2>Вы действительно хотите удалить заказ?</h2>
      </Modal>
    </transition>
  </div>
</template>

<script setup>
import api, { appUrl } from '@/modules/api'
import { useAuth } from '@/modules/auth'
import { onMounted, reactive, ref, watch } from 'vue-demi'
import Modal from '@/components/common/Modal.vue'
import moment from 'moment'
import CardsList from '@/components/uiClient/Billing/CreditCards/CardsList.vue'

const { user } = useAuth()
const orders = ref([])
const payments = ref([])
const cards = ref([])
const deleteOrderModalIsActive = ref(false)
const isLoading = reactive({
  addCard: false,
  payment: false
})
const isLoaded = reactive({
  orders: false,
  payments: false,
  cards: false
})
const activePaymentDateRange = ref('week')
const selectedOrderToDelete = ref()

const getData = () => {
  if (user.value.data.id) {
    api.requestGet(
      '/api/orders',
      {
        finished: 0,
        user_id: user.value.data.id
      },
      response => {
        orders.value = response.data.data
        isLoaded.orders = true
      }
    )
    api.requestGet(
      '/api/user-cards',
      {},
      response => {
        cards.value = response.data.data
        isLoaded.cards = true
      }
    )
    api.requestGet(
      '/api/orders',
      {
        user_id: user.value.data.id,
        finished: 1,
        finished_at_range: [moment().subtract(1, 'w').format(), moment().format()]
      },
      response => {
        payments.value = response.data.data
        isLoaded.payments = true
      }
    )
  }
}

const getOrdersListAtRange = range => {
  isLoaded.payments = false
  api.requestGet(
    '/api/orders',
    {
      user_id: user.value.data.id,
      finished: 1,
      finished_at_range: range
    },
    response => {
      payments.value = response.data.data
      isLoaded.payments = true
    }
  )
}

onMounted(() => getData())

watch(
  () => user,
  () => {
    getData()
  },
  {
    deep: true
  }
)

const addCard = () => {
  if (!isLoading.addCard) {
    api.requestPost(
      '/api/payments/paybox/save-card',
      {
        back_url: `${appUrl}/learning/billing`
      },
      response => {
        window.location.href = response.data.data.redirect_url
      },
      errorResponse => {
        isLoading.addCard = false
      }
    )
  }
  isLoading.addCard = true
}

const payCourse = order => {
  if (!isLoading.payment) {
    api.requestPost(
      '/api/payments/paybox/init-payment',
      {
        order_id: order.id,
        success_url: `${appUrl}/learning/my-courses`,
        failure_url: `${appUrl}/course/${order.payable.id}`
      },
      response => {
        window.location.href = response.data.data.redirect_url
      }
    )
  }
  isLoading.payment = order.id
}

// const isAllLoaded = () => {
//   return orders.value && cards.value
// }

const showDeleteOrderModal = order => {
  deleteOrderModalIsActive.value = true
  selectedOrderToDelete.value = order.id
}

const deleteOrder = () => {
  api.requestDelete(
    `/api/orders/${selectedOrderToDelete.value}`,
    {},
    response => {
      deleteOrderModalIsActive.value = false
      selectedOrderToDelete.value = null
      isLoaded.orders = false
      api.requestGet(
        '/api/orders',
        {
          finished: 0,
          user_id: user.value.data.id
        },
        response => {
          orders.value = response.data.data
          isLoaded.orders = true
        }
      )
    }
  )
}
</script>

<style scoped>
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 20px;
}
h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  margin-bottom: 15px;
}
.cart-list .btns{
  display: flex;
  justify-content: space-between;
}
.cart-list .btn{
  min-width: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width .2s linear;
}
.cart-list .btn span{
  color: #fff;
  font-size: 16px;
  transition: color .2s linear;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 4px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: #fed;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 0px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 16px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 32px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: -4px;
  }
  50%, 100% {
    top: 4px;
  }
}
table {
  width: 100%;
  height: fit-content;
  background: var(--accent-color);
  border-collapse: collapse;
  margin-bottom: 30px;
}
table th {
  background: #161931cc;
}
table tr {
  position: relative;
}
table tr hr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 95%;
  bottom: 0;
  right: 0;
  position: absolute;
  transform: translateX(-2.5%);
}
table th,
table td {
  color: #fff;
  padding: 13px 20px;
  text-align: start;
}
.options {
  cursor: pointer;
}
.payment-history{
  padding: 0 10px 3px;
  background-color: #F0F0F0;
}
.payment-history .view-type{
  display: flex;
  justify-content:space-around;
  background-color: #fff;
  margin-bottom: 15px;
}
.payment-history .view-type button{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  color: inherit;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 0;
  background-color: #fff;
  transition: color .2s linear;
}
.payment-history .view-type button::before{
  content: "";
  display: block;
  width: 0;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: transparent;
  transition: width .2s linear, background-color .2s linear;
}
.payment-history button:hover{
  color: var(--accent-color);
}
.payment-history button.active{
  color: var(--accent-color);
}
.payment-history button.active::before{
  width: 100%;
  background-color: var(--accent-color);
}
.payment-history .receipts{
  margin-bottom: 13px;
}
.payment-history .date{
  color: rgba(22, 25, 49, 0.5);
  font-size: 14px;
  font-weight: 600;
  font-family: 'SF Pro Display';
  margin-bottom: 5px;
}
.payment-history .receipt{
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 9px;
  border-bottom: 1px solid rgba(#161931, .5);
  background-color: #fff;
}
.payment-history .name{
  display: flex;
  align-items: center;
}
.payment-history .name .preview{
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 100px;
  background-color: #F0F0F0;
}
.payment-history .name .preview svg{
  fill: #000;
}
.payment-history .price{
  display: flex;
  align-items: center;
}
.payment-history .price svg{
  margin-right: 15px;
}
.addCard{
  width: 150px;
  min-height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 2px solid #fff;
  border-radius: 10px;
  background-color: #0082FA;
  transition: background-color .2s linear, border-color .2s linear;
}
.addCard.disabled{
  pointer-events: none;
  opacity: .7;
}
.addCard svg{
  fill: #fff;
  transition: fill .2s linear, transform .2s linear;
}
.addCard:hover{
  border-color: #0082FA;
  background-color: #fff;
}
.addCard:hover svg{
  fill: #0082FA;
  transform: rotate(90deg);
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 7px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* SKELETON */
.disabled {
  pointer-events: none;
}
table.disabled {
  background-color: #fff !important;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
}
table.disabled tr:first-child th{
  background-color: #c9c9c9cc;
}
/* MODAL */
.modal-enter-active,
.modal-leave-active {
  transition: all .3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.empty span {
  color: var(--cancel-color);
  font-size: 20px;
}
.empty.orders {
  margin-top: -30px;
  background: #f0f0f0;
  padding: 10px;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<style>
.card-item {
  margin: 0 !important;
}
.card-item * {
  color: #fff;
}
.card-item__cover{
  background-color: #385898 !important;
}
.card-item__cover::after{
  display: none;
}
</style>
