<template>
  <div></div>
</template>
<script>
export default {
  name: 'Forum',
  components: {
  },
  props: {
  },
  setup () {
    return {
    }
  }
}
</script>
<style scoped>
</style>
