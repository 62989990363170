<template>
  <div>
    <Transition mode="out-in">
      <div class="grid" v-if="viewType === 'grid'">
        <div class="groups" id="groups-grid" v-if="tableValues.length > 0">
          <div
            v-for="(group, index) in tableValues"
            :key="group.id"
            class="card"
            v-wave
            @click="goGroupInfo(group.id)"
          >
              <Transition name="list">
                <div
                  class="preferedDays-card"
                  :class="(index + 1) % 4 === 0 || (index + 1) % 4 === 3 ? 'left' : ''"
                  v-if="groupPreferedDaysIsShown === group.id"
                  v-collapse
                >
                  <PreferedDays
                    v-model:morning="group.preferred_weekdays.morning"
                    v-model:day="group.preferred_weekdays.day"
                    v-model:evening="group.preferred_weekdays.evening"
                    group
                  />
                </div>
              </Transition>
            <span class="title">
              {{ group.name }}
              <button class="options" @click.stop>
                  <SvgIcon name="dots" class="dots"/>

                  <ul class="options-dropdown">
                    <li
                      class="prefered-days"
                      @click="toggleGroupPreferedDays(group.id)"
                      v-wave
                    >
                      {{
                        groupPreferedDaysIsShown !== group.id
                          ? 'Показать удобные дни'
                          : 'Cкрыть удобные дни'
                      }}
                    </li>
                    <li v-wave @click="showAttachTeacherModal(group)">
                      Прикрепить куратора
                    </li>
                    <li class="delete" v-wave>Удалить</li>
                  </ul>
                </button>
            </span>
            <span class="course">Курс: {{ group.course.name }}</span>
            <div class="footer">
              <div class="item">
                <SvgIcon
                  name="group"
                  :viewboxHeight="16"
                  :viewboxWidth="16"
                  :height="16"
                  :width="16"
                />
                <span>{{ group.users.length }}</span>
              </div>
              <div class="item">
                <SvgIcon
                  name="group-calendar"
                  :viewboxHeight="16"
                  :viewboxWidth="16"
                  :height="16"
                  :width="16"
                />
                <span>{{ moment(group.date).format('DD.MM.YYYY') }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="groups disabled" v-else>
          <div v-for="group in 12" :key="group" class="card">
            <span class="title skeleton small-text"></span>
            <span class="course skeleton middle-text"></span>
            <div class="footer">
              <div class="item">
                <SvgIcon
                  name="group"
                  :viewboxHeight="16"
                  :viewboxWidth="16"
                  :height="16"
                  :width="16"
                />
                <span class="skeleton"></span>
              </div>
              <div class="item">
                <SvgIcon
                  name="group-calendar"
                  :viewboxHeight="16"
                  :viewboxWidth="16"
                  :height="16"
                  :width="16"
                />
                <span class="skeleton"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="list" v-if="tableValues.length > 0" :class="{ loading }">
          <div class="group" v-for="group of tableValues" :key="group.id">
            <Transition mode="out-in">
              <div class="group__summary loading" v-if="dragging.dragoverGroupId === group.id && dragging.userId && loading">
                <Loading/>
                <SvgIcon/>
              </div>
              <div
                class="group__summary dropping"
                v-else-if="dragging.dragoverGroupId === group.id && dragging.userId"
              >
                Переместить в группу {{ group.name }}
              </div>
              <div
                v-else
                class="group__summary"
                @click="goGroupInfo(group.id)"
                @dragover="dragging.dragoverGroupId = group.id"
              >
                <SvgIcon
                  class="group__chevron"
                  :class="expandedGroupId === group.id ? 'expanded' : ''"
                  name="chevron-down"
                  :viewboxHeight="56"
                  :viewboxWidth="56"
                  @click.stop="
                    expandedGroupId === group.id
                      ? (expandedGroupId = null)
                      : (expandedGroupId = group.id)
                  "
                />
                <span class="group__name">
                  {{ group.name }}
                </span>
                <span class="group__course">
                  {{ group.course.name }}
                </span>
                <span class="group__students-count">
                  <SvgIcon
                    name="users"
                    :viewboxHeight="18"
                    :viewboxWidth="18"
                    :height="12"
                    :width="12"
                    fill="black"
                  />
                  {{ group.users.length }}
                </span>
                <button class="options" @click.stop>
                  <SvgIcon name="dots" class="dots"/>

                  <ul class="options-dropdown">
                    <li
                      class="prefered-days"
                      @click="toggleGroupPreferedDays(group.id)"
                      v-wave
                    >
                      {{
                        groupPreferedDaysIsShown !== group.id
                          ? 'Показать удобные дни'
                          : 'Cкрыть удобные дни'
                      }}
                    </li>
                    <li v-wave @click="showAttachTeacherModal(group)">
                      Прикрепить куратора
                    </li>
                    <li class="delete" v-wave>Удалить</li>
                  </ul>
                </button>
              </div>
            </Transition>
            <Transition name="list">
              <div
                class="preferedDays"
                v-if="groupPreferedDaysIsShown === group.id"
                v-collapse
              >
                <PreferedDays
                  v-model:morning="group.preferred_weekdays.morning"
                  v-model:day="group.preferred_weekdays.day"
                  v-model:evening="group.preferred_weekdays.evening"
                  group
                />
              </div>
            </Transition>
            <Transition name="list" mode="out-in">
              <div
                class="group__users"
                v-if="expandedGroupId === group.id"
                @dragover="dragging.dragoverGroupId = null"
                v-collapse
              >
                <Draggable
                  v-model="group.users"
                  item-key="id"
                  tag="transition-group"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                  :component-data="{
                    type: 'transition-group',
                    name: !drag ? 'flip-list' : null
                  }"
                >
                  <template #item="{ element }">
                    <div
                      class="group__user"
                      @drag="onUserDrag(element.id)"
                      @dragend="onUserDragEnd"
                    >
                      <img src="/img/tmp/default_avatar.png" alt="" />
                      <span class="user__name">{{ element.name }}</span>
                      <span class="user__birthday">{{
                        moment(element.birthday).format('DD.MM.YYYY')
                      }}</span>
                      <a
                        :href="`mailto:${element.email}`"
                        class="user__email"
                        >{{ element.email }}</a
                      >
                      <a :href="`tel:${element.phone}`" class="user__phone">{{
                        element.phone
                      }}</a>
                      <SvgIcon
                        name="calendar"
                        class="calendar"
                        :viewboxHeight="18"
                        :viewboxWidth="18"
                        @click="toggleUserPreferedDays(element.id)"
                        data-tooltip="Нажмите чтобы увидеть удобные дни студента"
                        data-tooltip-location="left"
                      />
                      <div
                        class="preferedDays student"
                      >
                        <Transition name="list">
                          <PreferedDays
                            v-collapse
                            v-if="userPreferedDayIsShown === element.id && element.preferred_weekdays"
                            v-model:morning="element.preferred_weekdays.morning"
                            v-model:day="element.preferred_weekdays.day"
                            v-model:evening="element.preferred_weekdays.evening"
                            :editable="false"
                          />
                          <PreferedDays
                            v-collapse
                            v-else-if="userPreferedDayIsShown === element.id"
                            v-model:morning="groupPreferedDays.morning"
                            v-model:day="groupPreferedDays.day"
                            v-model:evening="groupPreferedDays.evening"
                            :editable="false"
                          />
                        </Transition>
                      </div>
                    </div>
                  </template>
                </Draggable>
              </div>
            </Transition>
          </div>
        </div>
        <div class="list" v-else>
          <div class="group" v-for="group in 10" :key="group">
            <div class="group__summary skeleton">
              <SvgIcon />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition>
      <AttachTeacherModal
        v-if="attachTeacherModalIsActive"
        :hide="() => { attachTeacherModalIsActive = false }"
        @success="reloadTable"
        :group="attachTeacherGroup"
      />
    </Transition>
  </div>
</template>

<script setup>
import moment from 'moment'
import { reactive, ref } from 'vue-demi'
import { useRouter } from 'vue-router'
import Draggable from 'vuedraggable'

import api from '@/modules/api'

import PreferedDays from '@/components/PreferedDays.vue'
import Loading from '@/components/common/Loading.vue'
import AttachTeacherModal from '@/components/uiBusiness/Groups/AttachCuratorModal.vue'

const props = defineProps({
  tableValues: {
    type: Array,
    default: () => []
  },
  viewType: {
    type: String,
    default: 'list',
    validator: (value) => ['list', 'grid'].includes(value)
  },
  reloadTable: {
    type: Function,
    required: true
  }
})

const router = useRouter()

const expandedGroupId = ref(null)
const drag = ref(false)
const dragging = reactive({
  userId: null,
  dragoverGroupId: null
})
const dragOptions = {
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost'
}
const loading = ref(false)

const attachTeacherModalIsActive = ref(false)
const attachTeacherGroup = ref(null)

const groupPreferedDaysIsShown = ref(null)
const groupPreferedDays = reactive({
  morning: [],
  day: [],
  evening: []
})

const userPreferedDayIsShown = ref(null)

const showAttachTeacherModal = group => {
  attachTeacherModalIsActive.value = true
  attachTeacherGroup.value = group
}

const goGroupInfo = (groupId) => {
  router.push(`/business/groups/group/${groupId}`)
}

const onUserDrag = (draggableId) => {
  dragging.userId = draggableId
}

const onUserDragEnd = async () => {
  if (dragging.userId && dragging.dragoverGroupId) {
    const group = props.tableValues.find(group => group.id === dragging.dragoverGroupId)
    const prevGroup = props.tableValues.find(group => group.users.find(user => user.id === dragging.userId))
    try {
      loading.value = true
      await transferStudent(
        dragging.userId,
        group,
        prevGroup
      )
      props.reloadTable()
    } catch (error) {
      console.error(error)
    } finally {
      dragging.userId = null
      dragging.dragoverGroupId = null
      loading.value = false
    }
  }
  dragging.userId = null
  dragging.dragoverGroupId = null
}

const toggleGroupPreferedDays = (groupId) => {
  groupPreferedDaysIsShown.value =
    groupPreferedDaysIsShown.value === groupId ? null : groupId
}
const toggleUserPreferedDays = (userId) => {
  userPreferedDayIsShown.value =
    userPreferedDayIsShown.value === userId ? null : userId
}

const transferStudent = async (studentId, group, prevGroup) => {
  try {
    // Delete student from previous group
    await new Promise((resolve, reject) => {
      api.requestPut(
        `api/groups/${prevGroup.id}`,
        {
          users: prevGroup.users.map(user => user.id).filter(user => user !== studentId)
        },
        () => resolve(),
        err => reject(err)
      )
    })
    // Add student to selected group
    await new Promise((resolve, reject) => {
      api.requestPut(
        `api/groups/${group.id}`,
        {
          users: [...group.users.map(user => user.id), studentId]
        },
        () => resolve(),
        err => reject(err)
      )
    })
  } catch (error) {
    console.error(error)
  }
}
</script>

<style scoped>
.grid .groups {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 0;
}
.grid .groups .card {
  display: flex;
  flex-direction: column;
  background: #f1f3f4;
  border-radius: 10px;
  padding: 12px;
  border: 1px solid rgba(22, 25, 49, 0.1);
  transition: all 0.1s ease;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.grid .groups .card:hover {
  border: 1px solid #161931;
}
.preferedDays-card {
  position: absolute;
  top: 110%;
  background: #fff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  border-radius: 10px;
  z-index: 1;
  left: 0;
  width: 300%;
}
.preferedDays-card.left {
  right: 0;
  left: auto;
}
.grid .groups .card .title {
  font-weight: 500;
  font-size: 16px;
  color: #161931;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.grid .groups .card .course {
  font-weight: 600;
  font-size: 12px;
  color: rgba(22, 25, 49, 0.5);
  margin-bottom: 30px;
}
.grid .groups .card .footer {
  display: flex;
  gap: 40px;
}
.grid .groups .card .footer .item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 10px;
  color: #161931;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.1s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style scoped lang="scss">
.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
  transition: all 0.5s ease;
  &.loading {
    pointer-events: none;
  }
  .group {
    // overflow: hidden;
    &__summary {
      display: grid;
      grid-template-columns: auto 1fr 0.7fr 0.5fr auto;
      align-items: center;
      background: #f1f3f4;
      border-radius: 3px;
      padding: 14px 20px 12px 20px;
      cursor: pointer;
      z-index: 1;
      // position: relative;
      transition: all 0.2s;
      &.dropping {
        background: var(--accent-color);
        color: #fff;
        display: flex;
        justify-content: center;
        line-height: 24px;
        // outline: 1px dashed #fff;
        // outline-offset: -2px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='5' stroke-dasharray='10%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
      &.loading {
        position: relative;
        background: var(--accent-color);
      }
    }
    &__chevron {
      &.expanded {
        transform: rotate(0);
      }
      margin-right: 20px;
      transition: all 0.2s ease;
      transform: rotate(-90deg);
      cursor: pointer;
      &:hover {
        fill: var(--accent-color)
      }
    }
    &__name {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: var(--primary-color);
    }
    &__course {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: rgba(22, 25, 49, 0.5);
    }
    &__students-count {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: var(--primary-color);
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &__users {
      padding-top: 15px;
      .group__user {
        display: grid;
        grid-template-columns: auto 1fr 1fr 1fr 1fr auto;
        align-items: center;
        padding: 8px 20px;
        border: 1px solid rgba(22, 25, 49, 0.1);
        &:not(:last-child) {
          margin-bottom: -1px;
        }
        background: rgba(255, 255, 255, 0.2);
        cursor: move;
        img {
          width: 34px;
          height: 34px;
          margin-right: 10px;
        }
        .user__name {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: var(--primary-color);
        }
        .calendar {
          cursor: pointer;
        }
        .preferedDays {
          grid-column: 1 / 7;
        }
      }
    }
  }
}

.preferedDays {
  background: #fff;
  transition: all 0.2s ease;
  border-radius: 8px;
}
.options {
  position: relative;
  .dots:hover {
    fill: var(--accent-color);
  }
  &-dropdown {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    display: grid;
    gap: 0;
    right: 0;
    top: 20px;
    background: #fff;
    width: max-content;
    padding: 0;
    overflow: hidden;
    border-radius: 5px;
    z-index: 1000;
    box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
    transition: all 0.3s ease;
    transform: translateY(-10px);
    li {
      padding: 10px;
      background: #fff;
      transition: all 0.2s ease;
      text-align: start;
      &:hover {
        background: var(--hover-color);
      }
    }
  }
  &:focus &-dropdown {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
