<template>
  <div class="review__btns">
    <Button @click="() => { reviewVisible = true }">REVIEW MODAL ONE</Button>
    <Button @click="() => { reviewVisibleTwo = true }">REVIEW MODAL TWO</Button>
  </div>
  <!-------------- FIRST REVIEW-MODAL -------------->
  <review-modal
    v-if="reviewVisible"
    :hide="() => { reviewVisible = false}"
  >
    <StepOne/>
  </review-modal>
  <!------------- SECOND REVIEW-MODAL ------------->
  <review-modal
  v-if="reviewVisibleTwo"
  :hide="() => { reviewVisibleTwo = false}"
  >
    <StepTwo/>
    <template #success>
      <div class="review__success-block" >
        <h2 class="review__success-text">Большое спасибо за оставленный отзыв. Будем стараться радовать Вас своей работой! </h2>
      </div>
    </template>
  </review-modal>
</template>

<script setup>
import { ref } from 'vue-demi'

import ReviewModal from '@/components/uiLearning/reviews/ReviewModal.vue'
import StepOne from '@/components/uiLearning/reviews/StepOne.vue'
import StepTwo from '@/components/uiLearning/reviews/StepTwo.vue'

const reviewVisible = ref(false)
const reviewVisibleTwo = ref(false)
</script>

<style scoped>
  .review__btns{
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
  .review__success{
    min-height: 100vh;
    background-color: #0082FA;
  }
  .review__success-block{
    position: absolute;
    top: 45%;
    width: 100%;
  }
  .review__success-text{
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    color: white;
    font-size: 36px;
    line-height: 40px;
  }
</style>
