<template>
  <div class="main-landing certificate">
    <div>
      <Header />
      <section class="wrapper wrapper-landing">
        <div v-if="certificate">
          <!-- --------------    Titles    -------------- -->
          <div class="certificate-header">
            <h2 class="landing-title">Цифровой сертификат</h2>
            <p class="subtitle">Этот сертификат подтверждает успешное завершение курса
              “<RouterLink :to="`/course/${route.params.courseId}`">{{ certificate.course_name }}</RouterLink>”
              от школы
              {{ certificate.school_name }}.
            </p>
            <router-link :to="`/course/${route.params.courseId}`">
              <p class="subtitle">Посмотреть курс</p>
            </router-link>
          </div>

          <!-- --------------    Certificate    -------------- -->
          <div class="certificate-block">
            <h4 class="certificate-title">Миниатюра сертификата</h4>
            <CertificatePDF class="pdf" :dataSettings="settings" :data="certificate" />
            <div class="btns" v-if="user.data.name === certificate.student_name">
              <Button
                class="btn print"
                type="default-outlined dark"
                @click="printCertificate"
                :loading="isLoading.print"
              >
                <span>Печать</span>
                <SvgIcon
                  name="print"
                  :height="20"
                  :width="20"
                  :viewboxHeight="20"
                  :viewboxWidth="20"
                />
              </Button>
              <Button
                class="btn download"
                @click="installCertificate"
                :loading="isLoading.install"
              >
                <span>Скачать</span>
                <SvgIcon
                  name="download-filled"
                  :height="20"
                  :width="20"
                  :viewboxHeight="20"
                  :viewboxWidth="20"
                />
              </Button>
            </div>
          </div>
        </div>

        <div v-else class="loading-certificate">
          <Loading name="sk" color="var(--primary-color)"/>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>

<script setup>
import Header from '@/components/uiLanding/layouts/Header.vue'
import Footer from '@/components/uiLanding/layouts/Footer.vue'
import Loading from '@/components/common/Loading.vue'
import CertificatePDF from '@/components/uiBusiness/CertificatePDF.vue'

import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useAuth } from '@/modules/auth'
import { getCertificateData, downloadCertificate } from '@/actions/uiLanding/certificate'

const route = useRoute()
const { user } = useAuth()

const certificate = ref()

const settings = {
  student_name: true,
  teacher_name: true,
  school_name: true,
  course_name: true,
  grade: true
}

const isLoading = reactive({
  print: false,
  install: false
})

onMounted(async () => {
  certificate.value = (await getCertificateData(route.params.courseId, route.params.userId)).data
})

const printCertificate = async () => {
  isLoading.print = true
  try {
    await downloadCertificate(route.params.courseId, true)
  } finally { isLoading.print = false }
}

const installCertificate = async () => {
  const fileName = certificate.value.course_name ? `Сертификат от курса ${certificate.value.course_name}` : 'Сертификат'
  isLoading.install = true
  try {
    await downloadCertificate(route.params.courseId, false, fileName)
  } finally { isLoading.install = false }
}
</script>

<style scoped lang="scss">
.loading-certificate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
}
.landing-title {
  font-weight: 700;
  font-size: 36px;
  color: #161931;
  margin-bottom: 26px;
}
.subtitle {
  max-width: 900px;
  display: block;
  font-size: 16px;
  line-height: 1.2;
  color: #161931;
  margin-bottom: 16px;
}
a {
  display: inline-block;
  text-decoration: underline;
  & p {
    margin: 0 !important;
  }
}
.certificate {
  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #161931;
    margin-bottom: 20px;
  }
  &-header{
    margin-bottom: 50px;
  }
  &-block {
    display: inline-block;
    padding: 26px 20px 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  & .pdf {
    margin-bottom: 20px;
  }
  & .btns {
    display: flex;
    justify-content: flex-end;
    grid-gap: 14px;
  }

  & button.btn {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    border-radius: 5px;
    transition: all .2s linear;

    & span {
      font-weight: 500;
      font-size: 16px;
    }
    &.print {
      & span {
        color: #161931;
        text-transform: capitalize;
      }
      & svg {
        fill: #161931;
      }
    }

    &.download {
      & span {
        color: #fff;
        text-transform: capitalize;
      }
      & svg {
        fill: #fff;
      }
    }
  }
}
</style>
