<template>
  <div class="main">
    <h1>
      <span class="title">Мои школы</span>
      <Button name="Добавить школу" @click="createSchoolIsActive = true">
        <template v-slot:left >
          <SvgIcon name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" style="fill: white;" />
        </template>
      </Button>
    </h1>
    <div class="schools-table">
      <div class="filter">
        <input type="text" id="searchSchoolsByName" placeholder="Введите название школы" @keydown="searchByName">
      </div>

      <div class="schools" v-if="schools">
        <SchoolCard
          v-for="school of filterSchool(schools)"
          :key="school.id"
          :school="school"
          @success="getData"
        />
      </div>

      <div class="schools disabled" v-else>
        <SchoolCard
          v-for="school of 12"
          :key="school"
        />
      </div>
    </div>
    <transition name="modal">
      <NewSchoolModal
        v-if="createSchoolIsActive"
        @hide="hideCreateSchoolModal"
      />
    </transition>
  </div>
</template>

<script setup>
import SchoolCard from '@/components/uiLanding/Schools/SchoolCard.vue'
import NewSchoolModal from '@/components/uiBusiness/Schools/NewSchoolModal.vue'

import { onMounted, ref } from 'vue'
import api from '@/modules/api'

import filterSchool from '@/helpers/filterSchool.js'

const schools = ref()

const getData = async () => {
  schools.value = null
  api.requestGet(
    '/api/user',
    {},
    response => {
      schools.value = response.data.data.schools
    },
    () => {},
    true
  )
}

onMounted(getData)

const createSchoolIsActive = ref(false)

const hideCreateSchoolModal = () => {
  createSchoolIsActive.value = false
}
</script>

<style scoped>
.main {
  padding: 20px 30px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 20px;
}
.schools-table {
  padding: 13px 20px;
  margin-bottom: 15px;
}
.schools-table .filter {
  width: 100%;
  margin-bottom: 20px;
}
.schools-table .filter input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: rgba(22, 25, 49, 0.5);
  text-transform: none;
}
.schools-table .filter input {
  width: 100%;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.935 11.2429C11.9473 11.2534 11.9592 11.2644 11.9707 11.276L15.6073 14.9125C15.8583 15.1636 15.8583 15.5706 15.6073 15.8217C15.3562 16.0727 14.9492 16.0727 14.6982 15.8217L11.0616 12.1851C11.0259 12.1494 10.9952 12.1105 10.9696 12.0693C10.0422 12.7017 8.92137 13.0714 7.71418 13.0714C4.51882 13.0714 1.92847 10.4811 1.92847 7.28571C1.92847 4.09035 4.51882 1.5 7.71418 1.5C10.9095 1.5 13.4999 4.09035 13.4999 7.28571C13.4999 8.81632 12.9055 10.2081 11.935 11.2429ZM7.71423 11.7858C10.1995 11.7858 12.2142 9.77105 12.2142 7.28577C12.2142 4.80049 10.1995 2.78577 7.71423 2.78577C5.22895 2.78577 3.21423 4.80049 3.21423 7.28577C3.21423 9.77105 5.22895 11.7858 7.71423 11.7858Z' fill='%23161931' fill-opacity='0.5'/%3E%3C/svg%3E%0A") left 16px center no-repeat;
  padding: 12px 45px;
  text-transform: capitalize;
}
.schools-table .filter input:focus {
  border: 1px solid var(--primary-color);
}
.schools {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.modal-enter-active,
.modal-leave-active {
  transition: all .3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
