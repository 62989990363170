<template>
  <div class="course-card">
    <div class="top">
      <h6 class="category skeleton small-text"></h6>
      <div class="raiting">
        <span class="reviews skeleton small-text"></span>
        <span class="reviews skeleton small-text"></span>
      </div>
    </div>

    <div class="center">
      <span class="name skeleton middle-text"></span>
      <div class="logo skeleton"></div>
    </div>
    <div class="description">
      <div class="infos">
        <span class="info skeleton middle-text"></span>
        <span class="info skeleton middle-text"></span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.course-card{
  max-width: 430px;
  height: 189px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  padding: 20px 20px 22px;

  background: #FFFFFF;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

& .preview {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }

  & .top,
  & .center,
  & .description{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .top {
    & .raiting,
    & .stars {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
    & .category {
      font-size: 11px;
    }
    & .reviews {
      font-size: 12px;
    }
  }

  & .center {
    align-items: flex-start;
    grid-gap: 20px;
    & .name {
      font-size: 35px;
    }
    & .logo {
      width: 70px;
      height: 70px;
      border-radius: 5px;
    }
  }

  & .description {
    & .infos {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      & .info {
        font-size: 14px;
      }
    }
  }

}
</style>
