import { createRouter, createWebHistory } from 'vue-router'
import Profile from '@/views/Profile.vue'

// ------------- UI Landing Pages ---------------
// ------ Main -------
import Authorization from '@/views/Authorization/Authorization.vue'
import Home from '@/views/uiLanding/Home.vue'
import ForBusiness from '@/views/uiLanding/ForBusiness.vue'
import CategoriesPage from '@/views/uiLanding/Categories.vue'
import CertificatePage from '@/views/uiLanding/Certificate.vue'
import Search from '@/views/uiLanding/Search.vue'

// ------ Schools Pages -------
import Schools from '@/views/uiLanding/Schools/Schools.vue'
import SchoolPage from '@/views/uiLanding/Schools/SchoolPage.vue'

// ------ Courses Pages -------
import Courses from '@/views/uiLanding/Courses/Courses.vue'
import CoursePage from '@/views/uiLanding/Courses/CoursePage.vue'

// ------ Teachers Pages -------
import TeachersLanding from '@/views/uiLanding/Teachers/Teachers.vue'

// ------------- UI Businass Pages ---------------
// ------ Main -------
import Users from '@/views/uiBusiness/Users/Users.vue'
import UserInfo from '@/views/uiBusiness//Users/UserInfo.vue'
import Students from '@/views/uiBusiness/Users/Students.vue'
import Teachers from '@/views/uiBusiness/Users/Teachers.vue'
import Requests from '@/views/uiBusiness/Requests.vue'

import Forums from '@/views/uiBusiness/Forums/Forums.vue'
import ForumPage from '@/views/uiBusiness/Forums/ForumPage.vue'

import Applications from '@/views/uiBusiness/Applications.vue'

import CoursesBusiness from '@/views/uiBusiness/Courses/Courses.vue'
import LessonsBusiness from '@/views/uiBusiness/Courses/Lessons.vue'
import EditLesson from '@/views/uiBusiness/Courses/EditLesson.vue'
import EditHomework from '@/views/uiBusiness/Courses/EditHomework.vue'
import EditExam from '@/views/uiBusiness/Courses/EditExam.vue'

import Groups from '@/views/uiBusiness/Groups/Groups.vue'
import GroupInfo from '@/views/uiBusiness/Groups/GroupInfo.vue'

import HomeworksTeacher from '@/views/uiBusiness/Homeworks/Homeworks.vue'
import TakesPage from '@/views/uiBusiness/Homeworks/TakesPage.vue'
import Assessment from '@/views/uiBusiness/Homeworks/Assessment.vue'

import SchoolsBusiness from '@/views/uiBusiness/Schools.vue'
import NewsBusiness from '@/views/uiBusiness/News/MyNews.vue'
import NewsEdit from '@/views/uiBusiness/News/NewsEdit.vue'
// import Sales from '@/views/uiBusiness/Sales.vue'
import Marketing from '@/views/uiBusiness/Marketing.vue'
import Settings from '@/views/uiBusiness/Settings.vue'
import Certificate from '@/views/uiBusiness/Certificate.vue'
import Help from '@/views/uiBusiness/Help.vue'

import Tags from '@/views/uiBusiness/Tags.vue'
import Categories from '@/views/uiBusiness/Categories.vue'
import GradesAdmin from '@/views/uiBusiness/Grades.vue'

import MainLayoutClient from '@/components/uiClient/layouts/MainLayoutClient.vue'
import MyCourses from '@/views/uiClient/MyCourses.vue'
import Lessons from '@/views/uiClient/Lessons.vue'
import Schedule from '@/views/uiClient/Schedule.vue'
import Grades from '@/views/uiClient/Grades.vue'
import Homeworks from '@/views/uiClient/Homeworks/Homeworks.vue'
import HomeworkPage from '@/views/uiClient/Homeworks/HomeworkPage.vue'
import Chat from '@/views/uiClient/Chat.vue'
import Forum from '@/views/uiClient/Forum.vue'
import Billing from '@/views/uiClient/Billing.vue'

import uiLearning from '@/views/uiLearning/uiLearning.vue'
import DoHomework from '@/components/uiLearning/DoHomework.vue'
import DoLesson from '@/components/uiLearning/DoLesson.vue'

import NotFound from '@/views/404NotFound.vue'

import { useAuth } from '@/modules/auth'
import { useToast } from '@/modules/toast.js'

import Reviews from '@/components/uiLearning/reviews/Review.vue'
import Progress from '@/modules/progress'

const MainLayoutBusiness = () => import('@/components/uiBusiness/layouts/MainLayoutBusiness.vue')

const routes = [
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  // ------------- UI Landing Pages ---------------
  //
  {
    path: '/authorization/:logOrReg?',
    name: 'Authorization',
    component: Authorization,
    meta: { title: 'Авторизация - A-Class' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'A-Class' }
  },
  {
    path: '/for-business',
    name: 'ForBusiness',
    component: ForBusiness,
    meta: { title: 'Для преподавателей - A-Class' }
  },
  {
    path: '/courses/:filter?',
    name: 'Courses',
    component: Courses,
    meta: { title: 'Курсы - A-Class' }
  },
  {
    path: '/course/:course_id',
    name: 'CoursePage',
    component: CoursePage,
    meta: { title: 'Курс - A-Class' }
  },
  {
    path: '/course/:course_id/teachers',
    name: 'CourseTeachers',
    component: TeachersLanding,
    meta: { title: 'Преподователи - A-Class' }
  },
  {
    path: '/schools',
    name: 'Schools',
    component: Schools,
    meta: { title: 'Школы - A-Class' }
  },
  {
    path: '/school/:school_id/:edit?',
    name: 'SchoolPage',
    component: SchoolPage,
    meta: { title: 'Школа - A-Class' }
  },
  {
    path: '/school/:school_id/teachers',
    name: 'SchoolTeachers',
    component: TeachersLanding,
    meta: { title: 'Преподователи - A-Class' }
  },
  {
    path: '/categories',
    name: 'CategoriesPage',
    component: CategoriesPage,
    meta: { title: 'Категории - A-Class' }
  },
  {
    path: '/search/:searchType/:filter?',
    name: 'SearchResults',
    component: Search,
    meta: { title: 'Результаты поиска - A-Class' }
  },
  {
    path: '/cert/:courseId/:userId',
    name: 'CertificatePage',
    component: CertificatePage,
    meta: { title: 'Сертификат - A-Class' }
  },

  // ------------- UI Business Pages ---------------
  //
  {
    path: '/business',
    name: 'Business',
    component: MainLayoutBusiness,
    meta: { title: 'A-Class Business', requiresAuth: true },
    redirect: to => {
      if (user.value.data?.schools?.length > 0 || user.value.data?.role === 'superadmin') {
        if (user.value.data?.role === 'superadmin') return '/business/users'
        else return '/business/courses'
      } else {
        toast({
          message: 'У вас нет школы для бизнеса. Пожалуйста создайте школу'
        })
        return '/for-business#business-registration'
      }
    },
    children: [
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: { title: 'Пользователи - A-Class Business' },
        children: [
          {
            path: 'students',
            name: 'Students',
            component: Students,
            meta: { title: 'Студенты - A-Class Business' }
          },
          {
            path: 'teachers',
            name: 'Teachers',
            component: Teachers,
            meta: { title: 'Преподаватели - A-Class Business' }
          }
        ]
      },
      {
        path: 'user/:id',
        name: 'UserInfo',
        component: UserInfo
      },
      {
        path: 'requests/:user_role?',
        name: 'Requests',
        component: Requests,
        meta: { title: 'Заявки - A-Class Business' }
      },
      {
        path: 'payments',
        name: 'payments',
        component: Applications,
        meta: { title: 'Выплаты - A-Class Business' }
      },
      {
        path: 'my-payments',
        name: 'MyPayouts',
        component: Applications,
        meta: { title: 'Оплаты - A-Class Business' }
      },
      {
        path: 'payments/payouts',
        name: 'Payouts',
        component: Billing,
        meta: { title: 'Оплаты - A-Class Business' }
      },
      {
        path: 'courses',
        name: 'CoursesBusiness',
        component: CoursesBusiness,
        meta: { title: 'Курсы - A-Class Business' },
        children: [
          {
            path: ':course_id/lessons',
            name: 'LessonsBusiness',
            component: LessonsBusiness,
            meta: { title: 'Уроки - A-Class Business' },
            children: [
              {
                path: ':lesson_id',
                name: 'EditLesson',
                component: EditLesson,
                meta: { title: 'Урок - A-Class Business' }
              },
              {
                path: ':lesson_id/homework/:homework_id',
                name: 'EditHomework',
                component: EditHomework,
                meta: { title: 'Домашнee заданee - A-Class Business' }
              },
              {
                path: 'final-exam/:exam_id',
                name: 'EditExam',
                component: EditExam,
                meta: { title: 'Итоговый экзамен - A-Class Business' }
              }
            ]
          },
          {
            path: ':course_id/certificate',
            name: 'CertificateSettings',
            component: Certificate,
            meta: { title: 'Сертификат - A-Class Business' }
          }
        ]
      },
      {
        path: 'schedule',
        name: 'ScheduleBusiness',
        component: Schedule,
        meta: { title: 'Расписание - A-Class Business' }
      },
      {
        path: 'groups',
        name: 'Groups',
        component: Groups,
        meta: { title: 'Группы - A-Class Business' },
        children: [
          {
            path: 'group/:groupid',
            name: 'GroupInfo',
            component: GroupInfo,
            meta: { title: 'Группa - A-Class Business' }
          }
        ]
      },
      {
        path: 'homeworks',
        name: 'HomeworksTeacher',
        component: HomeworksTeacher,
        meta: { title: 'Домашние задания - A-Class Business' },
        children: [
          {
            path: ':student_id/takes',
            name: 'TakesPage',
            component: TakesPage,
            meta: { title: 'Ответы ученика - A-Class Business' }
          },
          {
            path: ':take_id/assessment',
            name: 'Assessment',
            component: Assessment,
            meta: { title: 'Оценка ученика - A-Class Business' }
          }
        ]
      },
      {
        path: 'schools',
        name: 'SchoolsBusiness',
        component: SchoolsBusiness,
        meta: { title: 'Мои школы - A-Class Business' }
      },
      {
        path: 'marketing',
        name: 'Marketing',
        component: Marketing,
        meta: { title: 'Маркетинг - A-Class Business' }
      },
      {
        path: 'settings',
        name: 'SettingsLearningBusiness',
        component: Settings,
        meta: { title: 'Настройки - A-Class Business' }
      },
      {
        path: 'help',
        name: 'Help',
        component: Help,
        meta: { title: 'Помощь - A-Class Business' }
      },
      {
        path: 'profile',
        name: 'ProfileBusiness',
        component: Profile,
        meta: { title: 'Профиль - A-Class Business' }
      },
      {
        path: 'tags',
        name: 'TagsBusiness',
        component: Tags,
        meta: { title: 'Теги - A-Class Business' }
      },
      {
        path: 'categories',
        name: 'CategoriesBusiness',
        component: Categories,
        meta: { title: 'Категории - A-Class Business' }
      },
      {
        path: 'grades',
        name: 'GradesBusiness',
        component: GradesAdmin,
        meta: { title: 'Виды оценок - A-Class Business' }
      },
      {
        path: 'forums',
        name: 'ForumsList',
        component: Forums,
        meta: { title: 'Форумы - A-Class Business' }
      },
      {
        path: 'forums/:forum_id',
        name: 'ForumsPage',
        component: ForumPage,
        meta: { title: 'Форум - A-Class Business' }
      },
      {
        path: 'news',
        name: 'NewsBusiness',
        component: NewsBusiness,
        meta: { title: 'Мои новости - A-Class Business' }
      },
      {
        path: 'news/:news_id',
        name: 'NewsEdit',
        component: NewsEdit,
        meta: { title: 'Новость - A-Class Business' }
      }
    ]
  },

  // ------------- UI Learning Pages ---------------
  //
  {
    path: '/learning',
    name: 'Client',
    component: MainLayoutClient,
    meta: { title: 'Обучение - A-Class', requiresAuth: true },
    redirect: to => {
      return '/learning/my-courses'
    },
    children: [
      {
        path: 'my-courses',
        name: 'MyCourses',
        component: MyCourses,
        meta: { title: 'Мои курсы - A-Class Business' }
      },
      {
        path: 'lessons',
        name: 'Lessons',
        component: Lessons,
        meta: { title: 'Уроки - A-Class Business' }
      },
      {
        path: 'settings',
        name: 'SettingsBusiness',
        component: Settings,
        meta: { title: 'Настройки - A-Class Business' }
      },
      {
        path: 'schedule',
        name: 'Schedule',
        component: Schedule,
        meta: { title: 'Расписание - A-Class Business' }
      },
      {
        path: 'grades',
        name: 'Grades',
        component: Grades,
        meta: { title: 'Оценки - A-Class Business' }
      },
      {
        path: 'homeworks',
        name: 'Homeworks',
        component: Homeworks,
        meta: { title: 'Домашнее задание - A-Class Business' },
        children: [
          {
            path: ':homeworkid',
            name: 'HomeworkPage',
            component: HomeworkPage,
            meta: { title: 'Домашнее задание - A-Class Business' }
          }
        ]
      },
      {
        path: 'chat',
        name: 'Chat',
        component: Chat,
        meta: { title: 'Чат - A-Class Business' }
      },
      {
        path: 'forum',
        name: 'Forum',
        component: Forum,
        meta: { title: 'Форум - A-Class Business' }
      },
      {
        path: 'billing',
        name: 'Billing',
        component: Billing,
        meta: { title: 'Оплата - A-Class Business' }
      },
      {
        path: 'profile',
        name: 'ProfileLearning',
        component: Profile,
        meta: { title: 'Профиль - A-Class Business' }
      },
      {
        path: 'settings',
        name: 'SettingsLearning',
        component: Settings,
        meta: { title: 'Настройки - A-Class Business' }
      }
    ]
  },
  {
    path: '/lesson/:course_id',
    name: 'uiLearning',
    component: uiLearning,
    meta: { title: 'Обучение - A-Class', requiresAuth: true },
    children: [
      {
        path: 'homework/:homework_id',
        name: 'DoHomework',
        component: DoHomework,
        meta: { title: 'Домашнее задание - A-Class' }
      },
      {
        path: 'unit/:lesson_id',
        name: 'DoLesson',
        component: DoLesson,
        meta: { title: 'Урок - A-Class' }
      }
    ]
  },
  // REVIEWS ROUTES
  {
    path: '/reviews',
    name: '/Review',
    component: Reviews
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash }
    } else if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  }
})

const progress = new Progress()
const { user, loaded, logged, getUser, logout } = useAuth()
const { toast } = useToast()

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title

  progress.start()

  if (!to.meta.requiresAuth) {
    next()
    return
  }

  if (!logged()) {
    next({ name: 'Authorization', params: { logOrReg: 'login' } })
    return
  }

  if (loaded.value) {
    next()
    return
  }

  try {
    await getUser()
    next()
  } catch (error) {
    logout()
    next({ name: 'Authorization', params: { logOrReg: 'login' } })
  }
})

router.afterEach(progress.end)

export default router
