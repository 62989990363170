<template>
  <div
    v-for="request of requests"
    :key="request"
  >
    <Modal
      :hide="hide"
      title="Подробные данные заявки"
      v-if="active.requestId === request.id"
    >
      <div class="form">
        <Input type="disabled" placeholder="Имя пользователя" :modelValue="user.name"/>
        <div class="group">
          <span class="label">Название курса</span>
          <Tag :text="request.name"/>
        </div>
        <Input type="disabled" placeholder="Роль" :modelValue="getRole(request.role)"/>
        <Input type="disabled" placeholder="Вид обучения" :modelValue="getCourseType(request.preferred_weekdays)"/>
        <div class="group" v-if="request.preferred_weekdays">
          <span class="label">Предварительные выбранные дни для обучения</span>
          <PreferedDays
            :evening="request.preferred_weekdays.evening"
            :day="request.preferred_weekdays.day"
            :morning="request.preferred_weekdays.morning"
            :editable="false"
          />
        </div>
      </div>

      <div class="actions">
        <Button
          shadowed
          @click="onAcceptRequest"
          :loading="active.loading"
        >ПРИНЯТЬ</Button>
        <Button
          shadowed
          type="cancel"
          @click="onDenyRequest"
        >ОТКЛОНИТЬ</Button>
      </div>

      <button
        class="next"
        data-tooltip="Показать следующую заявку этого пользователя"
        v-if="nextRequestExists()"
        @click="nextRequest"
      >
        <SvgIcon
          name="chevron-right"
          :viewboxHeight="40"
          :height="30"
          fill="#fff"
        />
      </button>
      <button
        class="previous"
        data-tooltip="Показать предыдущую заявку этого пользователя"
        v-if="previousRequestExists()"
        @click="previousRequest"
      >
        <SvgIcon
          name="chevron-left"
          :viewboxHeight="40"
          :height="30"
          fill="#fff"
        />
      </button>
    </Modal>
  </div>
  <Modal
    v-if="active.denyConfirm"
    :hide="() => { active.denyConfirm = false }"
    title="Вы действительно хотите отклонить заявку?"
    footer
    :onOk="denyRequest"
  />
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import Tag from '@/components/common/Tag.vue'
import PreferedDays from '@/components/PreferedDays.vue'
import { reactive, ref } from 'vue-demi'
import { putAcceptUserRequest, putDenyUserRequest } from '@/actions/uiBusiness/requests'
import { useToast } from '@/modules/toast'

const props = defineProps({
  hide: {
    type: Function,
    default: () => null
  },
  user: {
    type: Object
  },
  reloadTable: {
    type: Function,
    default: () => null
  }
})
const { toast } = useToast()
const requests = ref(props.user.courses)
const active = reactive({
  requestId: requests.value[0]?.id,
  requestIndex: 0,
  denyConfirm: false,
  loading: false,
  undoRequestId: null
})

const getRole = role => {
  switch (role) {
    case 'student':
      return 'Студент'
    case 'teacher':
      return 'Преподаватель'
    default:
      return role
  }
}

const nextRequestExists = () => {
  if (requests.value[active.requestIndex + 1]) return true
  return false
}
const previousRequestExists = () => {
  if (requests.value[active.requestIndex - 1]) return true
  return false
}

const nextRequest = () => {
  if (requests.value[active.requestIndex + 1]) active.requestIndex++
  active.requestId = requests.value[active.requestIndex].id
}
const previousRequest = () => {
  if (requests.value[active.requestIndex - 1]) active.requestIndex--
  active.requestId = requests.value[active.requestIndex].id
}

const onDenyRequest = () => {
  active.denyConfirm = true
}
const denyRequest = () => {
  putDenyUserRequest(props.user.id, active.requestId)
  toast({
    message: 'Заявка отклонена'
  })
  props.hide()
}

const onAcceptRequest = async () => {
  try {
    active.loading = true
    await putAcceptUserRequest(props.user.id, requests.value[active.requestIndex])
    active.undoRequestId = active.requestId
    toast({
      message: 'Заявка успешно принята',
      type: 'success'
    })

    // if (nextRequestExists()) nextRequest()
    // else if (previousRequestExists()) previousRequest()
    props.hide()

    // requests.value = props.user.courses.filter(course => course.id !== active.undoRequestId)
  } catch (err) {
    console.error(err)
  } finally {
    active.loading = false
  }
}

const getCourseType = data => data ? 'Оффлайн' : 'Онлайн'
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.group {
  display: flex;
  flex-direction: column;
  gap: 11px;
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}
.prefered-days {
  gap: 7px;
}
.actions {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 20px;
}
.next {
  position: absolute;
  right: -50px;
  top: 50%;
}
.previous {
  position: absolute;
  top: 50%;
  left: -50px;
}
.next-enter-active,
.next-leave-active {
  transition: all .5s ease;
}
.next-enter-from,
.next-leave-to {
  opacity: 0;
  transform: translateX(10em) scale(99%);
}
</style>
