import api from '@/modules/api'

export const getUserTakes = async userId =>
  await api.asyncGet(
    '/api/takes',
    {
      user_id: userId,
      finished: 1
    }
  )
