<template>
  <div class="course-landing">
    <Header />
    <Intro
      :course="courseData"
      :haveCourse="haveCourse"
      :schoolAdmin="schoolAdmin"
      @editCourse="() => { showEditModal = true }"
    />

    <Price
      :price="courseData.price"
      :duration="courseData.duration"
      :graduated_count="courseData.students_graduated_count"
      :certificate="courseData.certificate_fields"
    />

    <Features
      v-if="courseData?.target_audience || schoolAdmin"
      :data="courseData"
      :schoolAdmin="schoolAdmin"
      @getData="getData"
    />

    <Benefits
      :data="courseData"
      :schoolAdmin="schoolAdmin"
      @getData="getData"
    />

    <LessonsCounts :course="courseData" />

    <section class="wrapper wrapper-landing program" v-if="courseData.lessons">
      <h2 class="landing-title">Программа обучения</h2>
      <Program :lessonsData="courseData.lessons"/>
    </section>

    <!-- ------------- Teachers -------------- -->
    <section class="wrapper wrapper-landing" v-if="teachersList.length">
      <div class="landing-header">
        <h2 class="landing-title">Преподователи</h2>
        <router-link
          v-if="teachersList.length > 10"
          class="landing-link"
          :to="`/course/${courseData.id}/teachers`"
        >
          {{ teachersList ? teachersList.length : 'Все' }}
        </router-link>
      </div>

      <Slider :link="teachersList.length > 10 ? `/course/${courseData.id}/teachers` : null">
        <TeacherCard
          v-for="teaher of teachersList.slice(0, 10)"
          :key="teaher"
          :teacher="teaher"
        />
      </Slider>
    </section>

    <!-- <Author /> -->

    <section class="wrapper wrapper-landing" v-if="courseData.id">
      <h2 class="landing-title">Статистика и отзывы пользователей</h2>
      <div class="ratings-block">
        <Statistics
          :statistics="statistics"
          studentsGraduated
          studentsCount
        />
        <Ratings :courseData="courseData" />
      </div>
    </section>

    <div class="teacher-back" v-if="courseData.id && !schoolAdmin && teacherCourse !== 'accepted'">
      <div class="wrapper wrapper-landing">
        <h2 class="landing-title">Хотите стать преподавателем прямо сейчас?</h2>
        <Button
          :name="teacherCourse !== 'not_processed' ? 'Стать преподавателем' : 'Запрос в обработке'"
          @click="enrollToCourse"
          :loading="loadingEnroll"
        ></Button>
      </div>
    </div>

    <Payment
      v-if="!haveCourse && !schoolAdmin"
      v-model:haveCourse="haveCourse"
      :course="courseData"
      :userData="user.data"
    />

    <!-- --------------    School Courses List   -------------- -->
    <section class="wrapper wrapper-landing" v-if="courseData.id && schoolCourses.length !== 0">
      <div class="landing-header">
        <h2 class="landing-title">Другие курсы этой школы</h2>
        <router-link v-if="schoolCourses.length > 10" class="landing-link" :to="`/courses?school_id=${courseData.school.id}&school_name=${courseData.school.name}`">
          {{ schoolCourses.length ?? 'Все' }}
        </router-link>
      </div>
      <Slider
        :link="
          schoolCourses.length > 10 ?
          `/courses?school_id=${courseData.school.id}&school_name=${courseData.school.name}`
          : null
        "
      >
        <CourseCard
          v-for="course of schoolCourses.slice(0, 10)"
          :key="course"
          :course="course"
        />
      </Slider>
    </section>

    <Footer />

    <transition mode="out-in">
      <EditCourseModal
        v-if="showEditModal"
        :data="courseData"
        @hide="() => { showEditModal = false }"
        @finish="getData"
      />
    </transition>
  </div>
</template>

<script setup>
import Header from '@/components/uiLanding/layouts/Header.vue'
import Footer from '@/components/uiLanding/layouts/Footer.vue'
import Intro from '@/components/uiLanding/Courses/Page/Intro.vue'
import Price from '@/components/uiLanding/Courses/Page/Price.vue'
import TeacherCard from '@/components/uiLanding/Schools/Page/TeacherCard.vue'
import Benefits from '@/components/uiLanding/Courses/Page/Benefits/Benefits.vue'
import Program from '@/components/uiLanding/Courses/Page/Program.vue'
// import Author from '@/components/uiLanding/Courses/Page/Author.vue'
// import Ratings from '@/components/uiLanding/Ratings/Ratings.vue'
// import Statistics from '@/components/uiLanding/Ratings/Statistics.vue'
// import Review from '@/components/uiLanding/Review.vue'
import Payment from '@/components/uiLanding/Courses/Page/Payment/Payment.vue'
import LessonsCounts from '@/components/uiLanding/Courses/Page/LessonsCounts.vue'
import Features from '@/components/uiLanding/Courses/Page/Features/Features.vue'
import Slider from '@/components/common/Slider/Slider.vue'
import Ratings from '@/components/uiLanding/Ratings/Ratings.vue'
import Statistics from '@/components/uiLanding/Ratings/Statistics.vue'
import EditCourseModal from '@/components/uiLanding/Courses/Page/EditCourseModal.vue'
import CourseCard from '@/components/uiLanding/Courses/List/CourseCard.vue'

import { onMounted, ref, computed, watch, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuth } from '@/modules/auth'

import { getCourse, getCourses, postCourseEnroll } from '@/actions/uiBusiness/courses'
import { useToast } from '@/modules/toast'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()
const { user, logged, reloadUser } = useAuth()

const showEditModal = ref(false)
const courseData = ref({
  school: {}
})
const schoolCourses = ref([])
const teachersList = ref([])
const statistics = reactive({
  students: null,
  graduated_students: null
})

const getData = async () => {
  courseData.value = (await getCourse(route.params.course_id)).data
}

onMounted(async () => {
  const courseFromApi = (await getCourse(route.params.course_id)).data
  schoolCourses.value = (await getCourses(courseFromApi.school.id)).data.filter(course => course.id !== courseFromApi.id)
  teachersList.value = courseFromApi.teachers
  document.title = `${courseFromApi.name} - A-Class`
  if (logged()) {
    const courseStudent = user.value.data.courses.find(course => course.id === Number(route.params.course_id))
    if (courseStudent) haveCourse.value = courseStudent.permission.role === 'student' && courseStudent.permission.status === 'accepted'
    else haveCourse.value = false

    const courseTeacher = user.value.data.courses.find(course => course.id === Number(route.params.course_id) && course.permission.role === 'teacher')
    if (courseTeacher) teacherCourse.value = courseTeacher.permission.status
    else teacherCourse.value = null
  }
  statistics.students = courseFromApi.students_count
  statistics.graduated_students = courseFromApi.students_graduated_count

  if (courseFromApi.status === 'draft' && teacherCourse.value !== 'accepted' && !schoolAdmin.value) {
    router.push('/courses')
    toast({
      message: 'На данный момент к курсу нет доступа на маркетплейсе'
    })
  } else courseData.value = courseFromApi
})

// Enroll TO Course
const loadingEnroll = ref(false)
const enrollToCourse = async () => {
  if (logged()) {
    try {
      loadingEnroll.value = true
      await postCourseEnroll(courseData.value.id)
      reloadUser()
    } finally {
      loadingEnroll.value = false
    }
  } else {
    localStorage.setItem('savedRoute', route.path)
    toast({ message: 'Пожалуйста зарегистрируйтесь чтобы оставить заявку' })
    router.push('/authorization/login')
  }
}

// Check Have Course
const haveCourse = ref(false)
watch(
  () => user.value.data,
  async () => {
    if (logged()) {
      const course = user.value.data.courses.find(course => course.id === Number(route.params.course_id))
      if (course) haveCourse.value = course.permission.role === 'student' && course.permission.status === 'accepted'
      else haveCourse.value = false
    }
  }
)

// Check Have Course
const teacherCourse = ref(null)
watch(
  () => user.value.data,
  async () => {
    if (logged()) {
      const course = user.value.data.courses.find(course => course.id === Number(route.params.course_id) && course.permission.role === 'teacher')
      if (course) teacherCourse.value = course.permission.status
      else teacherCourse.value = null
    }
  }
)

// Check Admin Course
const schoolAdmin = computed(() => {
  if (!user.value.data.id || !courseData.value.school?.id) return false
  return user.value.data.schools.some((schoolItem) => schoolItem.id === courseData.value.school?.id)
})
</script>

<style scoped lang="scss">
.course-landing {
  & .header {
    margin-bottom: 0 !important;
  }
  & section {
    margin-bottom: 120px;
  }
}

.reviews {
  display: flex;
  grid-gap: 30px;
  & .review {
    max-width: 325px;
  }
}

.teacher-back{
  padding: 60px 0;
  & .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  background-color: #161931;
  margin-bottom: 120px;

  & h2 {
    color: #fff;
  }
}

</style>
<style lang="scss">
.course-landing .edit-btn {
  height: 55px;
  width: 55px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);

  & svg {
    fill: rgba(#161931, .5);
    transition: fill .3s ease-in-out;
  }

  &:hover svg {
    fill: #161931;
  }
}
</style>
