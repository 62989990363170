<template>
  <div class="form">
    <NewSchoolForm v-if="logged()" />
    <Form
      v-else
      @finish="registration()"
      class="form-registration"
      autocomplete="off"
    >
      <Input
        type="text"
        placeholder="Имя компаний"
        required
        v-model="formData.companyName"
        @success="hasError.companyName = false"
        @error="hasError.companyName = true"
      />
      <Input
        type="text"
        placeholder="Имя"
        required
        v-model="formData.name"
        @success="hasError.name = false"
        @error="hasError.name = true"
      />
      <Input
        type="tel"
        placeholder="Номер телефона"
        required
        validation="phone"
        v-model="formData.phone"
        @success="hasError.phone = false"
        @error="hasError.phone = true"
      />
      <Input
        type="email"
        placeholder="Адрес электронной почты"
        required
        validation="email"
        v-model="formData.email"
        @success="hasError.email = false"
        @error="hasError.email = true"
      />
      <Input
        type="password"
        placeholder="Пароль"
        required
        validation="password"
        v-model="formData.password"
        @success="hasError.password = false"
        @error="hasError.password = true"
      />
      <Input
        type="password"
        placeholder="Повторитe пароль"
        required
        validation="sameAs"
        :sameAs="formData.password"
        v-model="formData.passwordConfirm"
        @success="hasError.passwordConfirm = false"
        @error="hasError.passwordConfirm = true"
      />
      <div class="agreement">
        <span>Отправляя нам ваши контактные данные, вы соглашаетесь с <a href="/docs/Оферта Codetau.pdf" download="">договором оферты</a> и <a href="/docs/Политика Конфиденциальности CodeTau.pdf" download="">политикой конфиденциалности</a></span>
      </div>
      <Button
        htmlType="submit"
        :disabled="handleError(hasError)"
        v-wave
        name="Зарегистрироваться"
        :loading="isLoading"
        size="large"
      />
    </Form>
  </div>
  <transition name="modal">
    <div class="modal" v-if="codeModalIsActive">
      <div class="container">
        <h2>Введите код из сообшения на телефоне</h2>
        <form method="POST" @submit.prevent="checkCodeRequest()">
          <div class="input">
            <input type="tel" name="code" maxlength="1" @focus="focusOnLeftInput(i)" @input="checkCode(i)" v-for="i in 6" :key="i" :class="'input-code-' + i" autocomplete="off">
          </div>
          <transition name="modal" mode="out-in">
            <span class="timer" v-if="formattedTimer">{{ formattedTimer }}</span>
            <span class="resend" v-else>Код не пришел?<span @click="registration">Отправить снова</span></span>
          </transition>
          <button class="disabled" type="submit" v-wave>Отправить</button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script setup>
import Form from '../common/Form.vue'
import NewSchoolForm from '../uiBusiness/Schools/NewSchoolForm.vue'

import { useRouter } from 'vue-router'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { reactive, ref } from 'vue'

import api from '@/modules/api'
import { useToast } from '@/modules/toast'
import { useAuth } from '@/modules/auth'

// ====================  VARIABLES  ====================
const router = useRouter()
const { logged, reloadUser } = useAuth()
const { toast } = useToast()
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
const token = ref()
const codeModalIsActive = ref(false)
const verificationCode = ref('')
const formData = reactive({
  companyName: '',
  name: '',
  email: '',
  phone: '+7 ',
  password: '',
  passwordConfirm: ''
})
let formattedNumberToBack
const timerLeft = ref(120)
const formattedTimer = ref(true)

const isLoading = ref(false)

const hasError = reactive({
  companyName: true,
  name: true,
  email: true,
  phone: true,
  password: true,
  passwordConfirm: true
})

const handleError = fields => {
  for (const field in fields) {
    if (fields[field]) return true
  }
  return false
}

// ====================  RECAPTCHA  ====================

const recaptcha = async () => {
  await recaptchaLoaded()
  token.value = await executeRecaptcha('login')
}

// ====================  REGISTER  ====================

const registration = async () => {
  await recaptcha()
  isLoading.value = true
  api.requestPost(
    '/api/registration/get-code',
    {
      phone_number: formatToPhone(formData.phone),
      recaptcha_token: token.value
    },
    response => {
      codeModalIsActive.value = true
      timerLeft.value = 120
      timer()
      document.querySelector('.main').style.filter = 'blur(1rem)'
    },
    () => {
      isLoading.value = false
    }
  )
}

// ====================  FORMAT PHONE NUMBER  ====================

const formatToPhone = input => {
  const phoneHead = 7
  const areaCode = input.substring(4, 7)
  const middle = input.substring(9, 12)
  const last = input.substring(13, 17)

  formattedNumberToBack = `+${phoneHead}${areaCode}${middle}${last}`
  console.log(formattedNumberToBack)
  return formattedNumberToBack
}

// ====================  CHECK CODE  ====================

const checkCode = (item) => {
  const previousInput = document.querySelector(`.input-code-${item - 1}`)
  const currentInput = document.querySelector(`.input-code-${item}`)
  const nextInput = document.querySelector(`.input-code-${item + 1}`)
  verificationCode.value = ''
  const inputs = document.querySelectorAll('input[name="code"]')
  for (const i of inputs) {
    verificationCode.value = verificationCode.value + i.value
  }
  const btn = document.querySelectorAll('button[type="submit"]')
  if (verificationCode.value.length === 6) {
    for (const el of btn) {
      el.classList.remove('disabled')
    }
  } else {
    document.querySelectorAll('button[type="submit"]')
    for (const el of btn) {
      el.classList.add('disabled')
    }
  }
  if (currentInput.value.length > 0 && item !== 6) {
    nextInput.focus()
  }
  const backSpace = event => {
    if (event.keyCode === 8) {
      currentInput.value = null
      if (item !== 1) {
        previousInput.focus()
      }
    }
    if (verificationCode.value.length === 6) {
      for (const el of btn) {
        el.classList.remove('disabled')
      }
    } else {
      document.querySelectorAll('button[type="submit"]')
      for (const el of btn) {
        el.classList.add('disabled')
      }
    }
  }
  currentInput.addEventListener('keydown', backSpace)
}

const checkCodeRequest = () => {
  api.requestPost(
    '/api/registration/check-code',
    {
      phone_number: formattedNumberToBack,
      code: verificationCode.value
    },
    response => {
      api.requestPost(
        '/api/registration/register',
        {
          name: formData.name,
          email: formData.email,
          phone: formattedNumberToBack,
          password: formData.password
        },
        response => {
          const { setUser } = useAuth()
          api.requestPost(
            '/api/sanctum/token',
            {
              email: formData.email,
              password: formData.password
            },
            response => {
              setUser({
                access_token: response.data.token
              })
              api.requestPost(
                '/api/schools',
                {
                  name: formData.companyName
                },
                response => {
                  toast({
                    message: 'Школа успешно создана',
                    type: 'success'
                  })

                  setTimeout(() => {
                    toast({
                      message: 'Ваша школа отобразится на маркетплейсе школ, как только вы опубликуете курс с уроками'
                    })
                  }, 700)
                  localStorage.setItem('selected_school', JSON.stringify(response.data.data))
                  reloadUser()
                  router.push(`/school/${response.data.data.id}`)
                },
                () => {
                  isLoading.value = false
                }
              )
            },
            () => {
              isLoading.value = false
            }
          )
        },
        () => {
          isLoading.value = false
          codeModalIsActive.value = false
          document.querySelector('.main').style.filter = 'none'
        }
      )
    }
  )
}

const focusOnLeftInput = (item) => {
  const previousInput = document.querySelector(`.input-code-${item - 1}`)
  const input = document.querySelector(`.input-code-${item}`)
  const allInputs = document.querySelectorAll('input[name="code"]')
  const btn = document.querySelectorAll('button[type="submit"]')

  if (input.value.length < 1) {
    for (const el of allInputs) {
      if (el.value.length < 1) {
        el.focus()
        break
      }
    }
  }
  const backSpace = event => {
    if (event.keyCode === 8) {
      input.value = null
      if (item !== 1) {
        previousInput.focus()
      }
    }
    if (verificationCode.value.length === 6) {
      for (const el of btn) {
        el.classList.remove('disabled')
      }
    } else {
      document.querySelectorAll('button[type="submit"]')
      for (const el of btn) {
        el.classList.add('disabled')
      }
    }
  }
  input.addEventListener('keydown', backSpace)
}

// ====================  CHECK CODE TIMER  ====================

const timer = () => {
  const timeLeft = timerLeft.value
  const minutes = Math.floor(timeLeft / 60)
  let seconds = timeLeft % 60

  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  if (timeLeft > 0) {
    setTimeout(() => {
      timerLeft.value--
      timer()
    }, 1000)
    formattedTimer.value = `${minutes}:${seconds}`
  } else {
    formattedTimer.value = false
  }
}
</script>

<style scoped>
form {
  display: grid;
  gap: 10px;
  align-items: center;
}
form.form-login {
  min-width: 500px;
}
.sketch {
  grid-column-start: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.sketch .preview .delete-image {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 1;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.sketch .preview {
  border: 1px solid #161931;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  padding-top: 52.78%;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}
.sketch .preview img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.agreement {
  display: flex;
  align-items: center;
  gap: 12px;
  grid-column-start: 1;
}
.agreement span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #161931;
  transition: all .2s ease;
}
.agreement span a {
  color: var(--accent-color);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}
.v-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
/* ============ Modal ============ */
.modal {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  backdrop-filter: blur(10px);
  z-index: 2;
}
/* .container {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
} */
.container h2 {
  font-size: 34px;
  margin-bottom: 20px;
  text-align: center;
}
.container .input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.container .timer {
  grid-column-start: 1;
  text-align: center;
}
.container .resend {
  grid-column-start: 1;
  text-align: center;
}
.container .resend span {
  color: var(--primary-color);
  margin-left: 10px;
  cursor: pointer;
}
.container .resend span:hover {
  border-bottom: 1px solid var(--primary-color);
}
.container .input input[name="code"] {
  padding: 10px;
  font-size: 3em;
  width: 50px;
}
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
</style>
