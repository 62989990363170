<template>
  <Button type="dark" name="Завершить курс" @click="checkFinish" />
  <ReviewModal
    :hide="hideModal"
    v-if="modalIsActive"
    @next="handleSuccess"
    :success="success"
    :loading="loading"
  >
    <StepOne
      v-if="step === 1"
      v-model="courseReview"
    />
    <StepTwo
      v-else-if="step === 2"
      v-model:school="schoolReview"
      v-model:teacher="teacherReview"
    />
    <template #success>
      <div class="review__success-block">
        <h2 class="review__success-text">
          Большое спасибо за оставленный отзыв. Будем стараться радовать Вас
          своей работой!
        </h2>
      </div>
    </template>
  </ReviewModal>
</template>

<script>
import { computed, inject, reactive, toRefs } from 'vue-demi'
import ReviewModal from './reviews/ReviewModal.vue'
import StepOne from './reviews/StepOne.vue'
import StepTwo from './reviews/StepTwo.vue'
import { leaveFeedback } from '@/actions/uiLearning/review'
import { useRouter } from 'vue-router'

export default {
  name: 'FinishCourse',
  components: { ReviewModal, StepOne, StepTwo },
  props: {
    finished: {
      type: String,
      default: null
    }
  },
  emits: ['finish'],
  setup (props, { emit }) {
    const courseData = inject('courseData')
    const router = useRouter()

    const courseId = computed(() => courseData.value.id)
    const schoolId = computed(() => courseData.value.school_id)
    const teacherId = computed(() => courseData.value.teachers[0]?.id)

    const states = reactive({
      modalIsActive: false,
      step: 1,
      loading: false,
      success: false,
      courseReview: {
        rating: 1,
        comment: ''
      },
      schoolReview: {
        rating: 1,
        comment: ''
      },
      teacherReview: {
        rating: 1,
        comment: ''
      }
    })

    const hideModal = () => {
      states.modalIsActive = false
    }

    const getReviewData = () => [
      {
        id: courseId.value,
        ...states.courseReview
      },
      {
        id: schoolId.value,
        ...states.schoolReview
      },
      {
        id: teacherId.value,
        ...states.teacherReview
      }
    ]

    const handleSuccess = async () => {
      if (states.step === 1) {
        states.step = 2
      } else {
        states.loading = true
        console.log('go')
        await leaveFeedback(...getReviewData())
        console.log('finish')
        emit('finish')
        states.success = true
        states.loading = false
      }
    }

    const checkFinish = () => {
      if (props.finished) router.push('/learning')
      else states.modalIsActive = true
    }

    return {
      ...toRefs(states),
      hideModal,
      handleSuccess,
      checkFinish
    }
  }
}
</script>

<style scoped>
.review__success {
  min-height: 100vh;
  background-color: #0082fa;
}
.review__success-block {
  position: absolute;
  top: 45%;
  width: 100%;
}
.review__success-text {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  color: white;
  font-size: 36px;
  line-height: 40px;
}
</style>
