<template>
  <div class="blue__background">
    <div class="wrapper-system">
      <div class="schedule__header">
        <h1>Расписание</h1>
        <div class="actions">
          <ScheduleSearch @search="handleSearch" @filter="handleFilter" />
          <Button
            v-if="$route.fullPath === '/business/schedule'"
            name="Создать карточку"
            type="dark"
            :icon="{
              name: 'plus',
              viewboxHeight: 14,
              viewboxWidth: 14,
              width: 14,
              height: 14,
              fill: 'white',
              position: 'left'
            }"
            @click="scheduleModal = true"
          />
        </div>
      </div>
      <ScheduleDatePicker
        @pick="handlePickDay"
        :selectedDay="filters.selectedDay"
      />
      <ScheduleTable :schedules="schedules" :loading="loading" />
      <Transition>
        <ScheduleModal v-if="scheduleModal" @hide="scheduleModal = false" @finish="loadData" />
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { getSchedulesList } from '@/actions/uiClient/schedule'
import { getUserCourses } from '@/actions/uiClient/courses'

import { onMounted, provide, reactive, toRefs } from 'vue-demi'

import ScheduleSearch from '@/components/uiClient/Schedule/ScheduleSearch.vue'
import ScheduleTable from '@/components/uiClient/Schedule/ScheduleTable.vue'
import ScheduleDatePicker from '@/components/uiClient/Schedule/ScheduleDatePicker.vue'
import ScheduleModal from '@/components/uiClient/Schedule/ScheduleModal.vue'

const today = new Date()
today.setHours(0, 0, 0, 0)

const states = reactive({
  schedules: [],
  filters: {
    selectedDay: today,
    name: null,
    lessonId: null
  },
  courses: null,
  scheduleModal: false,
  loading: true
})
const { schedules, filters, loading, courses, scheduleModal } = toRefs(states)

const mapFilters = ({ selectedDay, name, lessonId }) => ({
  date: selectedDay,
  name,
  lessonId
})

const loadData = async () => {
  states.loading = true
  try {
    states.schedules = (await getSchedulesList(mapFilters(states.filters))).data
  } finally {
    states.loading = false
  }
}
provide('reload', loadData)

onMounted(loadData)

onMounted(async () => {
  states.courses = (await getUserCourses()).data
})
provide('userCourses', courses)

const handleSearch = async (value) => {
  states.loading = true
  try {
    states.filters.name = value
    states.schedules = (await getSchedulesList(mapFilters(states.filters))).data
  } finally {
    states.loading = false
  }
}

const handlePickDay = async (day) => {
  states.loading = true
  try {
    states.filters.selectedDay = day
    states.schedules = (await getSchedulesList(mapFilters(states.filters))).data
  } finally {
    states.loading = false
  }
}

const handleFilter = async (lessonId) => {
  states.loading = true
  try {
    states.filters.lessonId = lessonId
    states.schedules = (await getSchedulesList(mapFilters(states.filters))).data
  } finally {
    states.loading = false
  }
}
</script>

<style scoped lang="scss">
.blue__background {
  background: #0082fa;
  min-height: calc(100vh - 70px);
}
.schedule__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 80%;
  }
}
h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #fff;
}
</style>
