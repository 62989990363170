<template>
  <div class="wrapper-system">
    <div class="wrapper-header">
      <h2>Личный кабинет</h2>
      <div class="btns" v-if="!editActive">
        <router-link to="/business/settings">
          <Button name="ИзМЕНИТЬ ПАРОЛЬ" type="cancel" />
        </router-link>
        <Button name="РЕДАКТИРОВАТЬ" @click="edit" />
      </div>
      <div class="btns" v-else>
        <Button name="Отмена" type="cancel" @click="cancel" />
        <Button name="Сохранить" @click="saveEdittedData"/>
      </div>
    </div>

   <div class="user-info">
      <div class="left">
        <div class="profile">
          <div class="avatar-info">
            <div class="avatar-container">
              <img
                v-if="data.user_photo"
                :src="data.user_photo"
                alt=""
                class="avatar"
              />
              <img
                v-else-if="user.data.user_photo"
                :src="getFileUrl(user.data.user_photo)"
                alt=""
                class="avatar"
              />
              <SvgIcon
                v-else
                name="avatar"
                :viewboxWidth="34"
                :viewboxHeight="34"
                :width="115"
                :height="115"
                class="avatar"
              />
              <div class="upload-avatar" v-if="editActive"  @click="uploadPhoto">
                <input type="file" class="upload-photo" @change="uploadPhotoRun" accept="image/png, image/gif, image/jpeg, image/svg" style="display: none;">
                <SvgIcon class="edit-avatar" name="edit" :viewboxHeight="24" :viewboxWidth="24" :width="24" :height="24" fill="#161931"/>
              </div>
            </div>
            <div class="profile-info">
              <input v-if="editActive" class="username" type="text" v-model="data.name" />
              <p class="username" v-else>
                <span>{{ user.data.name }}</span>
              </p>

              <p class="user-prof">
                <span v-if="user.data.role === 'user'">Пользователь</span>
                <span v-else-if="user.data.role === 'student'">Ученик</span>
                <span v-else-if="user.data.role === 'teacher'">Учитель</span>
                <span v-else-if="user.data.role === 'superadmin'">Админ</span>
              </p>

              <!-- <div class="rating">
                <div class="text">120 голосов</div>
                <StarRating v-model="rating" />
              </div> -->
            </div>
          </div>

          <div class="profile-descriptions">
            <div class="description">
              <p>Mobile: </p>
              <input v-if="editActive" type="text" v-model="data.phone" v-mask="'+7 (###) ### ####'" />
              <span v-else class="text">{{ user.data.phone }}</span>
            </div>
            <div class="description">
              <p>Email: </p>
              <input v-if="editActive" type="email" v-model="data.email" />
              <span v-else class="text">{{ user.data.email }}</span>
            </div>
          </div>
        </div>
        <div class="info-block">
          <div class="group">
            <h4 class="info-title">Персональные данные</h4>
            <div class="personal-data">
              <div>
                <div class="info">
                  <h6 class="name">Дата рождения: </h6>
                  <input v-if="editActive" type="date" v-model="data.birthday" />
                  <span v-else class="value">{{
                    moment(user.data.birthday).format('DD.MM.YYYY')
                  }}</span>
                </div>
              </div>
              <div class="personal-data-right">
                <div class="info">
                  <span class="name">Добавлено: </span>
                  <span v-if="user.data.email_verified_at" class="value">{{
                    moment(user.data.email_verified_at).format('DD.MM.YYYY г., HH:mm:ss')
                  }}</span>
                  <span v-else class="value">Почта не верифицирована</span>
                </div>
              </div>
            </div>

          </div>
          <div class="group">
            <h4 class="info-title">Информация отображается в следующих типах уведомлений:</h4>
            <NotificationSettings />
          </div>
        </div>
      </div>
      <div class="right">
        <span class="title">Мои курсы</span>
        <div class="courses" v-if="coureseList.length">
          <div v-for="course in coureseList.slice(0, 4)" :key="course">
            <router-link :to="'/lesson/' + course.id" :class="'card card--' + course.id" v-wave v-if="course.status === 'published'">
              <div class="main">
                <div>
                  <span class="type">{{ course.online == true ? "Курс" : "Коучинговая программа"}}</span>
                  <span class="name">{{ course.name }}</span>
                </div>
                <ImageLoader class="course-avatar" v-if="course.course_logo !== null" :src="course.course_logo"/>
                <div v-else class="default-image">{{ abbreviatedCourseName(course) }}</div>
              </div>
              <div class="info">Школа:</div>
            </router-link>
          </div>
        </div>
        <div class="courses" v-else>
          <div v-for="course in 4" :key="course">
            <div class="card">
              <div class="main">
                <div>
                  <span class="type skeleton small-text"></span>
                  <span class="name skeleton middle-text"></span>
                </div>
                <div class="default-image skeleton"></div>
              </div>
              <div class="info skeleton small-text"></div>
            </div>
          </div>
        </div>
        <div class="seeAll">
          <router-link to="/business/courses">
            <span>Просмотреть все</span>
            <SvgIcon
              name="arrow-right"
              :viewboxWidth="32"
              :viewboxHeight="32"
              :width="32"
              :height="32"
              fill="#0082FA"
              class="avatar"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ImageLoader from '@/components/common/ImageLoader.vue'
// import StarRating from '@/components/uiLearning/reviews/StarRating.vue'
import NotificationSettings from '@/components/NotificationSettings.vue'

import moment from 'moment'
import { onUpdated } from 'vue-demi'
import { makePalette } from 'material-color-tool'
import { reactive, ref } from 'vue'
import FastAverageColor from 'fast-average-color'

import { useAuth } from '@/modules/auth'
import api from '@/modules/api'
import getFileUrl from '@/helpers/getFileUrlByDate'

const { user, reloadUser } = useAuth()
const data = reactive({})
const fac = new FastAverageColor()
const editActive = ref(false)
const formData = new FormData()
const coureseList = ref([])
// const rating = ref(3)

const edit = () => {
  Object.assign(data, user.value.data)
  data.user_photo = null
  editActive.value = true
}
const saveEdittedData = () => {
  formData.set('name', data.name)
  formData.set('email', data.email)
  if (data.gender) formData.set('gender', data.gender)
  formData.set('birthday', data.birthday)
  formData.set('phone', formatToPhone(data.phone))

  api.requestPost('/api/user?_method=PUT', formData, response => {
    reloadUser()
    data.user_photo = null
    editActive.value = false
  })
}

const cancel = () => {
  editActive.value = false
  Object.assign(data, user.value.data)
  data.user_photo = null
}

const uploadPhoto = () => {
  document.querySelector('.upload-photo').click()
}

const uploadPhotoRun = event => {
  const reader = new FileReader()
  reader.readAsDataURL(event.target.files[0])
  reader.onload = () => {
    data.user_photo = reader.result
  }
  formData.set('user_photo', event.target.files[0])
}

const formatToPhone = input => {
  const phoneHead = 7
  const areaCode = input.substring(4, 7)
  const middle = input.substring(9, 12)
  const last = input.substring(13, 17)

  return `+${phoneHead}${areaCode}${middle}${last}`
}

const setBackgroundColor = () => {
  const cards = document.querySelectorAll('.card')
  for (const card of cards) {
    const img = card.querySelector('img')
    if (img) {
      fac.getColorAsync(img)
        .then(color => {
          card.style.backgroundColor = makePalette(color.hexa)[1]
        })
        .catch(e => {
          console.log(e)
        })
    } else card.style.backgroundColor = '#16193115'
  }
}
onUpdated(() => setBackgroundColor())

api.requestGet(
  '/api/student/courses',
  {},
  response => {
    coureseList.value = response.data.data
  }
)

const abbreviatedCourseName = item => {
  const splittedName = item.name.toUpperCase().trim().split(' ')
  if (splittedName.length > 1) return splittedName[0][0] + splittedName[1][0]
  else return splittedName[0][0] + splittedName[0][1]
}
</script>

<style scoped>
.wrapper-header{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 20px;
  margin-bottom: 20px;
}
.wrapper-header h2 {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
}
.wrapper-header .btns{
  display: flex;
  grid-gap: 20px;
}
.edit {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  padding: 5px;
  border-radius: 3px;
}
.user-info {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-gap: 20px;
  width: calc(100% - 20px);
  margin-bottom: 20px;
}

/* -------- User Profile --------- */
.user-info .left .profile {
  display: flex;
  justify-content: space-between;
  background: #F1F3F4;
  border-radius: 10px;
  padding: 20px 40px 24px 20px;
  margin-bottom: 25px;
}
.user-info .left .profile .avatar-info{
  display: flex;
  gap: 20px;
}
.user-info .left .profile .avatar-container {
  position: relative;
}
.user-info .left .profile .upload-avatar {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  transition: all .3s ease;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  padding: 10px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}
.user-info .left .profile .avatar-container:hover .upload-avatar {
  opacity: 1;
  pointer-events: all;
}
.user-info .left .profile img {
  width: 115px;
  height: 115px;
  object-fit: cover;
}
.user-info .left .profile .profile-info .username {
  margin-bottom: 10px;
}
.user-info .left .profile .profile-info .username span {
  font-weight: 600;
  font-size: 24px;
  color: #000000;
}
.user-info .left .profile .profile-info .user-prof {
  font-size: 16px;
  color: #161931;
  margin-bottom: 15px;
}
.user-info .left .profile .profile-info .rating .text {
  font-size: 16px;
  color: #0082FA;
  margin-bottom: 4.5px;
}
.user-info .left .profile .profile-descriptions {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
}
.user-info .left .profile .profile-descriptions .description p,
.user-info .left .profile .profile-descriptions .description .text {
  display: inline;
  font-weight: 400;
  font-size: 16px;
  color: #161931;
}
.user-info .left .profile .profile-descriptions .description p {
  font-weight: 600;
}

/* -------- User Info Block --------- */
.user-info .left .info-block {
  padding: 0 20px;
  margin-bottom: 50px;
}
.user-info .left .info-block .group {
  padding: 25px 20px 25px 0;
  border-bottom: 1px solid rgba(22, 25, 49, 0.1);
}
.user-info .left .info-block .group:first-child {
  padding-top: 0;
}
.user-info .left .info-block .group:last-child{
  padding-bottom: 0;
  border-bottom: none;
}
.user-info .left .info-block .personal-data {
  display: flex;
  justify-content: space-between;
}

.user-info .left .info-block .info-title {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}
.user-info .left .info-block .info .name {
  display: inline;
  font-weight: 600;
  font-size: 16px;
  color: #161931;
}
.user-info .left .info-block .info .value {
  font-weight: 400;
  font-size: 16px;
  color: #161931;
}
.user-info .right {
  padding: 20px;
  border: 0.5px solid #161931;
  border-radius: 10px;
}
.user-info .right .title{
  display: block;
  font-size: 20px;
  margin-bottom: 20px;
}

.user-info .right .courses{
  width: 100%;
}
.user-info .right .courses .card{
  display: block;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid transparent;
  margin-bottom: 15px !important;
  background-color: #ECECEE;
}
.user-info .right .courses .card:hover{
  border-color: #000;
}
.user-info .right .courses .card:last-child{
  margin-bottom: 0;
}
.user-info .right .courses .main{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.user-info .right .courses .card .type{
  display: block;
  font-size: 14px;
  letter-spacing: 2%;
  margin-bottom: 10px;
}
.user-info .right .courses .card .name{
  display: block;
  max-width: 145px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 2%;
}
.user-info .right .courses .card .info{
  font-size: 14px;
  letter-spacing: 2%;
}
.user-info .right .courses .card .img,
.user-info .right .courses .card img {
  display: block;
  width: 104px;
  height: 104px;
  background-color: #fff;
  border-radius: 8px;
  object-fit: contain;
}
.user-info .right .courses .card .default-image {
  width: 104px;
  height: 104px;
  line-height: 100px;
  text-align: center;
  background: var(--primary-color);
  border-radius: 10px;
  color: #fff;
  font-size: 40px;
}
.user-info .right .seeAll {
  display: flex;
  justify-content: flex-end;
}
.user-info .right .seeAll a {
  display: inline-flex;
  align-items: center;
  grid-gap: 20px;
}
.user-info .right .seeAll span {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #0082FA;
}
.user-info .right .seeAll svg {
  position: relative;
  left: -8px;
  transition: all .2s ease;
}
.user-info .right .seeAll:hover svg{
  left: 0;
}

.user-info .left input,
.user-info .left select {
  min-width: 240px;
  display: block;
  color: #161931;
  padding: 16px;
  font-size: 14px;
}
.user-info .left .profile-info input[type='text'] {
  text-transform: capitalize;
}
</style>
<style>
.user-info .left .profile .profile-info .rating .review_stars{
  grid-gap: 4.6px;
}
.user-info .left .profile .profile-info .rating .review_stars svg{
  height: 15px;
  width: 15px;
}
</style>
