<template>
  <div class="wrapper-system" v-if="data.name">
    <h1>
      <span>{{ data.name }}</span>
    </h1>
    <div class="lesson-content" v-html="data.content"></div>
    <section>
      <form action="#" @submit.prevent="">
        <div class="task">

          <div class="question type--textarea" v-if="taskOnView.type === 'textarea'">
            <span class="title">{{ getIndex(taskOnView, data.tasks) }}. Задача</span>
            <div class="homework-content" v-html="taskOnView.content"></div>
            <!-- <textarea rows="3" placeholder="Ваш ответ" v-model="inputtedData.answer"></textarea>  -->
            <CKEditor v-model="inputtedData.answer" placeholder="Ваш ответ"/>
          </div>

          <div class="question type--test" v-if="taskOnView.type === 'test'">
            <span class="title">{{ getIndex(taskOnView, data.tasks) }}. Тест</span>
            <div class="homework-content" v-html="taskOnView.content"></div>
            <div class="answer" v-for="value in taskOnView.answers" :key="value">
              <input
                type="radio"
                :name="'radio_' + getIndex(taskOnView, data.tasks)"
                :id="'checkbox--' + + getIndex(taskOnView, data.tasks) + '--' + getIndex(value, taskOnView.answers)"
                :value="value.id"
                v-model="inputtedData.task_answer_id"
              >
              <label :for="'checkbox--' + getIndex(taskOnView, data.tasks) + '--' + getIndex(value, taskOnView.answers)">{{toLetters(getIndex(value, taskOnView.answers))}}. {{ value.answer }}</label>
            </div>
          </div>

          <div class="question type--file" v-if="taskOnView.type === 'file'">
            <span class="title">{{ getIndex(taskOnView, data.tasks) }}. Задача</span>
            <div class="homework-content" v-html="taskOnView.content"></div>
            <textarea rows="3" placeholder="Комментарий" v-model="inputtedData.answer"/>
            <input :id="'file-upload--' + getIndex(taskOnView, data.tasks)" type="file" class="upload-file" @change="uploadFiles">
            <label v-wave :for="'file-upload--' + getIndex(taskOnView, data.tasks)" class="custom-file-upload">
                Загрузить файл
            </label>
            <span v-if="inputtedData.answer_file">{{ inputtedData.answer_file.name }}</span>
          </div>

        </div>
        <div class="homework-footer">
          <Button
            name="Отправить"
            @click="onSendTake"
            class="send-button"
            :disabled="!dataIsInputted() || fullAccess"
            :loading="loading"
          />
          <div class="controllers">
            <Button
              v-if="taskOnView !== data.tasks[0]"
              type="default-outlined"
              name="Предыдущее задание"
              @click="previousHomework"
            />
            <Button
              v-if="taskOnView !== data.tasks[data.tasks.length-1]"
              type="default-outlined"
              name="Следующее задание"
              @click="nextHomework"
            />
            <Button
              v-if="homeworkIsCompleted && nextLessonId || fullAccess"
              type="dark"
              name="Следующий урок"
              @click="goNextLesson"
            >
              <SvgIcon name="chevron-right" :viewboxHeight="40" :viewboxWidth="24" :width="11" :height="18" fill="#fff" style="margin-left: 10px;"/>
            </Button>
            <FinishCourse
              v-if="homeworkIsCompleted && !nextLessonId"
              @finish="finishCourse"
              :finished="finished_at"
            />
          </div>
        </div>
      </form>
    </section>
  </div>
  <div class="loading-container" v-else>
    <div class="background">
      <Loading name="sk" color="#161931" :width="75" :height="75"/>
    </div>
  </div>
</template>

<script setup>
import FinishCourse from './FinishCourse.vue'
import Loading from '@/components/common/Loading.vue'
import CKEditor from '@/components/common/CKEditor.vue'

import hljs from 'highlight.js'

import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, onUpdated, ref, watch } from 'vue-demi'

import { useAuth } from '@/modules/auth'
import { useToast } from '@/modules/toast'
import { useCourseProgress } from '@/modules/learning'

import checkFullAccess from '@/helpers/checkFullAccess'

import { getHomeworkData, getNextLessonId, getTakes, sendTake } from '@/actions/uiLearning/homework'
import { finishLesson } from '@/actions/uiLearning/lesson'

const route = useRoute()
const router = useRouter()
const { user } = useAuth()
const { toast } = useToast()
const { setFinishedLessons } = useCourseProgress()

const fullAccess = checkFullAccess(route.params.course_id, user.value.data)

const goNextLesson = async () => {
  if (!fullAccess) {
    await finishLesson(data.value.lesson_id)
    setFinishedLessons()
  }
  router.push(`/lesson/${route.params.course_id}/unit/${nextLessonId.value}`)
}

const finished_at = ref(null)

const taskOnView = ref({})
const data = ref({})
const takeId = ref()
const inputtedData = ref({
  task_answer_id: null,
  answer: '',
  answer_file: null
})
const savedData = ref({})
const takesCount = ref(0)
const nextLessonId = ref(null)
const loading = ref(false)

const getData = async () => {
  const homeworkId = route.params.homework_id
  data.value = {}
  taskOnView.value = null

  const homeworkData = (await getHomeworkData(homeworkId)).data
  homeworkData.tasks.forEach(element => {
    element.answers.forEach(item => {
      item.is_correct = !!item.is_correct
    })
  })

  data.value = homeworkData
  taskOnView.value = homeworkData.tasks[0]
  takeId.value = null
  nextLessonId.value = (await getNextLessonId(homeworkData.lesson_id)).data.next_lesson_id

  const take = ref(null)
  if (!fullAccess) {
    take.value = await getTakes(homeworkId)
    finished_at.value = take.finished_at
    takeId.value = take.id
    takesCount.value = take.answers.length
    for (const answer of take.answers) {
      for (const task of data.value.tasks) {
        if (task.id === answer.task_id) {
          savedData.value[task.id] = Object.assign({}, answer)
        }
      }
    }
    if (taskOnView.value.id in savedData.value) inputtedData.value = savedData.value[taskOnView.value.id]
  }
}
onMounted(() => getData())

const onSendTake = async () => {
  const sendData = async () => {
    await sendTake(takeId.value, taskOnView.value.id, inputtedData.value)
    takesCount.value++
    toast({
      message: 'Отправлено',
      type: 'success'
    })
  }

  loading.value = true
  try {
    if (!takeId.value) {
      const take = await getTakes(Number(route.params.homework_id))
      takeId.value = take.id
      if (dataIsInputted()) {
        await sendData()
      }
    } else if (dataIsInputted()) {
      await sendData()
    } else {
      toast({
        message: 'Введите ответы',
        type: 'warning'
      })
    }
  } finally {
    loading.value = false
  }
}

const uploadFiles = event => {
  if (event.target.files.length > 0) inputtedData.value.answer_file = event.target.files[0]
}

const nextHomework = () => {
  if (taskOnView.value !== data.value.tasks[data.value.tasks - 1]) {
    savedData.value[taskOnView.value.id] = Object.assign({}, inputtedData.value)
    taskOnView.value = data.value.tasks[data.value.tasks.indexOf(taskOnView.value) + 1]
    clearInputtedData()
    if (taskOnView.value.id in savedData.value) inputtedData.value = savedData.value[taskOnView.value.id]
  }
}
const previousHomework = () => {
  if (taskOnView.value !== data.value.tasks[0]) {
    savedData.value[taskOnView.value.id] = Object.assign({}, inputtedData.value)
    taskOnView.value = data.value.tasks[data.value.tasks.indexOf(taskOnView.value) - 1]
    clearInputtedData()
    if (taskOnView.value.id in savedData.value) inputtedData.value = savedData.value[taskOnView.value.id]
  }
}

const dataIsInputted = () => {
  if (taskOnView.value.type === 'file') return Boolean(inputtedData.value.answer_file)
  return inputtedData.value.task_answer_id || inputtedData.value.answer || inputtedData.value.answer_file
}

const clearInputtedData = () => {
  inputtedData.value = {
    task_answer_id: null,
    answer: '',
    answer_file: null
  }
}

const getIndex = (item, arr) => {
  return arr.indexOf(item) + 1
}

const homeworkIsCompleted = computed(() => takesCount.value === data.value?.tasks?.length)

const toLetters = (num) => {
  const mod = num % 26
  let pow = num / 26 | 0
  const out = mod ? String.fromCharCode(64 + mod) : (--pow)
  return pow ? toLetters(pow) + out : out
}

watch(
  () => route.params.homework_id,
  () => {
    if (route.params.homework_id) {
      clearInputtedData()
      getData()
    }
  }
)

onUpdated(() => {
  document.querySelectorAll('code').forEach(el => {
    hljs.highlightElement(el)
  })
})

const finishCourse = async () => {
  await finishLesson(data.value.lesson_id)
  router.push('/learning')
}
</script>

<style scoped>
h1 {
  margin-bottom: 15px;
}
h1 span {
  font-weight: 600;
  font-size: 26px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
section {
  border: 0.5px solid #161931;
  border-radius: 10px;
  padding: 30px;
}
section form {
  display: grid;
  gap: 10px;
}
section form h2 {
  font-size: 1.2em;
  font-weight: 500;
}
.homework-content {
  margin-bottom: 20px;
}
.task {
  margin-bottom: 20px;
}
.task .question {
  display: grid;
  gap: 15px;
}
.task .question textarea {
  border: 1px solid #161931;
  border-radius: 3px;
  padding: 15px;
  resize:vertical;
  min-height: 5pc;
  width: 100%;
}
.answer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox + label::before {
  width: 18px;
  height: 18px;
  content: '';
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  margin-right: 0.5em;
  transition-duration: 0.2s;
  cursor: pointer;
}
.custom-checkbox + label:active {
  transform: scale(80%);
}
.custom-checkbox:checked + label::before {
  transition-duration: 0.2s;
  border-color: #161931;
  background: #161931;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
label {
  color: #161931;
  transition: all .2s ease;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  padding: 17px;
  background: var(--primary-color);
  width: fit-content;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  font-weight: normal;
}

.homework-footer {
  display: flex;
  justify-content: space-between;
}
.controllers {
  display: flex;
  gap: 20px;
}

.loading-container {
  height: calc(100vh - 70px);
  position: relative;
}
.loading-container .background {
  background: #16193104;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
button.disabled {
  opacity: .5;
  pointer-events: none;
}
</style>
<style>
.homework-content * {
  text-decoration: revert;
  list-style-type: revert;
  margin: revert;
  padding: revert;
  border: revert;
  list-style-type: revert;
}
.homework-content iframe {
  border: none;
}
.homework-content img {
  width: 100%;
}
.homework-content pre code.hljs {
  background: #f3f4f6;
  border-radius: 10px;
}
.homework-content .image-style-side {
  max-width: 50%;
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.homework-content a {
  color: var(--accent-color);
  cursor: pointer;
  text-decoration: none;
}
.homework-content a:hover {
  text-decoration: underline;
}
.homework-content .image.image_resized {
  display: block;
  box-sizing: border-box;
}

.homework-content .image.image_resized img {
  width: 100%;
}

.homework-content .image.image_resized > figcaption {
  display: block;
}
</style>
