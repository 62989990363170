<template>
  <transition mode="out-in">
    <table v-if="tableValues.length > 0">
      <tr class="headers">
        <th class="avatar"></th>
        <th class="name">Наименование школы</th>
        <th>Сумма (тг)</th>
        <th>Дата</th>
        <th>Операции</th>
      </tr>
      <tr v-for="value in values" :key="value" class="values">
        <td class="avatar" ><SvgIcon name="avatar" :viewboxWidth="34" :viewboxHeight="34" :width="34" :height="34" /></td>
        <td class="schoolName">{{ value.payable.name }}</td>
        <td class="amount">{{ formatCurrency(value.amount) }}</td>
        <td class="date">{{ moment(value.email_verified_at).format('DD.MM.YYYY')}}</td>

        <td class="operations">
          <ApplicationState
            @click="() => {route.name === 'payments' ? showApplicationModal(value) : null}"
            :state="value.status"
            :superAdmin="route.name === 'payments'"
          />
        </td>
      </tr>
    </table>
    <table v-else class="disabled">
      <tr class="headers">
        <th class="avatar"></th>
        <th class="name"><span class="skeleton small-text"></span></th>
        <th><span class="skeleton small-text"></span></th>
        <th><span class="skeleton small-text"></span></th>
        <th><span class="skeleton small-text"></span></th>
        <th><span class="skeleton small-text"></span></th>
      </tr>
      <tr v-for="value in 20" :key="value" class="values">
        <td class="avatar"><div class="skeleton skeleton-img"></div></td>
        <td class="schoolName "><span class="skeleton long-text"></span></td>
        <td class="amount "><span class="skeleton small-text"></span></td>
        <td class="date"><span class="skeleton long-text"></span></td>
        <td class="role "><span class="skeleton small-text"></span></td>
        <td class="operations">
          <div class="buttons">
            <button class="extend skeleton">Просмотр</button>
          </div>
        </td>
      </tr>
    </table>
  </transition>
  <ApplicationModal
    :payout="payoutInfo"
    v-if="applicationModalIsActive"
    @hide="hideApplicationModal"
    @updateTable="() => {$emit('updateTable')}"
  />
</template>

<script setup>
import ApplicationState from '@/components/uiBusiness/Applications/ApplicationState.vue'
import ApplicationModal from '@/components/uiBusiness/Applications/ApplicationModal.vue'

import { computed, ref } from 'vue'
import moment from 'moment'
import { useRoute } from 'vue-router'

import formatCurrency from '@/helpers/formatCurrency.js'

const props = defineProps({
  tableValues: {
    type: Array,
    default: () => []
  },
  modelValue: {
    type: Number,
    default: 0
  }
})
defineEmits(['updateTable'])
const route = useRoute()

const values = computed(() => props.tableValues)

const applicationModalIsActive = ref(false)
const payoutInfo = ref()

const showApplicationModal = info => {
  payoutInfo.value = info
  applicationModalIsActive.value = true
}

const hideApplicationModal = () => {
  applicationModalIsActive.value = false
}
</script>

<style scoped lang="scss">
.requests-count {
  font-weight: bold;
  font-size: 16px;
  color: #0082FA;
  margin-bottom: 10px;
  display: block;
}
table {
  width: 100%;
  background: #FFFFFF;
  border-top: none;
  border-collapse: collapse;
}
hr {
  width: 100%;
}
table tr,
table td {
  padding: 10px;
  border-top: 1px solid rgba(22, 25, 49, 0.1);
  font-weight: normal;
  font-size: 16px;
  color: #161931;
}
table th {
  padding: 8px 10px 10px 10px;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  color: #161931;
  background: #fff;
}

table td{
  width: 180px;
}
table .avatar{
  width: 60px;
}
table .schoolName{
  width: 35%;
}
table{
  & .schoolName{
    width: 50%;
  }
}
table .operations {
  cursor: auto;
}
table .schoolName {
  font-weight: 500;
}
#reversetable {
  transition: transform .2s ease;
}
/* SKELETON */
.headers.skeleton,
.values.skeleton {
  pointer-events: none;
}
.values.skeleton .avatar {
  opacity: 0;
}
.skeleton.skeleton-img {
  width: 34px;
  height: 34px;
}
.headers.skeleton .avatar{
  color: rgba(0, 0, 0, 0);
}
.disabled {
  pointer-events: none;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
