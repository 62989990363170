<template>
  <section class="wrapper wrapper-landing intro">
    <h1 class="intro-title">
      Начни <span>получать</span> новые знания уже сейчас
    </h1>
    <div class="intro-collage">
      <div class="intro-collage-img">
        <picture>
          <source srcset="/img/tmp/uiLanding/Home/Intro/intro-1.webp" type="image/webp" />
          <img src="/img/tmp/uiLanding/Home/Intro/intro-1.jpg" alt=""/>
        </picture>
      </div>
      <div class="intro-collage-img">
        <picture>
          <source srcset="/img/tmp/uiLanding/Home/Intro/intro-2.webp" type="image/webp" />
          <img src="/img/tmp/uiLanding/Home/Intro/intro-2.jpg" alt=""/>
        </picture>
      </div>
      <div class="intro-collage-img">
        <picture>
          <source srcset="/img/tmp/uiLanding/Home/Intro/intro-3.webp" type="image/webp" />
          <img src="/img/tmp/uiLanding/Home/Intro/intro-3.jpg" alt=""/>
        </picture>
      </div>
      <div class="intro-collage-img">
        <picture>
          <source srcset="/img/tmp/uiLanding/Home/Intro/intro-4.webp" type="image/webp" />
          <img src="/img/tmp/uiLanding/Home/Intro/intro-4.jpg" alt=""/>
        </picture>
      </div>
      <div class="intro-collage-img">
        <picture>
          <source srcset="/img/tmp/uiLanding/Home/Intro/intro-5.webp" type="image/webp" />
          <img src="/img/tmp/uiLanding/Home/Intro/intro-5.jpg" alt=""/>
        </picture>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.intro{
  margin-bottom: 155px;

  &-title{
    font-weight: 700;
    font-size: 52px;
    line-height: 1.2;
    text-align: center;
    color: #070917;
    max-width: 850px;
    margin: 0 auto 40px;

    & span{
      color: #725EFE;
    }
  }

  &-collage{
    display: flex;
    align-items: center;
    grid-gap: 25px;

    &-img{
      width: 20%;
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      transition: width .4s ease;

      &:hover{
        width: 23%;
      }

      & img{
        display: block;
        max-height: 100%;

        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    &-img:nth-child(3) {
      width: 22%;
      height: 475px;
      &:hover{
        width: 25%;
      }
    }
    &-img:nth-child(2),
    &-img:nth-child(4) {
      height: 370px;
    }
    &-img:nth-child(1),
    &-img:nth-child(5) {
      height: 285px;
    }

    &-img:nth-child(1) { background-color: #ACE0D3; }
    &-img:nth-child(2) { background-color: #FF93CC; }
    &-img:nth-child(3) { background-color: #FFE081; }
    &-img:nth-child(4) { background-color: #75D1FF; }
    &-img:nth-child(5) { background-color: #60B59F; }
  }
}
</style>
