<template>
<main class="landing-page">
  <Header/>

  <section class="wrapper wrapper-landing">
    <h2 class="landing-title">Преподователи</h2>

    <div v-if="teachers" class="teachers">
      <div
        class="item"
        v-for="teacher of teachers"
        :key="teacher.id"
      >
        <TeacherCard :teacher="teacher" />
      </div>
    </div>
  </section>
  <Footer/>
</main>
</template>

<script setup>
import Header from '@/components/uiLanding/layouts/Header.vue'
import Footer from '@/components/uiLanding/layouts/Footer.vue'
import TeacherCard from '@/components/uiLanding/Teachers/TeacherCard.vue'

import { onMounted, ref } from 'vue-demi'
import { useRoute } from 'vue-router'

import { getSchoolData } from '@/actions/uiLanding/school'
import { getCourse } from '@/actions/uiBusiness/courses'

const route = useRoute()

const teachers = ref(null)

onMounted(async () => {
  if (route.params.school_id) teachers.value = (await getSchoolData(route.params.school_id)).data.teachers
  else teachers.value = (await getCourse(route.params.course_id)).data.teachers
})
</script>

<style scoped lang="scss">
.teachers {
  & .item:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
}
.item {
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid #eaeaea;
}

@media (max-width: 1440px) {
  .item {
    padding-bottom: 14px;
    margin-bottom: 14px;
  }
}
</style>
