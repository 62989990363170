<template>
  <div class="wrapper-system">
    <router-link class="back" to="/learning/homeworks">
      <SvgIcon name="back" :viewboxHeight="18" :viewboxWidth="18" :height="18" :width="18"/>
      <span>Домашнее задание</span>
    </router-link>
  </div>
</template>

<style scoped>
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all .3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
</style>
