import FastAverageColor from 'fast-average-color'
import { makePalette } from 'material-color-tool'

const getBackgroudColor = (element, brightness = 1, defaultColor = '#fff') => {
  const fac = new FastAverageColor()
  if (element) return makePalette(fac.getColor(element).hexa)[brightness]
  return defaultColor
}

export default getBackgroudColor
