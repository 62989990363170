<template>
  <main class="landing-page home">
    <Header />

    <Intro />

    <AboutUs  :statistics="statistics"/>

    <!-- --------------    Courses Block    -------------- -->
    <section class="wrapper wrapper-landing">
      <div class="landing-header">
        <h2 class="landing-title">Участвуй в онлайн курсах</h2>
        <router-link
          v-if="courses.length > 10"
          class="landing-link"
          to="/courses"
        >
          {{ courses.length ?? 'Все' }}
        </router-link>
      </div>
      <Slider
        v-if="courses.length"
        :link="courses.length > 10 ? '/courses' : null"
      >
        <CourseCard
          v-for="course of courses.slice(0, 10)"
          :key="course"
          :course="course"
        />
      </Slider>

      <Slider v-else>
        <CourseCardSkeleton v-for="course in 10" :key="course" />
      </Slider>
    </section>

    <!-- --------------    Categories Block    -------------- -->
    <section class="wrapper wrapper-landing">
      <div class="landing-header">
        <h2 class="landing-title">Топ категории</h2>
        <router-link
          v-if="categories.length > 10"
          class="landing-link"
          to="/categories"
        >
          {{ categories.length ?? 'Все' }}
        </router-link>
      </div>
      <Slider v-if="categories.length" :link="categories.length > 10 ? '/categories' : null">
        <CategoryCard
          v-for="category of categories.slice(0, 10)"
          :key="category"
          :category="category"
        />
      </Slider>

      <Slider v-else>
        <CategoryCard v-for="item in 10" :key="item" :category="null" />
      </Slider>
    </section>

    <AnimatedSection :saveRoute="saveRoute" :logged="logged()"/>

    <!-- --------------    Statistics - Ratings Block    -------------- -->
    <section class="wrapper wrapper-landing">
      <h2 class="landing-title">Статистика и отзывы пользователей</h2>
      <div class="ratings-block">
        <Statistics
          :statistics="statistics"
          studentsGraduated
          studentsCount
          coursesCount
        />
        <!-- <Ratings /> -->
      </div>
      <!-- <Reviews /> -->
    </section>

    <!-- <section class="wrapper wrapper-landing">
      <h2 class="landing-title">Новости</h2>
      <div class="news">
        <div class="news-card">
          <router-link class="preview" to="/business/news" :style="{ backgroundImage: 'url(' + '/img/tmp/example_news-photo.png' + ')'}">
            <div class="content">
              <h4 class="title">Samsung представляет Flip 3 — интерактивную доску для эффективного обучения</h4>
              <p class="subtitle">Преподаватели и учащиеся могут демонстрировать на дисплее тексты, изображения и видео, решать задачи. Ведь учебный материал усваивать проще и интереснее, если он подкреплен динамичной визуализацией на большом дисплее.</p>
            </div>
            <p class="author">Profit.kz</p>
          </router-link>
          <div class="desriptions"></div>
        </div>
      </div>
    </section> -->

    <Footer />
  </main>
</template>

<script setup>
import Header from '@/components/uiLanding/layouts/Header.vue'
import Footer from '@/components/uiLanding/layouts/Footer.vue'
import Slider from '@/components/common/Slider/Slider.vue'
import Statistics from '@/components/uiLanding/Ratings/Statistics.vue'

import Intro from '@/components/uiLanding/Home/Intro.vue'
import AboutUs from '@/components/uiLanding/Home/AboutUs.vue'
import CategoryCard from '@/components/uiLanding/Categories/CategoryCard.vue'
// import Reviews from '@/components/uiLanding/Reviews/Reviews.vue'
// import Ratings from '@/components/uiLanding/Ratings/Ratings.vue'
// import Statistics from '@/components/uiLanding/Ratings/Statistics.vue'
import CourseCard from '@/components/uiLanding/Courses/List/CourseCard.vue'
import AnimatedSection from '@/components/uiLanding/Home/AnimatedSection.vue'
import CourseCardSkeleton from '@/components/uiLanding/Courses/List/CourseCardSkeleton.vue'

import { useAuth } from '@/modules/auth'
import { onMounted, ref } from 'vue'
import { getCourseCategories, getCourses } from '@/actions/uiBusiness/courses'
import { getStatisticsTotal } from '@/actions/uiLanding/home'

const { logged } = useAuth()

const categories = ref([])
const courses = ref([])
const statistics = ref({
  graduated_students: null,
  students: null,
  courses: null
})

onMounted(async () => {
  const [categoriesList, coursesList, statisticsList] = await Promise.all([
    getCourseCategories(),
    getCourses(null, { status: 'published' }),
    getStatisticsTotal()
  ])

  categories.value = categoriesList.data
  courses.value = coursesList.data
  statistics.value = statisticsList
})

const saveRoute = route => {
  localStorage.setItem('savedRoute', route.path)
}
</script>

<style lang="scss">
main.home {
  & .course-card {
    min-width: 420px;
  }
  & footer {
    margin-top: 0 !important;
  }

  & .statistics-block {
    margin-top: 20px;
    display: flex;
    justify-content:space-around;
    & .item {
      text-align: center;
    }
    & .title {
      font-size: 70px;
    }

    & .subtitle {
      font-size: 22px;
    }
  }
}

.ratings-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news{
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
  grid-gap: 28px;

  &-card{
    max-width: 575px;
  }
  & .preview{
    height: 275px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    padding: 25px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 2;

    &::after{
      content: '';
      display: block;
      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: linear-gradient(180.17deg, rgba(0, 0, 0, 0) 38.5%, rgba(0, 0, 0, 0.5) 99.85%);
      background-size: 400% 400%;
      background-position: 0% 0%;
      transition: all .4s ease-in-out;
    }
    &:hover::after{
      background-position: 0% 100%;
    }
  }
  & .title{
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  & .subtitle {
    display: none;
    padding-top: 5px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
  }
  & .author {
    font-weight: 400;
    font-size: 16px;
    color: #DBDBDB;
  }
}
</style>
