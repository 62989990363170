<template>
  <div>
    <h2>Войдите и продолжайте обучение!</h2>
    <Form
      @finish="login"
    >
      <Input
        type="email"
        name="email"
        placeholder="Адрес электронной почты"
        required
        validation="email"
        autofocus
      />
      <Input
        type="password"
        name="password"
        placeholder="Пароль"
        required
      />
      <Button
        name="Войти"
        :loading="loading"
        htmlType="submit"
        size="large"
      />
    </Form>
    <div class="form-footer">
      <router-link to="/authorization/remember-password" class="remember-password">Забыли пароль?</router-link>
      <span class="login">
        Еще не с нами?<router-link to="/authorization/register">Зарегистрируйтесь!</router-link>
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue-demi'
import { useAuth } from '@/modules/auth'
import api from '@/modules/api'
import { useRouter } from 'vue-router'
import Form from '@/components/common/Form.vue'

const emit = defineEmits(['disposable'])
const router = useRouter()

const loading = ref(false)

const login = formData => {
  const { setUser } = useAuth()
  loading.value = true

  api.requestPost(
    '/api/sanctum/token',
    formData,
    response => {
      setUser({
        access_token: response.data.token
      })
      loading.value = false
      if (response.data.change_password === 1) {
        emit('disposable')
      } else {
        router.push(localStorage.getItem('savedRoute') ? localStorage.getItem('savedRoute') : '/')
        localStorage.removeItem('savedRoute')
      }
    },
    _ => {
      loading.value = false
    }
  )
}
</script>

<style scoped>
h2 {
  margin-bottom: 36px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #161931;
  display: block;
  text-align: center;
}
form {
  display: grid;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  min-width: 500px;
}

.form-footer {
  display: grid;
  justify-items: center;
  row-gap: 20px;
}
span.login {
  font-size: 18px;
  line-height: 21px;
  display: block;
  text-align: center;
}
span.login a {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  height: 22px;
}
span.login a:hover {
  border-bottom: 1px solid #000;
}

.remember-password {
  text-align: center;
  opacity: .7;
  cursor: pointer;
  width: fit-content;

}
.remember-password:hover {
  text-decoration: underline;
}
</style>
