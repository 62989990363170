<template>
  <div v-if="!course.id" class="card disabled">
    <div class="content">
      <div class="preview">
        <span class="name skeleton small-text"></span>
        <div class="img skeleton"></div>
      </div>
      <div class="progress">
        <div class="info">
          <span class="skeleton small-text"></span>
          <span class="skeleton small-text"></span>
        </div>
        <div class="progress-bar">
          <div class="percent skeleton" :style="{width: '80%'}"></div>
        </div>
      </div>
    </div>
    <div class="continue">
      <span class="skeleton middle-text"></span>
    </div>
  </div>

  <div v-else-if="course.status === 'published'" class="card">
    <router-link class="content course-content" :to="course.notFinishedLesson !== undefined ? `/lesson/${course.id}/unit/${course.notFinishedLesson.id}` : `/lesson/${course.id}`">
      <div class="preview">
        <span class="name">{{ course.name }}</span>
        <ImageLoader v-if="course.course_logo" class="logo" :src="course.course_logo"/>
        <DefaultCourseLogo v-else class="default-image" :categoryId="course.course_category_id" />
      </div>
      <div class="progress">
        <div class="info">
          <div class="info-item">
            <span class="user-progress">{{ course.lessons_finished }}</span>/{{ course.lessons.length }}
            <span class="title">уроков</span>
          </div>
          <div class="info-item">
            <span class="user-progress">{{ course.homework_count }}</span>
            <span class="title">заданий</span>
          </div>
        </div>
        <div class="right">
          <CourseContinue class="continue-list-type" :course="course" />
          <div class="progress-bar">
            <div class="percent" :style="{ width: (course.lessons_finished / course.lessons.length)* 100 + '%' }"/>
          </div>
        </div>
      </div>
    </router-link>
    <CourseContinue class="continue-grid-type" :course="course" />
  </div>
</template>

<script setup>
import CourseContinue from './CourseContinue.vue'
import { onMounted } from 'vue'

import { makePalette } from 'material-color-tool'
import FastAverageColor from 'fast-average-color'

import ImageLoader from '@/components/common/ImageLoader.vue'
import DefaultCourseLogo from '@/components/common/DefaultCourseLogo.vue'

defineProps({
  course: {
    type: [Object, Number],
    default: null
  }
})

const fac = new FastAverageColor()

const setBackgroundColor = () => {
  const cards = document.querySelectorAll('.course-content')
  for (const card of cards) {
    const img = card.querySelector('img')
    if (img) {
      fac.getColorAsync(img)
        .then(color => {
          card.style.backgroundColor = makePalette(color.hexa)[1]
        })
        .catch(e => {
          console.log(e)
        })
    } else card.style.backgroundColor = '#16193115'
  }
}
onMounted(() => setBackgroundColor())
</script>

<style scoped lang="scss">
.card{
  max-width: 400px;
  & .content{
    min-height: 120px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 12px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 0px #161931;
    transition: all .2s linear;

    &:hover {
      box-shadow: inset 0px 0px 0px 1px #161931;
    }
  }
  & .preview{
    display: grid;
    grid-template-columns: 1fr 68px;
    grid-gap: 40px;
  }

  & .name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  & .logo,
  & img {
    width: 68px;
    height: 68px;
    background: white;
    border-radius: 8px;
    object-fit: contain;
  }
  & .progress {
    grid-column-start: 1;
    grid-column-end: 3;

    & .info {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.02em;
      margin-bottom: 5px;
    }
    & .progress-bar {
      width: 100%;
      height: 6px;
      background: #F5F7F9;
      border-radius: 5px;
    }
    & .percent {
      background: #0082FA;
      border-radius: 5px;
      height: 100%;
    }
  }
  & .continue-list-type {
    display: none;
  }
}

.card.list {
  max-width: none !important;
  min-height: 70px;
  display: flex;
  align-items: center;
  max-width: 400px;
  padding: 0;
  border-radius: 10px;
  background: none !important;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);

  cursor: pointer;
  & .content{
    width: 100%;
    min-height: 46px;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 11px 30px 13px 15px;
    border-radius: 10px;
    margin-bottom: 0;
    box-shadow: none;

    &:hover {
      box-shadow: inset 0px 0px 0px 1px #161931;
    }
  }

  & .preview{
    width: 55%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 15px;
  }

  & .img,
  & img {
    width: 50px;
    height: 50px;
    border-radius: 3px;
  }

  & .progress {
    display: flex;
    align-items: center;
    width: 45%;
    grid-column-start: 1;
    grid-column-end: 3;

    & .info {
      width: 60%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 0;

      &-item {
        width: 50%;
        color: #B3B3B3;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 0;
      }

      & .user-progress {
        color: #161931;
        font-weight: 700;
        font-size: 28px;
        line-height: 1;
      }
      & .title {
        display: none;
      }
    }

    & .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;
      grid-gap: 14px;
    }

    & .progress-bar {
      height: 6px;
      background: #F5F7F9;
      border-radius: 5px;
    }
    & .percent {
      background: #0082FA;
      border-radius: 5px;
      height: 100%;
    }

  }
  & .continue-list-type {
    display: flex;
  }
  & .continue-grid-type {
    display: none;
  }
}
/* SKELETON */
.disabled .content {
  background-color: #fff !important;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
}
</style>
<style lang="scss">
.my-courses .card .content:hover {
  & .continue,
  & ~ .continue {
    & svg {
      fill: #0082FA !important;
    }
    & span {
      color: #0082FA !important;
    }
  }
}
</style>
