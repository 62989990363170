<template>
  <Button name="Создать ЗАЯВКУ" @click="showNewRequestModal" shadowed :loading="loading">
    <template v-slot:left >
      <SvgIcon name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" style="fill: white;" />
    </template>
  </Button>
</template>

<script setup>
import api from '@/modules/api'
import { ref } from 'vue'
import { useToast } from '@/modules/toast'
import router from '@/router'

const emit = defineEmits(['showModal', 'update:modelValue'])
const { toast } = useToast()

const loading = ref(false)

const showNewRequestModal = () => {
  loading.value = true
  api.requestGet(
    '/api/user-cards',
    {},
    response => {
      const cards = response.data.data
      loading.value = false
      if (cards.length !== 0) {
        emit('update:modelValue', cards[0].id)
        emit('showModal')
      } else {
        toast({
          message: 'Для создания заявки нужно прикрепить карту'
        })
        router.push('/business/payments/payouts')
      }
    }
  )
}
</script>
