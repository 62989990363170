<template>
  <Modal :hide="hide">
    <div class="modal-header">
      <span class="title">Новый тег</span>
    </div>
    <div class="modal-body">
      <input
        type="text"
        placeholder="Имя"
        autocomplete="off"
        v-model="tagName"
      />
    </div>
    <div class="modal-footer">
      <Button name="Отмена" type="cancel" @click="hide"/>
      <Button name="Создать тег" @click="createTag" />
    </div>
  </Modal>
</template>

<script setup>
import { ref } from 'vue-demi'
import Modal from '@/components/common/Modal.vue'
import api from '@/modules/api'
import { useToast } from '@/modules/toast.js'

const props = defineProps({
  hide: {
    type: Function,
    default: null
  },
  reloadTable: {
    type: Function,
    default: null
  }
})
const tagName = ref('')
const { toast } = useToast()

const createTag = () => {
  const name = tagName.value
  api.requestPost(
    '/api/tags',
    { name },
    response => {
      props.reloadTable()
      toast({
        message: 'Тег создан',
        type: 'success'
      })
      props.hide()
    }
  )
}
</script>

<style scoped>
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-header .title {
  font-size: 24px;
  font-weight: bold;
  color: #161931;
}
.modal-header .close-modal {
  cursor: pointer;
}
.modal-body {
  padding: 40px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modal-body input {
  font-size: 16px;
  padding: 15px 17px;
}
.modal-body .password-input {
  width: 100%;
  display: flex;
  gap: 20px;
}
.modal-body .password-input input {
  width: 100%;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
</style>
