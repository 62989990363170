<template>
  <div>
    Доступен новый контент
    <Button name="Загрузить" @click="reloadPage" :loading="loading"/>
  </div>
</template>

<script setup>
import { ref } from 'vue-demi'

const loading = ref(false)

const reloadPage = () => {
  loading.value = true
  self.skipWaiting()
  location.reload()
}
</script>

<style scoped>
div {
  position: fixed;
  bottom: 50px;
  left: 50px;
  background: #fff;
  color: var(--primary-color);
  padding: 15px;
  border: 1px solid var(--accent-color);
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
