<template>
  <div class="modal">
    <div class="container">
      <h2>Введите код из сообшения на телефоне</h2>
      <form method="POST" @submit.prevent="$emit('check')">
        <div class="input">
          <input
            type="tel"
            name="code"
            maxlength="1"
            @input="inputHandler(i)"
            v-for="i in 6" :key="i"
            :class="'input-code-' + i"
            autocomplete="off"
          >
        </div>
        <transition name="modal" mode="out-in">
          <span class="timer" v-if="formattedTimer">{{ formattedTimer }}</span>
          <span class="resend" v-else>Код не пришел?<span @click="$emit('resend')">Отправить снова</span></span>
        </transition>
        <Button
          htmlType="submit"
          v-wave
          size="large"
          name="Отправить"
        />
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue-demi'

const emit = defineEmits(['update:modelValue', 'resend', 'send', 'check'])

const verificationCode = ref('')
const timerLeft = ref(120)
const formattedTimer = ref('')

const inputHandler = inputId => {
  const previousInput = document.querySelector(`.input-code-${inputId - 1}`)
  const currentInput = document.querySelector(`.input-code-${inputId}`)
  const nextInput = document.querySelector(`.input-code-${inputId + 1}`)
  verificationCode.value = ''
  const inputs = document.querySelectorAll('input[name="code"]')
  for (const i of inputs) {
    verificationCode.value = verificationCode.value + i.value
  }
  const btn = document.querySelectorAll('button[type="submit"]')
  if (verificationCode.value.length === 6) {
    for (const el of btn) {
      el.classList.remove('disabled')
    }
  } else {
    document.querySelectorAll('button[type="submit"]')
    for (const el of btn) {
      el.classList.add('disabled')
    }
  }
  if (currentInput.value.length > 0 && inputId !== 6) {
    nextInput.focus()
  }
  const backSpace = event => {
    if (event.keyCode === 8) {
      currentInput.value = null
      if (inputId !== 1) {
        previousInput.focus()
      }
    }
    if (verificationCode.value.length === 6) {
      for (const el of btn) {
        el.classList.remove('disabled')
      }
    } else {
      document.querySelectorAll('button[type="submit"]')
      for (const el of btn) {
        el.classList.add('disabled')
      }
    }
  }
  currentInput.addEventListener('keydown', backSpace)
}

const timer = () => {
  const timeLeft = timerLeft.value
  const minutes = Math.floor(timeLeft / 60)
  let seconds = timeLeft % 60

  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  if (timeLeft > 0) {
    setTimeout(() => {
      timerLeft.value--
      timer()
    }, 1000)
    formattedTimer.value = `${minutes}:${seconds}`
  } else {
    formattedTimer.value = false
  }
}

onMounted(() => timer())

watch(
  () => verificationCode.value,
  () => {
    if (verificationCode.value.length === 6) {
      emit('update:modelValue', verificationCode.value)
    }
  }
)
</script>

<style scoped>
/* ============ Modal ============ */
.modal {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.container h2 {
  font-size: 34px;
  margin-bottom: 20px;
  text-align: center;
}
.container .input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.container .timer {
  grid-column-start: 1;
  text-align: center;
}
.container .resend {
  grid-column-start: 1;
  text-align: center;
}
.container .resend span {
  color: var(--primary-color);
  margin-left: 10px;
  cursor: pointer;
}
.container .resend span:hover {
  border-bottom: 1px solid var(--primary-color);
}
.container .input input[name="code"] {
  padding: 10px;
  font-size: 3em;
  width: 50px;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
</style>
