<template>
  <Modal
    :hide="hide"
    fullScreen
  >
    <transition mode="out-in">
      <div class="wrapper" v-if="!states.success">
        <h2 class="title">Время для обучения</h2>
        <span class="subtitle">
          Выберите подходящее Вам время обучения. Отметьте маркером подходящую ячейку.
        </span>
        <PreferedDays
          v-model:morning="preferedTimes.morning"
          v-model:day="preferedTimes.day"
          v-model:evening="preferedTimes.evening"
        />
        <Button
          name="Оставить заявку"
          :disabled="!isValid()"
          :loading="states.loading"
          @click="onSubmit(courseId)"
        >
          <SvgIcon name="arrow-right" :viewboxHeight="32" :viewboxWidth="32" :width="32" :height="32" fill="#fff"/>
        </Button>
      </div>
      <div class="success" v-else>
        <h2 class="title">Заявка на поступление успешно отправлена!</h2>
        <span class="subtitle">Ожидайте Вас распределят в подходящую группу. Мы вас уведомим перед началом курса.</span>
      </div>
    </transition>
  </Modal>
</template>

<script setup>
import { reactive } from 'vue-demi'
import Modal from '@/components/common/Modal.vue'
import PreferedDays from '../PreferedDays.vue'
import api from '@/modules/api'
import { useToast } from '@/modules/toast.js'

const props = defineProps({
  hide: {
    type: Function,
    default: null
  },
  courseId: {
    type: Number,
    required: true
  }
})
const { toast } = useToast()
const states = reactive({
  loading: false,
  success: false
})
const preferedTimes = reactive({
  morning: [],
  day: [],
  evening: []
})

const isValid = () =>
  Boolean(preferedTimes.morning.length) ||
  Boolean(preferedTimes.day.length) ||
  Boolean(preferedTimes.evening.length)

const onSubmit = courseId => {
  states.loading = true
  api.requestPost(
    `api/courses/${courseId}/enroll`,
    {
      role: 'student',
      preferred_weekdays: { ...preferedTimes }
    },
    response => {
      states.success = true
      setTimeout(() => props.hide(), 5000)
      toast({
        message: 'Заявка успешно подана, ожидайте ответа от школы',
        type: 'success'
      })
    },
    () => {
      states.loading = false
    }
  )
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  gap: 35px;
}
.title {
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.subtitle {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}

.success {
  background: var(--accent-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.success .title {
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  max-width: 630px;
}
.success .subtitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  max-width: 630px;
}

button {
  max-width: fit-content;
  align-self: center;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
