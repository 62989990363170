<template>
  <div>
    <div class="wrapper-system" v-if="data.lesson_data.name && data.lessons_data.length > 0">
      <h1>
        <span class="lesson-name">{{ data.lesson_data.name }}</span>
        <!-- <button class="disabled mark-as-completed" :key="markAsCompleteKey">
          <div class="background"></div>
          Пометить как завершенный
        </button> -->
      </h1>
      <div>
        <div class="lesson-content" v-html="data.lesson_data.content"></div>
        <div class="lesson-files" v-if="data.lesson_data.lesson_files.length > 0">
          <h3>Файлы урока</h3>
          <div class="files">
            <a :href="getFileUrl(file)" download="aclass_file" target="_blank" class="file" v-wave v-for="file of data.lesson_data.lesson_files" :key="file">{{file.name}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-else>
      <div class="background">
        <Loading name="sk" color="#161931" :width="75" :height="75"/>
      </div>
    </div>
    <div class="lesson-footer" v-if="data.lessons_data.length > 0 && data.lesson_data.name">
        <RouterLink :to="previousLesson(data.lesson_data)" v-if="data.lesson_data.id !== data.lessons_data[0].id">
          <Button name="Предыдущий урок" type="dark">
            <template v-slot:left >
              <SvgIcon name="chevron-left" :viewboxHeight="40" :viewboxWidth="24" :width="11" :height="18" fill="#fff" style="margin-right: 10px;"/>
            </template>
          </Button>
        </RouterLink>
        <span></span>
        <div class="next" v-if="data.lesson_data.homework.length > 0">
          <RouterLink :to="'/lesson/' + route.params.course_id + '/homework/' + data.lesson_data.homework[0].id">
            <Button name="Перейти к домашнему заданию">
              <SvgIcon name="chevron-right" :viewboxHeight="40" :viewboxWidth="24" :width="11" :height="18" fill="#fff" style="margin-left: 10px;"/>
            </Button>
          </RouterLink>
        </div>
        <RouterLink
          @click="fullAccess ? null : finishLesson(data.lesson_data.id)"
          :to="nextLesson(data.lesson_data.next_lesson_id)"
          v-else-if="data.lesson_data.next_lesson_id"
        >
          <Button name="Cледующий урок">
            <SvgIcon name="chevron-right" :viewboxHeight="40" :viewboxWidth="24" :width="11" :height="18" fill="#fff" style="margin-left: 10px;"/>
          </Button>
        </RouterLink>
        <FinishCourse v-else-if="!fullAccess" @finish="finishCourse" :finished="finishedCourse"/>
        <RouterLink v-else :to="`/business/courses/${route.params.course_id}/lessons`">
          <Button type="dark">
            ЗАВЕРШИТЬ КУРС
          </Button>
        </RouterLink>
      </div>
  </div>
</template>

<script setup>
import FinishCourse from './FinishCourse.vue'
import Loading from '@/components/common/Loading.vue'

import api from '@/modules/api'
import { useAuth } from '@/modules/auth'
import { useToast } from '@/modules/toast'
import { useCourseProgress } from '@/modules/learning.js'

import { onUnmounted, onUpdated, ref, watch } from 'vue-demi'
import { useRoute, useRouter } from 'vue-router'

import hljs from 'highlight.js'
import 'highlight.js/styles/github.css'

import getFileUrl from '@/helpers/getFileUrlByDate'
import checkFullAccess from '@/helpers/checkFullAccess.js'

import { finishLesson as asyncFinishLesson } from '@/actions/uiLearning/lesson'
import { getCertificate } from '@/actions/uiBusiness/certificate'

const { setFinishedLessons } = useCourseProgress()
const { user } = useAuth()
const { toast } = useToast()
const route = useRoute()
const router = useRouter()

const data = ref({
  lessons_data: [],
  lesson_data: {}
})

const finishedCourse = ref(null)
const markAsCompleteKey = ref(0)

const fullAccess = checkFullAccess(route.params.course_id, user.value.data)

const forceUpdate = () => {
  markAsCompleteKey.value++
}
const nextLesson = nextLessonId => {
  return `/lesson/${route.params.course_id}/unit/${nextLessonId}`
}

const finishLesson = async lessonId => {
  await asyncFinishLesson(lessonId)
  setFinishedLessons()
}

const finishCourse = async () => {
  await finishLesson(data.value.lesson_data.id)
  await getCertificate(route.params.course_id)
  toast({ message: 'Вы можете скачать сертификат в следующей вкладке!' })
  router.push('/learning')
}
const previousLesson = item => {
  if (data.value.lessons_data.length > 0) {
    for (const element of data.value.lessons_data) {
      if (item.id === element.id) {
        const index = data.value.lessons_data.indexOf(element)
        if (data.value.lessons_data[index - 1]) {
          return `/lesson/${route.params.course_id}/unit/${data.value.lessons_data[index - 1].id}`
        }
      }
    }
  }
}

api.requestGet(
  'api/lessons/' + route.params.lesson_id,
  {},
  response => {
    data.value.lesson_data = response.data.data
  }
)
api.requestGet(
  '/api/lessons',
  {
    course_id: route.params.course_id
  },
  response => {
    data.value.lessons_data = response.data.data.reverse()
    finishedCourse.value = data.value.lessons_data[data.value.lessons_data.length - 1].finished_at
  }
)

let timer
const timeToMarkAsComplete = () => {
  window.clearTimeout(timer)
  timer = window.setTimeout(() => {
    document.querySelector('.mark-as-completed').classList.remove('disabled')
  }, 30000)
}
timeToMarkAsComplete()

onUnmounted(() => {
  window.clearTimeout(timer)
})

watch(
  () => route.params.lesson_id,
  () => {
    if (route.params.lesson_id) {
      data.value.lesson_data = {}
      api.requestGet(
        'api/lessons/' + route.params.lesson_id,
        {},
        response => {
          data.value.lesson_data = response.data.data
          forceUpdate()
          timeToMarkAsComplete()
        }
      )
    }
  }
)

onUpdated(() => {
  document.querySelectorAll('code').forEach(el => {
    hljs.highlightElement(el)
  })
})
</script>

<style scoped>
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .lesson-name {
  font-weight: 600;
  font-size: 26px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
h1 .mark-as-completed.disabled {
  pointer-events: none;
  opacity: .5;
}

h1 .mark-as-completed {
  border: 2px solid var(--accent-color);
  border-radius: 3px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--accent-color);
  position: relative;
}
h1 .mark-as-completed .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-name: timer;
  background: #0081fa35;
}
@keyframes timer {
  from {
    width: 0;
}

  to {
    width: 100%;
  }
}

.lesson-content {
  max-width: 1000px;
  margin: 0 auto 20px auto;
}
.lesson-files {
  max-width: 1000px;
  margin: 0 auto 20px auto;
}
.lesson-files h3 {
  margin-bottom: 20px;
}
.lesson-files .files {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.lesson-files .files a {
  padding: 10px 20px 10px 40px;
  width: fit-content;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7858 17.0798V15.4727H19.6064C21.027 15.4727 22.1787 14.321 22.1787 12.9004C22.1787 11.7009 21.3502 10.6675 20.2001 10.3965L18.8398 10.0759L18.9684 8.68436C18.9827 8.52993 18.9899 8.37412 18.9899 8.21721C18.9899 5.45918 16.754 3.22335 13.996 3.22335C12.2842 3.22335 10.7224 4.08968 9.80568 5.49923L9.15234 6.50377L8.00312 6.16428C7.80824 6.10672 7.60449 6.07709 7.39502 6.07709C6.21306 6.07709 5.2549 7.03526 5.2549 8.21721C5.2549 8.23914 5.25523 8.261 5.25587 8.28279L5.29499 9.6003L4.01061 9.89652C2.74142 10.1892 1.82516 11.3267 1.82516 12.6485C1.82516 14.2083 3.08959 15.4727 4.64934 15.4727H9.21442V17.0798H4.64934C2.20199 17.0798 0.218018 15.0959 0.218018 12.6485C0.218018 10.545 1.68366 8.78385 3.64944 8.33048C3.64832 8.29286 3.64776 8.2551 3.64776 8.21721C3.64776 6.14766 5.32546 4.46995 7.39502 4.46995C7.76441 4.46995 8.12131 4.5234 8.45842 4.62298C9.63559 2.81305 11.676 1.61621 13.996 1.61621C17.6416 1.61621 20.597 4.57158 20.597 8.21721C20.597 8.42458 20.5875 8.62972 20.5687 8.83221C22.4131 9.26686 23.7858 10.9233 23.7858 12.9004C23.7858 15.2086 21.9146 17.0798 19.6064 17.0798H14.7858V17.0798Z' fill='black'/%3E%3Cpath d='M11.9761 22.6502C12.177 22.6502 12.3578 22.5799 12.5587 22.379L15.9538 19.1044C16.1044 18.9537 16.1848 18.793 16.1848 18.572C16.1848 18.1602 15.8734 17.8689 15.4516 17.8689C15.2607 17.8689 15.0498 17.9493 14.9092 18.11L13.3924 19.7272L12.7093 20.4404L12.7696 18.9437V11.2026C12.7696 10.7808 12.408 10.4292 11.9761 10.4292C11.5442 10.4292 11.1725 10.7808 11.1725 11.2026V18.9437L11.2429 20.4404L10.5598 19.7272L9.03301 18.11C8.8924 17.9493 8.68145 17.8689 8.48058 17.8689C8.0587 17.8689 7.75732 18.1602 7.75732 18.572C7.75732 18.793 7.84775 18.9537 7.99844 19.1044L11.3935 22.379C11.5944 22.5799 11.7652 22.6502 11.9761 22.6502Z' fill='black'/%3E%3C/svg%3E%0A");
  background-position: left 10px center;
  background-repeat: no-repeat;
  border: 1px solid #F2F1F6;
  border-radius: 8px;
}
.lesson-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #F2F1F6;
  padding: 20px 30px;
}
.lesson-footer .next {
  display: flex;
  gap: 20px;
}

.loading {
  height: calc(100vh - 70px);
  position: relative;
}
.loading .background {
  background: #16193104;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.lesson-content * {
  text-decoration: revert;
  list-style-type: revert;
  margin: revert;
  padding: revert;
  border: revert;
  list-style-type: revert;
}
.lesson-content iframe {
  border: none;
}
.lesson-content img {
  width: 100%;
}
.lesson-content pre code.hljs {
  background: #f3f4f6;
  border-radius: 10px;
}
.lesson-content .image-style-side {
  max-width: 50%;
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.lesson-content a {
  color: var(--accent-color);
  cursor: pointer;
  text-decoration: none;
}
.lesson-content a:hover {
  text-decoration: underline;
}
.lesson-content .image.image_resized {
  display: block;
  box-sizing: border-box;
}

.lesson-content .image.image_resized img {
  width: 100%;
}

.lesson-content .image.image_resized > figcaption {
  display: block;
}
</style>
