<template>
  <div>
    <h2>Зарегистрируйтесь и начните обучение</h2>
    <Form
      @finish="getCode"
      class="form-registration"
    >
      <Input
        type="text"
        name="name"
        placeholder="Имя"
        required
        v-model="formData.name"
      />
      <Input
        type="tel"
        name="phone"
        placeholder="Номер телефона"
        required
        v-model="formData.phone"
        validation="phone"
      />
      <Input
        type="email"
        name="email"
        placeholder="Адрес электронной почты"
        required
        v-model="formData.email"
        validation="email"
      />
      <Input
        type="password"
        name="password"
        placeholder="Пароль"
        required
        v-model="formData.password"
        validation="password"
        @change="formData.passwordConfirm = ''"
      />
      <Input
        type="password"
        name="passwordConfirm"
        placeholder="Повторитe пароль"
        required
        v-model="formData.passwordConfirm"
        validation="sameAs"
        :sameAs="formData.password"
      />
      <div class="agreement">
        <span>
          Отправляя нам ваши контактные данные, вы соглашаетесь с
          <a href="/docs/Оферта Codetau.pdf" download="">договором оферты</a>
            и <a href="/docs/Политика Конфиденциальности CodeTau.pdf" download="">политикой конфиденциалности</a>
        </span>
      </div>
      <Button
        htmlType="submit"
        :loading="loading"
        name="Зарегистрироваться"
        size="large"
      />
    </Form>
    <span class="login">
      У вас уже есть аккаунт?<router-link to="/authorization/login">Войти</router-link>
    </span>
  <Teleport to="body">
    <CheckCode
      v-if="checkCodeIsActive"
      v-model="verificationCode"
      @resend="getCode"
      @check="checkCode"
    />
  </Teleport>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue-demi'
import { useReCaptcha } from 'vue-recaptcha-v3'

import formatPhone from '@/helpers/formatPhone.js'
import api from '@/modules/api'
import { useAuth } from '@/modules/auth'
import CheckCode from './CheckCode.vue'
import { useRouter } from 'vue-router'
import Form from '@/components/common/Form.vue'

const emit = defineEmits(['check', 'success', 'error'])

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
const { setUser } = useAuth()
const router = useRouter()

const formData = reactive({
  name: '',
  email: '',
  phone: '',
  password: '',
  passwordConfirm: ''
})
const verificationCode = ref('')
const checkCodeIsActive = ref(false)

const loading = ref(false)

const recaptcha = async () => {
  await recaptchaLoaded()
  return await executeRecaptcha('login')
}

const getCode = async () => {
  loading.value = true
  api.requestPost(
    '/api/registration/get-code',
    {
      phone_number: formatPhone(formData.phone),
      recaptcha_token: await recaptcha()
    },
    response => {
      checkCodeIsActive.value = true
      loading.value = false
      emit('check')
    },
    _ => {
      loading.value = false
    }
  )
}
const checkCode = () => {
  api.requestPost(
    '/api/registration/check-code',
    {
      phone_number: formatPhone(formData.phone),
      code: verificationCode.value
    },
    response => {
      checkCodeIsActive.value = false
      register()
    }
  )
}

const register = () => {
  api.requestPost(
    '/api/registration/register',
    {
      name: formData.name,
      email: formData.email,
      phone: formatPhone(formData.phone),
      password: formData.password
    },
    response => {
      emit('success')
      router.push('/')
      setUser({
        access_token: response.data.token
      })
    },
    () => {
      emit('error')
    }
  )
}
</script>

<style scoped>
h2 {
  margin-bottom: 36px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #161931;
  display: block;
  text-align: center;
}

.form-registration {
  display: grid;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}
.agreement {
  display: flex;
  align-items: center;
  gap: 12px;
}
.agreement span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #161931;
  transition: all .2s ease;
}
.agreement span a {
  color: var(--accent-color);
}

span.login {
  font-size: 18px;
  line-height: 21px;
  display: block;
  text-align: center;
}
span.login a {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  height: 22px;
}
span.login a:hover {
  border-bottom: 1px solid #000;
}
</style>
