<template>
  <Modal
    :hide="hide"
    fullScreen
  >
    <div class="wrapper">
      <h2>{{ group.name }}</h2>
      <PreferedDays
        :morning="group.preferred_weekdays.morning"
        :day="group.preferred_weekdays.day"
        :evening="group.preferred_weekdays.evening"
        group
      />
      <Transition>
        <div v-if="state.loading" class="loading">
          <Loading name="sk" color="var(--primary-color)"/>
        </div>
        <div v-else-if="state.error">
          {{ state.error }}
        </div>
        <div v-else class="curators">
          <h3>Преподаватели</h3>
          <div
            class="curator"
            v-for="curator of state.curators"
            :key="curator.id"
          >
            <span>{{curator.name}}</span>
            <button
              class="attach-button"
              :class="{
                disabled: state.attaching,
                attached: group.curator.id === curator.id
              }"
              @click="attachCurator(curator.id)"
              v-wave
              :disabled="state.attaching || group.curator.id === curator.id"
            >
              <Loading v-if="state.attaching === curator.id" />
              {{
                group.curator.id === curator.id
                  ? 'Прикреплен'
                  : state.attaching === curator.id
                  ? ''
                  : 'Прикрепить'
              }}
            </button>
          </div>
        </div>
      </Transition>
    </div>
  </Modal>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import PreferedDays from '@/components/PreferedDays.vue'
import { onMounted, reactive } from 'vue-demi'
import { attachCuratorForGroup, getCourseTeachers } from '@/actions/uiBusiness/groups'
import Loading from '@/components/common/Loading.vue'

const props = defineProps({
  hide: Function,
  group: Object
})
const emit = defineEmits(['success'])

const state = reactive({
  curators: [],
  loading: true,
  error: null,
  attaching: false
})

onMounted(
  async () => {
    try {
      state.curators = await getCourseTeachers()
    } catch (error) {
      state.error = error
    } finally {
      state.loading = false
    }
  }
)

const attachCurator = async curatorId => {
  state.attaching = curatorId
  try {
    await attachCuratorForGroup(curatorId, props.group.id)
    emit('success')
    props.hide()
  } catch (error) {
    console.error(error)
  } finally {
    state.attaching = false
  }
}
</script>

<style scoped>
.wrapper {
  margin-top: 100px;
}
h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.loading {
  display: flex;
  justify-content: center;
  padding: 2em;
}
.curators {
  display: grid;
  row-gap: 15px;
  margin-top: 50px;
}
h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  margin-bottom: 20px;
}
.curator {
  display: flex;
  justify-content: space-between;
  background: #F1F3F4;
  border-radius: 3px;
  align-items: center;
  padding-left: 15px;
}
.attach-button {
  padding: 17px;
  background: var(--accent-color);
  border-radius: 0px 3px 3px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
  position: relative;
  min-width: 100px;
  min-height: 50px;
}
.attach-button.disabled {
  pointer-events: none;
}
.attach-button.attached {
  pointer-events: none;
  background: #B3F5D9;
  color: #00854D;
}
</style>
