<template>
  <transition mode="out-in">
    <table v-if="tableValues.length > 0">
      <tr class="headers">
        <th class="checkbox"></th>
        <th>Название</th>
        <th>Количество курса</th>
      </tr>
      <tr v-for="value in tableValues" :key="value" class="values">
        <td></td>
        <td>
          <transition mode="out-in">
            <Modal v-if="selectedCategoryIdToEdit === value.id"
            :hide="onCancel">
              <div class="modal">
                <input type="text" v-model="value.name">
                <div class="buttons">
                  <Button name="изменить" @click="onSubmit(value.name)"/>
                  <Button name="отмена" type="cancel" @click="onCancel"/>
                </div>
              </div>
            </Modal>
            <span v-else>{{value.name}}</span>
          </transition>
        </td>
        <th>{{courseCount(value.id)}}</th>
        <td class="dots">
          <button>
            <SvgIcon name="dots" class="options" />
            <div class="dropdown">
              <span class="edit" v-wave @click="showEditCategoryModal(value.id)">Редактировать</span>
              <span class="delete" v-wave @click="showDeleteCategoryModal(value.id)">Удалить</span>
            </div>
          </button>
        </td>
      </tr>
    </table>
    <table v-else class="disabled">
      <tr class="headers">
        <td></td>
        <th class="name"><span class="skeleton small-text"></span></th>
        <th><span class="skeleton middle-text"></span></th>
      </tr>
      <tr v-for="value in 20" :key="value" class="values">
        <td></td>
        <td><div class="skeleton middle-text"></div></td>
        <td><span class="skeleton small-text"></span></td>
        <td class="dots "></td>
      </tr>
    </table>
  </transition>
  <transition mode="out-in">
    <Modal
      v-if="deleteCategoryModalIsActive"
      :footer="true"
      :onOk="deleteCategory"
      :onCancel="hideDeleteCategoryModal"
      :hide="hideDeleteCategoryModal"
    >
      <h2 class="modal__text">Вы действительно хотите удалить категорию?</h2>
    </Modal>
  </transition>
</template>

<script setup>
import { ref, watch } from 'vue-demi'
import Modal from '@/components/common/Modal.vue'
import api from '@/modules/api'

const props = defineProps({
  tableValues: {
    type: Array,
    default: () => []
  },
  reloadTable: {
    type: Function,
    default: null
  }
})
const tableData = ref()
watch(() => props.tableValues, () => { tableData.value = JSON.parse(JSON.stringify(props.tableValues)) })
const deleteCategoryModalIsActive = ref(false)
const selectedCategoryIdToDelete = ref(null)
const selectedCategoryIdToEdit = ref(null)

const showDeleteCategoryModal = categoryId => {
  selectedCategoryIdToDelete.value = categoryId
  deleteCategoryModalIsActive.value = true
}
const hideDeleteCategoryModal = () => {
  selectedCategoryIdToDelete.value = null
  deleteCategoryModalIsActive.value = false
}

const categories = ref([])
api.requestGet('/api/course-categories', {},
  response => {
    categories.value = response.data.data.filter(item => item.courses.filter(item => item.status === 'published').length > 0)
  }
)

const courseCount = (id) => {
  let i = 0
  categories.value.forEach(category => {
    if (category.id === id) {
      i = category.courses.length
    }
  })
  return i
}

const deleteCategory = () => {
  api.requestDelete(
    `/api/course-categories/${selectedCategoryIdToDelete.value}`,
    {},
    response => {
      props.reloadTable()
    }
  )
  deleteCategoryModalIsActive.value = false
}

const showEditCategoryModal = tagId => {
  selectedCategoryIdToEdit.value = tagId
}

const onSubmit = name => {
  api.requestPatch(
    `/api/course-categories/${selectedCategoryIdToEdit.value}`,
    { name },
    response => {
      props.reloadTable()
    }
  )
  selectedCategoryIdToEdit.value = null
}

const onCancel = () => {
  tableData.value = JSON.parse(JSON.stringify(props.tableValues))
  selectedCategoryIdToEdit.value = null
}
</script>

<style scoped>
table {
  width: 100%;
  background: #FFFFFF;
  border-top: none;
  border-collapse: collapse;
}
hr {
  width: 100%;
}
table tr,
table td {
  padding: 10px;
  border-top: 1px solid rgba(22, 25, 49, 0.1);
  font-size: 16px;
  color: #161931;
}
table tr:first-child:hover {
  background: none;
  border-top: 1px solid rgba(22, 25, 49, 0.1);
}
table tr:hover {
  background: var(--hover-color);
}
table th {
  padding: 8px 10px 10px 10px;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  color: #161931;
}

.dots {
  cursor: pointer;
}
button {
  position: relative;
}
.dropdown {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  display: grid;
  gap: 0;
  left: -100px;
  top: 20px;
  background: #fff;
  width: max-content;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  transition: all .3s ease;
  transform: translateY(-10px);
}
.dropdown span {
  display: inline-block;
  padding: 15px 50px 15px 15px;
  text-align: left;
}
input{
  margin-top: 40px;
  width: 100%;
  padding: 10px;
}
.dropdown span.edit {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:color-burn'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.4127 4.61921L19.653 3.8606C19.2347 3.4423 18.5507 3.4423 18.1324 3.8606L17.3738 4.61921L19.653 6.89953L20.4127 6.1398C20.831 5.72263 20.831 5.03977 20.4127 4.61921Z' fill='black'/%3E%3C/g%3E%3Cg style='mix-blend-mode:color-burn'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.3558 6.17477L16.5961 5.41504L5.04971 16.9478C5.04971 16.9478 4.53305 17.8602 4.34086 18.5069C4.05596 19.4667 3.96777 20.2886 3.96777 20.2886C3.96777 20.2886 4.92422 20.2615 5.87502 19.9528C6.48099 19.7572 7.32891 19.2281 7.32891 19.2281L18.8764 7.6965L18.1155 6.93676L17.3558 6.17477Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A") right 15px center no-repeat;
}
.dropdown span.clone {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'%0Awidth='24' height='24'%0AviewBox='0 0 172 172'%0Astyle=' fill:%23000000;'%3E%3Cg fill='none' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cpath d='M0,172v-172h172v172z' fill='none'%3E%3C/path%3E%3Cg fill='%23000000'%3E%3Cpath d='M55.54167,21.5c-18.80175,0 -34.04167,15.23992 -34.04167,34.04167v62.20443c0,4.79092 2.39456,9.26695 6.38281,11.92578l4.36719,2.91146v-77.04167c0,-12.86417 10.4275,-23.29167 23.29167,-23.29167h77.04167l-2.91146,-4.36719c-2.65525,-3.98825 -7.13128,-6.38281 -11.92578,-6.38281zM59.125,39.41667c-10.82086,0 -19.70833,8.88748 -19.70833,19.70833v71.66667c0,10.82086 8.88748,19.70833 19.70833,19.70833h71.66667c10.82086,0 19.70833,-8.88748 19.70833,-19.70833v-71.66667c0,-10.82086 -8.88748,-19.70833 -19.70833,-19.70833zM59.125,50.16667h71.66667c5.01031,0 8.95833,3.94802 8.95833,8.95833v71.66667c0,5.01031 -3.94802,8.95833 -8.95833,8.95833h-71.66667c-5.01031,0 -8.95833,-3.94802 -8.95833,-8.95833v-71.66667c0,-5.01031 3.94802,-8.95833 8.95833,-8.95833z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") right 15px center no-repeat;
}
.dropdown span.delete {
  color: #FF5656;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.71429 2.57171V3.42885H3.85714C3.38376 3.42885 3 3.81261 3 4.28599C3 4.75938 3.38376 5.14314 3.85714 5.14314H4.82609L5.89547 21.5404C5.95427 22.442 6.70266 23.1431 7.60613 23.1431H16.3939C17.2973 23.1431 18.0457 22.442 18.1045 21.5404L19.1739 5.14314H20.1429C20.6162 5.14314 21 4.75938 21 4.28599C21 3.81261 20.6162 3.42885 20.1429 3.42885H16.2857V2.57171C16.2857 1.62493 15.5182 0.857422 14.5714 0.857422H9.42857C8.4818 0.857422 7.71429 1.62493 7.71429 2.57171ZM6.54406 5.14322H17.456L16.3939 21.4289H7.60617L6.54406 5.14322ZM9.42863 3.42877H14.5715V2.57163H9.42863V3.42877ZM12.8572 7.28575C12.8572 6.81236 12.4734 6.42861 12 6.42861C11.5266 6.42861 11.1429 6.81236 11.1429 7.28575V19.7143C11.1429 20.1877 11.5266 20.5715 12 20.5715C12.4734 20.5715 12.8572 20.1877 12.8572 19.7143V7.28575ZM8.57146 6.42861H8.62183C9.07508 6.42861 9.44999 6.78148 9.47741 7.2339L10.2339 19.7159C10.2608 20.1606 9.92215 20.543 9.47741 20.57C9.46116 20.571 9.44488 20.5715 9.4286 20.5715H9.37823C8.92498 20.5715 8.55007 20.2186 8.52265 19.7662L7.76617 7.28418C7.73921 6.83943 8.0779 6.45704 8.52265 6.43008C8.5389 6.4291 8.55518 6.42861 8.57146 6.42861ZM15.4286 6.42861H15.3783C14.925 6.42861 14.5501 6.78148 14.5227 7.2339L13.7662 19.7159C13.7652 19.7321 13.7647 19.7484 13.7647 19.7647C13.7647 20.2103 14.1259 20.5715 14.5715 20.5715H14.6219C15.0751 20.5715 15.45 20.2186 15.4774 19.7662L16.2339 7.28418C16.2349 7.26793 16.2354 7.25165 16.2354 7.23537C16.2354 6.78981 15.8742 6.42861 15.4286 6.42861Z' fill='%23FF5656'/%3E%3C/svg%3E%0A") right 15px center no-repeat;
}
.dropdown span:hover {
  background-color: var(--hover-color);
}
button:focus .dropdown {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}
.modal__text{
  padding-top: 30px;
}
.modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.buttons {
  display: flex;
  gap: 20px;
}
.headers.skeleton,
.values.skeleton {
  pointer-events: none;
}
.values.skeleton .avatar {
  opacity: 0;
}
.skeleton.skeleton-img {
  width: 34px;
  height: 34px;
}
.headers.skeleton .avatar{
  color: rgba(0, 0, 0, 0);
}
.disabled {
  pointer-events: none;
}
.v-enter-active,
.v-leave-active {
  transition: all .2s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

h2 {
  text-align: center;
}
</style>
