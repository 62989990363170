<template>
  <div
    v-for="course of courses"
    :key="course"
  >
    <Modal
      :hide="() => { $emit('hide') }"
      title="Подробные данные курсов пользователя"
      v-if="active.courseId === course.id"
    >
      <div class="form">
        <Input type="disabled" placeholder="Имя пользователя" :modelValue="user.name"/>

        <div class="group">
          <span class="label">Название курса</span>
          <Tag :text="course.name"/>
        </div>

        <Input type="disabled" placeholder="Роль" :modelValue="getRole(course.role)"/>
      </div>

      <button
        class="next"
        data-tooltip="Показать следующую заявку этого пользователя"
        v-if="nextCourseExists"
        @click="nextcourse"
      >
        <SvgIcon
          name="chevron-right"
          :viewboxHeight="40"
          :height="30"
          fill="#fff"
        />
      </button>
      <button
        class="previous"
        data-tooltip="Показать предыдущую заявку этого пользователя"
        v-if="previouscourseExists"
        @click="previouscourse"
      >
        <SvgIcon
          name="chevron-left"
          :viewboxHeight="40"
          :height="30"
          fill="#fff"
        />
      </button>
    </Modal>
  </div>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import Tag from '@/components/common/Tag.vue'

import { reactive, ref, computed } from 'vue-demi'

const props = defineProps({
  user: {
    type: Object
  }
})
defineEmits(['hide'])

const courses = ref(props.user.courses)
const active = reactive({
  courseId: courses.value[0]?.id,
  courseIndex: 0,
  denyConfirm: false,
  loading: false,
  undocourseId: null
})

const getRole = role => {
  switch (role) {
    case 'student':
      return 'Студент'
    case 'teacher':
      return 'Преподаватель'
    default:
      return role
  }
}

const nextCourseExists = computed(() => {
  if (courses.value[active.courseIndex + 1]) return true
  return false
})
const previouscourseExists = computed(() => {
  if (courses.value[active.courseIndex - 1]) return true
  return false
})

const nextcourse = () => {
  if (courses.value[active.courseIndex + 1]) active.courseIndex++
  active.courseId = courses.value[active.courseIndex].id
}
const previouscourse = () => {
  if (courses.value[active.courseIndex - 1]) active.courseIndex--
  active.courseId = courses.value[active.courseIndex].id
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.group {
  display: flex;
  flex-direction: column;
  gap: 11px;
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}
.prefered-days {
  gap: 7px;
}
.actions {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 20px;
}
.next {
  position: absolute;
  right: -50px;
  top: 50%;
}
.previous {
  position: absolute;
  top: 50%;
  left: -50px;
}
.next-enter-active,
.next-leave-active {
  transition: all .5s ease;
}
.next-enter-from,
.next-leave-to {
  opacity: 0;
  transform: translateX(10em) scale(99%);
}
</style>
