const filterSchool = (schools) => {
  const schoolIds = []

  return schools.filter(element => {
    const isDuplicate = schoolIds.includes(element.id)
    if (!isDuplicate) {
      schoolIds.push(element.id)
      return true
    }
    return false
  })
}

export default filterSchool
