<template>
  <div class="main">
    <button class="back" @click="back">
      <SvgIcon
        name="back"
        :viewboxHeight="18"
        :viewboxWidth="18"
        :height="18"
        :width="18"
      />
      <span>Пользователи</span>
    </button>
    <div class="username">{{ data.name }}
    <button class="edit" @click="edit" v-wave v-if="!editActive">
      <SvgIcon
        name="pencil"
        :viewboxHeight="18"
        :viewboxWidth="18"
        :height="18"
        :width="18"
      />
      <span>Редактировать</span>
    </button>
    </div>
    <div class="user-info">
      <div class="left">
        <div class="profile">
          <img
            v-if="data.user_photo"
            :src="data.user_photo"
            alt=""
            class="avatar"
          />
          <SvgIcon
            v-else
            name="avatar"
            :viewboxWidth="34"
            :viewboxHeight="34"
            :width="115"
            :height="115"
            class="avatar"
          />
          <div class="profile-info">
            <input v-if="editActive" type="text" v-model="data.name" />
            <b v-else>{{ data.name }}</b>
            <span v-if="data.role === 'user'">Пользователь</span>
            <span v-if="data.role === 'student'">Ученик</span>
            <span v-if="data.role === 'teacher'">Учитель</span>
            <input v-if="editActive" type="email" v-model="data.email" />
            <a v-else :href="`mailto:`">{{ data.email }}</a>
          </div>
        </div>
        <div class="info">
          <span class="info-tilte">Инфо</span>
          <div class="group">
            <span class="name">Добавлено</span>
            <span v-if="data.email_verified_at" class="value">{{
              moment(data.email_verified_at).format('DD.MM.YYYY г., hh:mm:ss')
            }}</span>
            <span v-else class="value">Почта не верифицирована</span>
          </div>
          <div class="group">
            <span class="name">Дата рождения</span>
            <input v-if="editActive" type="date" v-model="data.birthday" />
            <span v-else class="value">{{
              moment(data.birthday).format('DD.MM.YYYY')
            }}</span>
          </div>
          <div class="group">
            <span class="name">Пол</span>
            <select v-if="editActive" name="sex" v-model="data.gender">
              <option value="null">Не выбран</option>
              <option value="male">Мужской</option>
              <option value="female">Женский</option>
            </select>
            <span v-else-if="data.gender == 'female'" class="value">Женский</span>
            <span v-else-if="data.gender == 'male'" class="value">Мужской</span>
            <span v-else class="value">Не выбран</span>
          </div>
          <div class="group">
            <span class="name">Телефон</span>
            <input v-if="editActive" type="text" v-model="data.phone" />
            <span v-else class="value">{{ data.phone }}</span>
          </div>
          <!-- <div class="group">
            <span class="name">Адрес</span>
            <input v-if="editActive" type="text" v-model="data.address">
            <span v-else class="value">КАЗАХСТАН, АЛМАТЫ, АУЭЗОВСКИЙ, 9 микрорайон 20 дом, кв.92</span>
          </div>
          <div class="group">
            <span class="name">Город</span>
            <input v-if="editActive" type="text" v-model="data.city">
            <span v-else class="value">Алматы</span>
          </div> -->
        </div>
        <div class="confirm-buttons" v-if="editActive">
          <Button name="Отмена" type="cancel" @click="cancel" />
          <Button name="Сохранить" @click="saveEdittedData"/>
        </div>
      </div>
      <div class="right">
        <span class="title">Пользователь изучает эти курсы</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import api from '@/modules/api'
import { useAuth } from '@/modules/auth'

const { selectedSchool } = useAuth()
const router = useRouter()
const route = useRoute()
const editActive = ref(false)
const data = ref({
  name: 'Загрузка...'
})
const save = {}

// ====================  REQUEST DATA  ====================

api.requestGet(
  `/api/schools/${selectedSchool.value.id}/course-users`,
  {
    user_id: route.params.id
  },
  response => {
    data.value = response.data.data[0]
    Object.assign(save, response.data.data[0])
  }
)
const saveEdittedData = () => {
  api.requestPut(`/api/users/${route.params.id}`, {
    name: data.value.name,
    email: data.value.email,
    phone: data.value.phone,
    gender: data.value.gender,
    birthday: data.value.birthday,
    role: data.value.role
  },
  response => {
    api.requestGet(`/api/users/${route.params.id}`, {}, response => {
      data.value = response.data.data
      Object.assign(save, response.data.data)
      editActive.value = false
    })
  })
}

const edit = () => {
  editActive.value = true
}

const cancel = () => {
  Object.assign(data.value, save)
  editActive.value = false
}

const back = () => {
  router.go(-1)
}
</script>

<style scoped>
.main {
  padding: 20px 30px;
}
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all 0.3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
.username {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  margin-bottom: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.edit {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  padding: 5px;
  border-radius: 3px;
}
.user-info {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 20px;
  width: calc(100% - 20px);
  margin-bottom: 20px;
}
.user-info .left {
  padding: 25px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
}
.user-info .left .profile {
  display: flex;
  gap: 20px;
  margin-bottom: 45px;
}
.user-info .left .profile img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
.user-info .left .profile .profile-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}
.user-info .left .profile .profile-info a {
  font-size: 16px;
  text-transform: lowercase;
  color: #0082fa;
}
.user-info .left .info {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 50px;
}
.user-info .left .info .group {
  display: flex;
  flex-direction: column;
}
.user-info .left .info .group .name {
  font-size: 16px;
  font-weight: 600;
  color: #161931;
}
.user-info .left .info .info-tilte {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
}
.user-info .left input,
.user-info .left select {
  color: #161931;
  padding: 16px;
  font-size: 14px;
}
.user-info .left .profile-info input[type='text'] {
  text-transform: capitalize;
}
.confirm-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.user-info .right {
  padding: 25px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
}
</style>
