<template>
  <div class="main">
    <button class="back" @click="() => {courseData.id ? $router.push(`/business/courses/${courseData.id}/lessons`) : $router.go(-1)}">
      <SvgIcon
        name="back"
        :viewboxHeight="18"
        :viewboxWidth="18"
        :height="18"
        :width="18"
      />
      <span>{{courseData.name}}</span>
    </button>
    <section class="certificate-main">
    <h1>Информация отображаемая на сертификате курса</h1>
      <div class="inner">
        <div class="block">
          <h4 class="title">Настройки</h4>
          <div class="sidebar">
            <div class="item">
              <Switch v-model="getCertificate" :loading="loading" />
              <button @click="() => {getCertificate = !getCertificate}">Выдача сертификата</button>
            </div>
            <div class="item">
              <Switch v-model="settings.student_name" :loading="loading" />
              <button @click="() => {settings.student_name = !settings.student_name}">ФИО студента</button>
            </div>
            <div class="item">
              <Switch v-model="settings.teacher_name" :loading="loading" />
              <button @click="() => {settings.teacher_name = !settings.teacher_name}">ФИО преподавателя</button>
            </div>
            <div class="item">
              <Switch v-model="settings.school_name" :loading="loading" />
              <button @click="() => {settings.school_name = !settings.school_name}">Название школы</button>
            </div>
            <div class="item">
              <Switch v-model="settings.course_name" :loading="loading" />
              <button @click="() => {settings.course_name = !settings.course_name}">Название курса</button>
            </div>
            <!-- <div class="item">
              <Switch v-model="settings.course_name" />
              <button>Период прохождения курса</button>
            </div> -->
            <div class="item">
              <Switch v-model="settings.grade" :loading="loading" />
              <button @click="() => {settings.grade = !settings.grade}">Набранная оценка</button>
            </div>
          </div>
        </div>
        <div class="block">
          <h4 class="title">Миниатюра публикации</h4>
          <div class="template">
            <div class="certificate" :class="{no: !getCertificate}">
              <CertificatePDF :dataSettings="getCertificate ? settings : defaultSettings" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import Switch from '@/components/common/Switch.vue'
import CertificatePDF from '@/components/uiBusiness/CertificatePDF.vue'

import api from '@/modules/api'
import { reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { useToast } from '@/modules/toast'
import { putCertificate } from '@/actions/uiBusiness/certificate'

const route = useRoute()
const { toast } = useToast()

const settings = reactive({
  student_name: true,
  teacher_name: true,
  school_name: true,
  course_name: true,
  grade: true
})
const courseData = ref({
  name: 'Курс',
  id: null
})

const getCertificate = ref(true)
const defaultSettings = Object.assign({}, settings)
let shopSettings = Object.assign({}, settings)
const loading = ref(true)

// -------  Edit Certificate --------
let timer
watch(
  () => settings,
  () => {
    for (const [key] of Object.entries(settings)) {
      if (settings[key]) getCertificate.value = true
    }
    if (getCertificate.value) shopSettings = Object.assign({}, settings)
    if (!loading.value) {
      window.clearTimeout(timer)
      timer = window.setTimeout(editCertificate, 900)
    }
  },
  { deep: true }
)

const editCertificate = async () => {
  await putCertificate(route.params.course_id, settings)
  toast({ message: 'Сертификат успешно изменен' })
}

// -------  Get Certificate --------
api.requestGet(`/api/courses/${route.params.course_id}`, {}, (response) => {
  var certificate_fields = response.data.data.certificate_fields
  courseData.value = response.data.data
  if (certificate_fields && certificate_fields.length !== 0) {
    for (const [key] of Object.entries(settings)) {
      if (!certificate_fields || certificate_fields.includes(key)) settings[key] = true
      else settings[key] = false
    }
    shopSettings = Object.assign({}, settings)
    getCertificate.value = true
  } else getCertificate.value = false

  setTimeout(() => { loading.value = false }, 100)
})

// -------  Toggle Settings Certificate --------
watch(
  () => getCertificate.value,
  () => toggleSettings()
)
const toggleSettings = () => {
  for (const [key] of Object.entries(settings)) {
    if (!getCertificate.value) settings[key] = false
    else settings[key] = shopSettings[key]
  }
}
</script>

<style scoped>
.main {
  padding: 20px 30px;
}
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all 0.3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
h1 {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 30px;
  margin-bottom: 25px;
}
.certificate-main .inner{
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-gap: 20px;
}
.certificate-main .block{
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-shadow: 0px 0px 10px rgb(22 25 49 / 10%);
}
.certificate-main h4.title{
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 20px;
}
.certificate-main .sidebar{
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.certificate-main .sidebar .item{
  display: flex;
  align-items: center;
  grid-gap: 20px;
}
.certificate-main .sidebar .item button{
  font-size: 18px;
  color: #161931;
}
.certificate-main .template{
  padding: 0 15px 0 10px;
}
.certificate-main .template .certificate{
  opacity: 1;
  transition: all .2s ease;
}
.certificate-main .template .certificate.no{
  opacity: .4;
}
</style>
