import { ref } from 'vue'
import api from '@/modules/api'

const finishedLessons = ref(0)

export const useCourseProgress = () => {
  const getFinishedLessons = () => finishedLessons.value

  const setFinishedLessons = courseId => {
    api.requestGet(
      '/api/lessons',
      {
        course_id: courseId,
        order_by: 'order',
        descending: 0,
        status: 'published'
      },
      response => {
        finishedLessons.value = 0
        response.data.data.forEach(lesson => {
          if (lesson.finished_at) {
            finishedLessons.value++
          }
        })
      }
    )
  }

  return {
    getFinishedLessons,
    setFinishedLessons
  }
}
const nextLesson = ref(null)

export const useNextLesson = () => {
  const getNextLesson = () => nextLesson.value

  const setNextLesson = (currentHomeworkId, allLessons) => {
    for (let i = 0; i < allLessons.length; i++) {
      if (allLessons[i].homework[0]?.id === currentHomeworkId) {
        nextLesson.value = allLessons[i + 1]?.id ?? false
        return
      }
    }
  }

  return {
    getNextLesson,
    setNextLesson
  }
}
