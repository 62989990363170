<template>
  <div class="wrapper-system">
    <button class="back" @click="back">
      <SvgIcon
        name="back"
        :viewboxHeight="18"
        :viewboxWidth="18"
        :height="18"
        :width="18"
      />
      <span>Уроки</span>
    </button>
    <h1>
      <span class="title">{{ !values.name.length && route.params.homework_id === 'new' ? 'Создать домашнее задание' : values.name }}</span>
      <div class="buttons">
        <Button
          v-if="route.params.homework_id !== 'new'"
          name="Удалить домашнее задание"
          @click="showDeleteModal"
          type="cancel"
          shadowed
        />
        <Button
          name="сохранить"
          @click="sendLessonData"
          :loading="loading.create"
          shadowed
        />
      </div>
    </h1>
    <div class="edit">
      <div class="left">
        <span class="title">Задание</span>
        <div class="group">
          <span class="name">Название</span>
          <input
            type="text"
            placeholder="Введите название домашнего задания"
            v-model="values.name"
          />
        </div>
        <div class="group">
          <span class="name">Описание домашнего задания</span>
          <CKEditor v-model="values.content" />
        </div>
        <div v-for="task in values.tasks" :key="task">
          <Transition>
            <div class="questions-and-tests" v-collapse>
              <span
                class="title"
                v-if="
                  task.type === '' ||
                  task.type === 'file' ||
                  task.type === 'textarea'
                "
              >
                {{ getIndex(task, values.tasks) }}. Задача
                <button class="delete-task" @click="deleteTask(task)" v-wave>
                  <SvgIcon
                    name="x"
                    fill="#FF5656"
                    :viewboxHeight="14"
                    :viewboxWidth="14"
                    :height="14"
                    :width="14"
                  />
                </button>
              </span>
              <CKEditor
                v-model="task.content"
                v-if="
                  task.type === '' ||
                  task.type === 'file' ||
                  task.type === 'textarea'
                "
              />
              <span class="title" v-if="task.type === 'test'">
                {{ getIndex(task, values.tasks) }}. Тест
                <button class="delete-task" @click="deleteTask(task)" v-wave>
                  <SvgIcon
                    name="x"
                    fill="#FF5656"
                    :viewboxHeight="14"
                    :viewboxWidth="14"
                    :height="14"
                    :width="14"
                  />
                </button>
              </span>
              <div class="tests" v-if="task.type === 'test'">
                <CKEditor v-model="task.content" />
                <div v-for="value in task.answers" :key="value">
                  <Transition>
                    <div class="answers" v-collapse>
                      <input
                        type="checkbox"
                        class="custom-checkbox"
                        :id="
                          'checkbox--' +
                          getIndex(value, task.answers) +
                          '--' +
                          getIndex(task, values.tasks)
                        "
                        v-model="value.is_correct"
                      />
                      <label
                        :for="
                          'checkbox--' +
                          getIndex(value, task.answers) +
                          '--' +
                          getIndex(task, values.tasks)
                        "
                      ></label>
                      <span>{{
                        toLetters(getIndex(value, task.answers))
                      }}</span>
                      <input
                        type="text"
                        placeholder="Введите ответ"
                        v-model="value.answer"
                        @keyup.enter="addAnswerOnEnter(task, value)"
                        :class="`answer-input--${getIndex(
                          value,
                          task.answers
                        )}-${getIndex(task, values.tasks)}`"
                      />
                      <button
                        @click="deleteAnswer(value, task.answers)"
                        class="delete"
                      >
                        <SvgIcon
                          name="minus"
                          fill="#fff"
                          :viewboxHeight="4"
                          :viewboxWidth="32"
                          :width="18"
                          :height="18"
                        />
                      </button>
                    </div>
                  </Transition>
                </div>
                <button class="add-test" @click="addAnswer(task)">
                  <SvgIcon name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" style="fill: #0082FA;" />
                  <span>Добавить новый вариант ответа</span>
                </button>
              </div>
              <span class="tip">{{ tips[task.type] }}</span>
            </div>
          </Transition>
        </div>
        <Dropdown btnText="Добавить задачу">
          <DropdownItem @click="addTest"> Тест вопрос </DropdownItem>
          <DropdownItem @click="addTask"> Текст вопрос </DropdownItem>
          <DropdownItem @click="addFileTask"> Файл вопрос</DropdownItem>
        </Dropdown>
        <div class="buttons">
          <Button type="cancel" name="отмена" @click="$router.go(-1)" />
          <Button
            name="сохранить"
            @click="sendLessonData"
            :loading="loading.create"
            shadowed
          />
        </div>
      </div>
      <div class="right" v-if="!courseData.isOnline">
        <div class="deadline">
          <div class="title">Срок исполнения</div>
          <input
            type="datetime-local"
            name="deadline"
            v-model="values.deadline_at"
          />
        </div>
      </div>
    </div>
    <transition name="modal">
      <Modal v-if="deleteModalIsActive" :hide="hideDeleteModal">
        <div class="modal">
          <span class="title"
            >Вы действительно хотите удалить домашнее задание?</span
          >
          <div class="buttons">
            <Button name="нет" type="cancel" @click="hideDeleteModal" />
            <Button
              name="да"
              @click="asyncDeleteHomework"
              :loading="loading.delete"
            />
          </div>
        </div>
      </Modal>
    </transition>
  </div>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import CKEditor from '@/components/common/CKEditor.vue'

import moment from 'moment'
import { reactive, ref } from 'vue-demi'
import { useRoute, useRouter } from 'vue-router'

import api from '@/modules/api'
import { useToast } from '@/modules/toast'

import {
  getHomework,
  postHomework,
  putHomework,
  deleteHomework
} from '@/actions/uiBusiness/homeworks'
import Dropdown from '@/components/common/Dropdown/index.vue'
import DropdownItem from '@/components/common/Dropdown/DropdownItem.vue'

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const deleteModalIsActive = ref(false)

const values = reactive({
  name: '',
  content: '',
  lesson_id: route.params.lesson_id,
  tasks: []
})
const loading = reactive({
  create: false,
  delete: false
})
const courseData = reactive({
  isOnline: true
})

const tips = {
  file: 'Ответ будет в виде файла',
  textarea: 'Ответ будет в виде текста',
  test: 'Ответ будет в виде теста'
}

const isNewHomeworkPage = () => {
  if (route.params.homework_id === 'new') return true
  else return false
}

const addTest = () => {
  values.tasks.push({
    content: '',
    question: 'question',
    type: 'test',
    answers: [
      {
        answer: '',
        is_correct: false
      }
    ]
  })
}

const addTask = () => {
  values.tasks.push({
    content: '',
    question: 'question',
    type: 'textarea'
  })
}
const addFileTask = () => {
  values.tasks.push({
    content: '',
    question: 'question',
    type: 'file'
  })
}

const addAnswer = (item, value) => {
  item.answers.push({
    answer: '',
    is_correct: false
  })
}
const addAnswerOnEnter = (item, value) => {
  if (item.answers[item.answers.length - 2] !== value) {
    item.answers.push({
      answer: '',
      is_correct: false
    })
    setTimeout(() => {
      const nextInput = document.querySelector(
        `.answer-input--${getIndex(value, item.answers) + 1}-${getIndex(
          item,
          values.tasks
        )}`
      )
      nextInput.focus()
    })
  }
}

const deleteTask = (item) => {
  const index = values.tasks.indexOf(item)
  if (index > -1) {
    values.tasks.splice(index, 1)
  }
}

const deleteAnswer = (item, arr) => {
  const index = arr.indexOf(item)
  if (index > -1) {
    arr.splice(index, 1)
  }
}

const getIndex = (item, arr) => {
  return arr.indexOf(item) + 1
}

const toLetters = (num) => {
  const mod = num % 26
  let pow = (num / 26) | 0
  const out = mod ? String.fromCharCode(64 + mod) : --pow
  return pow ? toLetters(pow) + out : out
}

const back = () => {
  router.go(-1)
}

const showDeleteModal = () => {
  deleteModalIsActive.value = true
}
const hideDeleteModal = () => {
  deleteModalIsActive.value = false
}

// --------- API Requests -----------
api.requestGet('/api/courses/' + route.params.course_id, {}, (response) => {
  courseData.isOnline = response.data.data.online
})

const fetchData = async () => {
  if (!isNewHomeworkPage()) {
    const response = (await getHomework(route.params.homework_id)).data.data
    Object.assign(values, response)
    values.tasks.forEach((element) => {
      if (element.type === 'test' && element.answers.length === 0) {
        element.answers.push({
          answer: '',
          is_correct: false
        })
      }
      element.answers.forEach((answer) => {
        answer.is_correct = !!answer.is_correct
      })
    })
    if (values.deadline_at) {
      values.deadline_at = moment(values.deadline_at).format('yyyy-MM-DDThh:mm')
    }
  }
}
fetchData()

const isTestNotChecked = () => {
  for (const task of values.tasks) {
    if (task.type !== 'test') continue

    const correctAnswersLength = task.answers
      .map((answer) => answer.is_correct)
      .filter((isCorrect) => isCorrect).length
    if (!correctAnswersLength) return true
  }
  return false
}

let notAttempted = true
const sendLessonData = async () => {
  loading.create = true
  if (isTestNotChecked() && notAttempted) {
    toast({
      message:
        'Вы можете выбрать правильный вариант ответа, чтобы система автоматический проверила ответ студента и высчитала оценку'
    })
    notAttempted = false
    loading.create = false
    return
  }
  try {
    if (isNewHomeworkPage()) await postHomework(values)
    else await putHomework(route.params.homework_id, values)
    toast({
      message: 'Успешно',
      type: 'success'
    })
    back()
  } finally {
    loading.create = false
  }
}

const asyncDeleteHomework = async () => {
  try {
    loading.delete = true
    await deleteHomework(route.params.homework_id)
    toast({
      message: 'Успешно',
      type: 'success'
    })
    back()
  } finally {
    loading.delete = false
  }
}
</script>

<style scoped>
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all 0.3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 20px;
}
.buttons {
  display: flex;
  gap: 10px;
}
.edit {
  display: grid;
  /* grid-template-columns: 60% 40%; */
  /* grid-gap: 20px; */
  /* width: calc(100% - 20px); */
}
.edit .left {
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 40px;
}
.edit .left .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  display: flex;
  align-items: center;
  gap: 20px;
}
.edit .left .group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.edit .left .group .name {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
}
.edit .left input {
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 16px;
}
.edit .left input:focus {
  border: 1px solid var(--accent-color);
}
.edit .left .create-task-or-test {
  display: flex;
  gap: 20px;
}
.edit .left .create-task-or-test button {
  padding: 13px 30px;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}
.edit .left .create-task-or-test button svg {
  transition: all 0.3s ease;
  fill: black;
}
.edit .left .create-task-or-test button span {
  transition: all 0.3s ease;
  font-size: 16px;
}
.edit .left .create-task-or-test button:hover {
  border: 1px solid #0082fa;
}
.edit .left .create-task-or-test button:hover svg {
  fill: #0082fa;
}
.edit .left .create-task-or-test button:hover span {
  color: #0082fa;
}
.edit .left .uploads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
}
.edit .left .uploads .actions {
  display: flex;
  gap: 15px;
}
.edit .left .uploads .actions svg {
  cursor: pointer;
  transition: all 0.3s ease;
}
.edit .left .uploads .actions svg:hover {
  fill: #0082fa;
}
.edit .left .uploads .actions .x:hover {
  fill: #ff5656;
}
.edit .left .add-file {
  width: fit-content;
  display: flex;
  align-items: center;
}
.edit .left .add-file span {
  color: #0082fa;
}
.edit .left .add-file svg {
  fill: #0082fa;
}
.edit .right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.edit .right .statuses {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
}
.edit .right .statuses .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 20px;
}
.edit .right .statuses .group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.edit .right .statuses .status {
  border-radius: 20px;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}
.edit .right .status.published {
  background: rgba(0, 219, 127, 0.15);
}
.edit .right .status.published svg {
  fill: #00854d;
}
.edit .right .status.published span {
  color: #00854d;
}
.edit .right .status.draft {
  background: rgba(22, 25, 49, 0.15);
}
.edit .right .status.draft svg {
  fill: rgba(22, 25, 49, 0.8);
}
.edit .right .status.draft span {
  color: rgba(22, 25, 49, 0.8);
}
.edit .delete-lesson {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
.edit .delete-lesson span {
  color: #ff5656;
}
.edit .delete-lesson svg {
  fill: #ff5656;
}

.edit .right .deadline {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
}
.edit .right .deadline .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 20px;
}
.edit .right .deadline input {
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 16px;
  transition: all 0.2s ease;
}
.edit .right .deadline input:focus {
  border: 1px solid var(--accent-color);
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox + label::before {
  width: 18px;
  height: 18px;
  content: '';
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  margin-right: 0.5em;
  transition-duration: 0.2s;
  cursor: pointer;
}
.custom-checkbox + label:active {
  transform: scale(80%);
}
.custom-checkbox:checked + label::before {
  transition-duration: 0.2s;
  border-color: #161931;
  background: #161931;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #161931;
  transition: all 0.2s ease;
}

.questions-and-tests {
  display: grid;
  gap: 20px;
}
.questions-and-tests .choose-type {
  display: flex;
  align-items: center;
  gap: 20px;
}
.questions-and-tests .choose-type div {
  display: flex;
  gap: 5px;
  align-items: center;
}
.questions-and-tests .delete-task {
  padding: 3px;
  border-radius: 2px;
}
.questions-and-tests .tests {
  display: grid;
  gap: 20px;
}
.questions-and-tests .tests .add-test {
  margin-left: 58px;
  width: fit-content;
  display: flex;
  gap: 7px;
  align-items: center;
}
.questions-and-tests .tests .add-test span {
  transition: all .4s ease;
  color: #0082FA;
  cursor: pointer;
}
.questions-and-tests .tests .add-test:hover span {
  transform: translateX(5px);
}
.questions-and-tests .tests .add-test svg {
  fill: #0082FA;
  transition: all .4s ease;
}
.questions-and-tests .tests .add-test:hover svg {
  transform: rotate(90deg);
}
.questions {
  display: grid;
  gap: 20px;
}
.questions-and-tests .answers {
  display: grid;
  grid-template-columns: 20px 18px 1fr 32px;
  align-items: center;
  gap: 10px;
}
.questions-and-tests .answers span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.questions-and-tests .answers input {
  width: 100%;
}
.questions-and-tests .answers .add {
  background: var(--accent-color);
  padding: 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.questions-and-tests .answers .delete {
  background: #ff5656;
  padding: 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.tip {
  font-size: 12px;
  font-weight: 400;
  color: var(--primary-color);
  opacity: 0.9;
}

.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.modal .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  text-align: center;
}
.modal .buttons {
  display: flex;
  gap: 20px;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.tasks-enter-active,
.tasks-leave-active {
  transition: all 0.3s ease;
}
.tasks-enter-from,
.tasks-leave-to {
  opacity: 0;
}

.answers-enter-active,
.answers-leave-active {
  transition: all 0.3s ease;
}
.answers-leave-to {
  opacity: 0;
}
.answers-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
