<template>
  <div class="wrapper-system">
    <router-link class="back" to="/business/groups">
      <SvgIcon name="back" :viewboxHeight="18" :viewboxWidth="18" :height="18" :width="18"/>
      <span>Группы</span>
    </router-link>
    <transition mode="out-in">
      <h1 v-if="values.courseName">{{ values.courseName }}</h1>
      <h1 v-else class="skeleton middle-text"></h1>
    </transition>
    <transition mode="out-in">
      <div class="main" v-if="values.users.length > 0">
        <section class="users">
          <select name="role" id="">
            <option value="student">Студент</option>
            <option value="teacher">Учитель</option>
          </select>
          <ul class="users-list">
            <li v-for="user of values.users" :key="user" v-wave @click="values.selectedUser = user" :class="'user--' + user.id + ' ' + activeUserOnList(user)">
              <img src="/img/tmp/default_avatar.png" alt="">
              <span>{{ user.name }}</span>
            </li>
          </ul>
        </section>
        <section class="user-data">
          <div class="profile">
            <img src="/img/tmp/default_avatar.png" alt="">
            <div class="user-main-data">
              <span class="name">{{ values.selectedUser.name }}</span>
              <span class="role">{{ values.selectedUser.role === 'student' ? 'Студент' : values.selectedUser.role }}</span>
              <a :href="'mailto:' + values.selectedUser.email">{{ values.selectedUser.email }}</a>
            </div>
          </div>
          <div class="user-info">
            <h2>Инфо</h2>
            <div class="group">
              <span class="name">Добавлено</span>
              <span class="value">Нет в базе</span>
            </div>
            <div class="group">
              <span class="name">Дата рождения</span>
              <span class="value">{{ moment(values.selectedUser.birthday).format('DD.MM.YYYY') }}</span>
            </div>
            <div class="group">
              <span class="name">Пол</span>
              <span class="value" v-if="values.selectedUser.gender == 'male'">Мужской</span>
              <span class="value" v-else-if="values.selectedUser.gender == 'female'">Женский</span>
              <span class="value" v-if="values.selectedUser.gender == null">Не выбран</span>
            </div>
            <div class="group">
              <span class="name">Телефонный номер</span>
              <a class="value" :href="'tel:' + values.selectedUser.phone ">{{ values.selectedUser.phone }}</a>
            </div>
          </div>
        </section>
        <section class="user-prefered-times">
          <h3>Студент хочет учится:</h3>
          <PreferedDays
            v-if="values.selectedUser.preferred_weekdays"
            v-model:morning="values.selectedUser.preferred_weekdays.morning"
            v-model:day="values.selectedUser.preferred_weekdays.day"
            v-model:evening="values.selectedUser.preferred_weekdays.evening"
            :editable="false"
          />
          <PreferedDays
            v-else
            v-model:morning="values.preferred_weekdays.morning"
            v-model:day="values.preferred_weekdays.day"
            v-model:evening="values.preferred_weekdays.evening"
            :editable="false"
          />
        </section>
      </div>

      <div class="main disabled" v-else>
        <section class="users">
          <select name="role" id="">
            <option value="student">Студент</option>
            <option value="teacher">Учитель</option>
          </select>
          <ul class="users-list">
            <li v-for="value of 10" :key="value" v-wave>
              <div class="skeleton skeleton-img"></div>
              <span class="skeleton middle-text"></span>
            </li>
          </ul>
        </section>
        <section class="user-data">
          <div class="profile">
            <img src="/img/tmp/default_avatar.png" alt="">
            <div class="user-main-data">
              <span class="name skeleton middle-text"></span>
              <span class="role skeleton small-text"></span>
              <a class="skeleton middle-text" href="mailto:123"></a>
            </div>
          </div>
          <div class="user-info">
            <h2>Инфо</h2>
            <div class="group">
              <span class="name">Добавлено</span>
              <span class="value skeleton small-text"></span>
            </div>
            <div class="group">
              <span class="name">Дата рождения</span>
              <span class="value skeleton small-text"></span>
            </div>
            <div class="group">
              <span class="name">Пол</span>
              <span class="value skeleton small-text"></span>
            </div>
            <div class="group">
              <span class="name">Телефонный номер</span>
              <a class="value skeleton middle-text" href="tel:123"></a>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script setup>
// import FastAverageColor from 'fast-average-color'
import { reactive } from 'vue-demi'
import api from '@/modules/api'
import { useRoute } from 'vue-router'
import moment from 'moment'
import PreferedDays from '../../../components/PreferedDays.vue'

// const fac = new FastAverageColor()
const route = useRoute()
const values = reactive({
  courseName: '',
  users: [],
  selectedUser: {},
  groupCourse: '',
  preferred_weekdays: {
    morning: [],
    day: [],
    evening: []
  }
})

api.requestGet(
  '/api/groups/' + route.params.groupid,
  {},
  response => {
    const group = response.data.data
    values.selectedUser = group.users[0]
    values.users = group.users
    values.courseName = group.name
  }
)

const activeUserOnList = item => {
  if (values.selectedUser.id === item.id) {
    return 'active'
  }
}

// const setBackgroundColor = (item) => {
//   const container = document.querySelector(`.card.id--${item.id}`)
//   const img = container.querySelector('img')
//   fac.getColorAsync(img)
//     .then(color => {
//       container.style.backgroundColor = color.rgba
//       container.style.color = color.isDark ? '#fff' : '#000'
//     })
//     .catch(e => {
//       console.log(e)
//     })
// }
// const removeBackgroundColor = (item) => {
//   const container = document.querySelector(`.card.id--${item.id}`)
//   container.style.backgroundColor = '#fff'
//   container.style.color = '#000'
// }
</script>
<style scoped>
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all .3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
h1 {
  margin-bottom: 20px;
  color: var(--primary-color);
  font-size: 24px!important;
  letter-spacing: 0.02em;
}
.main {
  display: grid;
  grid-template-columns: .5fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}
.users {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: inherit;
  grid-row: 1 / 3;
  overflow: hidden;
  position: relative;
}
.users select {
  padding: 16px;
  border: 1px solid #161931;
  border-radius: 3px;
  height: fit-content;
  position: absolute;
  width: 100%;
}
.users .users-list {
  overflow-y: auto;
  position: relative;
  top: 70px;
}
.users .users-list li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(22, 25, 49, 0.05);
  gap: 20px;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
}
.users .users-list li.active {
  background: var(--accent-color);
}
.users .users-list li.active:hover {
  background: var(--accent-color);
}
.users .users-list li:last-child {
  border: none;
}
.users .users-list li:hover {
  background: var(--hover-color);
}
.users .users-list li img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  object-fit: cover;
}
.users .users-list li span {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.users .users-list li.active span {
  color: #fff;
}
.user-data {
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(22, 25, 49, 0.1);
  background: #fff;
  padding: 25px;
  grid-row: 1;
  grid-column: 2 / 4;
}
.user-data .profile {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 45px;
}
.user-data .profile img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  object-fit: cover;
}
.user-data .profile .user-main-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.user-data .profile .user-main-data .name {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}
.user-data .profile .user-main-data .role {
  color: var(--primary-color);
  font-size: 16px;
  line-height: 19px;
}
.user-data .profile .user-main-data a {
  color: var(--accent-color);
}
.user-data h2 {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.02em;
  margin-bottom: 25px;
  grid-column: 1 / 3;
}
.user-data .user-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.user-data .group {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.user-data .group .name {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-color);
}
.user-prefered-times {
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(22, 25, 49, 0.1);
  background: #fff;
  padding: 15px;
  overflow-y: auto;
  grid-column: 2 / 4;
}
.user-prefered-times h3 {
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  padding: 10px;
}

.skeleton.skeleton-img {
  width: 34px;
  height: 34px;
  object-fit: cover;
}

.disabled {
  pointer-events: none;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.img.skeleton {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 3;
}
.name.skeleton.middle-text {
  height: 1em;
}
</style>
