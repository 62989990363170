<template>
  <main>
    <h1>
      <span>Виды оценок</span>
      <Button name="ДОБАВИТЬ ОЦЕНКИ">
        <template v-slot:left >
          <SvgIcon name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" style="fill: white;" />
        </template>
      </Button>
    </h1>
    <GradesTable :grades="grades" />
  </main>
</template>

<script>
import GradesTable from '@/components/uiBusiness/Grades/GradesTable.vue'
import { onMounted, reactive, toRefs } from 'vue-demi'
import { getGrades } from '@/actions/uiBusiness/grades'

export default {
  name: 'Grades',
  components: {
    GradesTable
  },
  props: {
  },
  setup () {
    const states = reactive({
      loading: true,
      grades: []
    })
    onMounted(async () => {
      const response = await getGrades()
      const gradeTypes = response.reduce((acc, grade) => {
        if (!acc[grade.grade_type.id]) acc[grade.grade_type.id] = { ...grade.grade_type, grades: [] }
        return acc
      }, {})
      response.forEach(grade => gradeTypes[grade.grade_type.id].grades.push({
        id: grade.id,
        name: grade.name,
        percent: grade.percent
      }))
      states.grades = Object.values(gradeTypes)
    })
    return {
      ...toRefs(states)
    }
  }
}
</script>

<style scoped>
main {
  padding: 20px 30px;
}
h1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
}
h1 span {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #161931;
}
</style>
