<template>
  <div class="img" :class="loaded ? '' : 'loading'">
    <!-- :srcset="
        `${apiUrl}/storage/small/${src},
        ${apiUrl}/storage/medium/${src} 1.5x,
        ${apiUrl}/storage/big/${src} 2x`" -->
    <img
      :class="{
        loading: !loaded
      }"
      :src="getFileUrl(src)"
      crossorigin="anonymous"
      alt=""
      loading="lazy"
      @load="
        loaded = true,
        $emit('load')
      "
    />
    <transition>
      <div
        v-if="!loaded"
        class="loader"
        :class="{
          loading: loaded
        }"
      >
        <Loading />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue-demi'
import Loading from './Loading.vue'
import getFileUrl from '@/helpers/getFileUrlByDate'

defineProps({
  src: {
    type: Object,
    default: null
  }
})
defineEmits(['load'])

const loaded = ref(false)
</script>

<style scoped>
.img {
  position: relative;
  width: inherit;
  height: inherit;
  overflow: hidden;
  transition: all 0.3s ease;
}
.loading {
  border-radius: 8px;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  background-color: #161931ee;
  z-index: 1;
}
img {
  width: inherit;
  height: inherit;
  transition: all 0.3s ease;
  object-fit: contain;
}
img.loading {
  filter: blur(5px);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
