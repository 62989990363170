<template>
  <div class="main-landing">
    <div>
      <Header />

      <section class="wrapper wrapper-landing">
        <h2 class="landing-title">Категории</h2>

        <div class="categories" v-if="categories">
          <CategoryCard
            v-for="category of categories"
            :key="category"
            :category="category"
          />
        </div>

        <div class="categories" v-else>
          <CategoryCard
            v-for="category of 20"
            :key="category"
          />
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import Header from '@/components/uiLanding/layouts/Header.vue'
import Footer from '@/components/uiLanding/layouts/Footer.vue'
import CategoryCard from '@/components/uiLanding/Categories/CategoryCard.vue'

import { onMounted, ref } from 'vue'
import { getCourseCategories } from '@/actions/uiBusiness/courses'

const categories = ref(null)

onMounted(async () => {
  categories.value = (await getCourseCategories()).data
})
</script>

<style scoped lang="scss">
.categories {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}
</style>
