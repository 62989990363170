<template>
  <div>
    <button class="back" @click="back">
      <SvgIcon name="back" :viewboxHeight="18" :viewboxWidth="18" :height="18" :width="18"/>
      <span>Назад</span>
    </button>
    <div class="main">
      <h1>
        <span class="title">Хозяйственно-бытовые постройки</span>
      </h1>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

const back = () => {
  router.go(-1)
}
</script>

<style scoped>
.main {
  padding: 20px 30px;
}
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 40px;
  transition: all .3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
h1 span {
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #161931;
}
</style>
