<template>
  <div class="not_courses">
    <img class="preview" src="/img/tmp/not_courses.svg" alt="">
    <h2 class="title">Похоже, у вас нет пока курсов</h2>
    <p class="subTitle">Люди будут появляться здесь, когда они присоединяются к вашему списку, проходят оценку и покупают Продукты.<br> Для наилучшего восприятия не забудьте пометить своих участников.</p>
    <router-link class="btn" v-wave to="/courses/"><span>приобрести курс</span><SvgIcon name="arrow-right" :viewboxHeight="32" :viewboxWidth="32" :height="32" :width="32"/></router-link>
  </div>
</template>

<style scoped>
.not_courses{
  min-height: 78vh;
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.not_courses .preview{
  display: block;
  height: 160px;
  width: 160px;
  margin-bottom: 40px;
}
.not_courses .title{
  color: #161931;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.not_courses .subTitle{
  color: #000000;
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 20px;
}
.not_courses .btn{
  display: inline-flex;
  align-items: center;
  padding: 9px 17px;
  border: 2px solid #0082FA;
  border-radius: 3px;
  background-color: #0082FA;
  transition: background-color .2s linear;
}
.not_courses .btn span{
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 17px;
  transition: color .2s linear;
}
.not_courses .btn svg{
  display: block;
  height: 32px;
  width: 32px;
  fill: #fff;
  transition: transform .2s linear, fill .2s linear;
}
.not_courses .btn:hover{
  background-color: #fff;
}
.not_courses .btn:hover span{
  color: #0082FA;
}
.not_courses .btn:hover svg{
  fill: #0082FA;
  transform: translateX(7px);
}
</style>
