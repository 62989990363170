<template>
  <div class="wrapper-system" v-if="!isEmpty">
    <h1>
      <span>Уроки</span>
      <div class="view-type">
        <button v-wave class="grid active" @click="viewTypeGrid(value)">
          <SvgIcon name="button-grid" />
        </button>
        <button v-wave class="list" @click="viewTypeList(value)">
          <SvgIcon name="button-list"/>
        </button>
      </div>
    </h1>
    <div v-if="AllLessons.length > 0 && categories">
      <div class="course" v-for="(course, index) of AllLessons" :key="course">
        <div class="header">
          <div class="left">
            <span class="course-name">{{ course.name }}</span>
            <span class="lessons-count">{{ course.lessons_count }} уроков</span>
          </div>
        </div>
        <template v-if="!viewType && course.lessons.length">
          <button class="scroll-controller l" @click="scroll('left', index)" v-if="!leftButtons[index]">
            <SvgIcon name="chevron-left" :viewboxWidth="24" :viewboxHeight="40" :width="20" :height="20" />
          </button>
          <button class="scroll-controller r" @click="scroll('right', index)" v-if="!rightButtons[index]">
            <SvgIcon name="chevron-right" :viewboxWidth="24" :viewboxHeight="40" :width="20" :height="20" />
          </button>
        </template>

        <div class="lessons" :class="{ listType: viewType }" :id="`lesson-${index}`">
          <IsVisible v-model="leftButtons[index]"/>
          <div class="lesson" v-for="lesson of course.lessons" :key="lesson.id">
            <router-link :to="'/lesson/' + course.id + '/unit/' + lesson.id" class="card" v-wave="!viewType">
              <SvgIcon name="document-lesson" :viewboxWidth="18" :width="18" :viewboxHeight="18" :height="18" v-if="viewType" style="marginRight: 10px"/>
              <span class="lesson-name">{{ lesson.name }}</span>
              <div>
                <span class="lesson-category">Категория: {{ categories.find(item => item.id === course.course_category_id).name }}</span>
                <div class="lesson-info">
                  <div class="lesson-position">
                    <SvgIcon name="camera-lesson" :viewboxWidth="16" :width="16" :viewboxHeight="16" :height="16" v-if="viewType" style="marginRight: 5px"/>
                    <span>{{ lesson.order + '/' + course.lessons_count }} урок</span>
                  </div>
                  <div class="lesson-homeworks-count">
                    <SvgIcon name="list" :viewboxHeight="16" :viewboxWidth="16" :width="13" :height="13" fill="#161931"/>
                    <span>{{ lesson.homework.length > 0 ? `${lesson.homework.length === 1 ? '1 домашнее задание' : `${lesson.homework.length} домашних заданий`}` : 'домашних заданий нет' }}</span>
                  </div>
                </div>
              </div>
            </router-link>
            <div v-if="lesson.order < course.finished_lessons_count" class="status completed">
              <SvgIcon name="check-fill" fill="#00DB7F" :viewboxHeight="16" :viewboxWidth="16" :width="16" :height="16"/>
              <span>Пройдено</span>
            </div>
            <div v-else class="status">
              <SvgIcon name="check-fill" fill="#16193180;" :viewboxHeight="16" :viewboxWidth="16" :width="16" :height="16"/>
              <span>Не пройдено</span>
            </div>
          </div>
          <IsVisible v-model="rightButtons[index]"/>
        </div>
      </div>
    </div>

    <div class="disabled" v-else>
      <div class="course">
        <div class="header">
          <div class="left">
            <span class="course-name skeleton middle-text"></span>
            <span class="lessons-count skeleton small-text"></span>
          </div>
        </div>

        <div class="lessons" v-for="item of 3" :key="item">
          <div/>
          <div class="lesson" v-for="item of 8" :key="item">
            <div class="card">
              <span class="lesson-name skeleton small-text"></span>
              <div>
                <span class="lesson-category skeleton middle-text"></span>
                <div class="lesson-info">
                  <div class="lesson-position">
                    <span class="skeleton small-text"></span>
                  </div>
                  <div class="lesson-homeworks-count">
                    <span class="skeleton small-text"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="status skeleton middle-text">
            </div>
          </div>
          <div/>
        </div>
      </div>
    </div>
  </div>
  <div class="not_courses" v-else>
    <img class="preview" src="/img/tmp/not_courses.svg" alt="">
    <h2 class="title">Похоже, у вас нет пока курсов</h2>
    <p class="subTitle">Люди будут появляться здесь, когда они присоединяются к вашему списку, проходят оценку и покупают Продукты.<br> Для наилучшего восприятия не забудьте пометить своих участников.</p>
    <router-link class="btn" v-wave to="/courses/"><span>приобрести курс</span><SvgIcon name="arrow-right" :viewboxHeight="32" :viewboxWidth="32" :height="32" :width="32"/></router-link>
  </div>
</template>

<script setup>
import { ref } from 'vue-demi'
import api from '@/modules/api'
import IsVisible from '@/components/common/IsVisible.vue'

const leftButtons = ref([])
const rightButtons = ref([])
const viewType = ref(false)
const AllLessons = ref([])
const isEmpty = ref(false)
const categories = ref()

const viewTypeGrid = () => {
  document.querySelector('button.grid').classList.add('active')
  document.querySelector('button.list').classList.remove('active')
  viewType.value = false
}
const viewTypeList = () => {
  document.querySelector('button.list').classList.add('active')
  document.querySelector('button.grid').classList.remove('active')
  viewType.value = true
}

api.requestGet(
  '/api/student/courses',
  {},
  response => {
    AllLessons.value = response.data.data
    if (AllLessons.value.length === 0) isEmpty.value = true
  }
)

api.requestGet(
  '/api/course-categories',
  {},
  response => {
    categories.value = response.data.data
  }
)

const scroll = (direction, index) => {
  const el = document.getElementById(`lesson-${index}`)
  if (direction === 'left') {
    el.scroll({
      left: el.scrollLeft - 280,
      behavior: 'smooth'
    })
  }
  if (direction === 'right') {
    el.scroll({
      left: el.scrollLeft + 280,
      behavior: 'smooth'
    })
  }
}
</script>
<style scoped>
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 20px;
}
h1 .view-type {
  display: flex;
  align-items: center;
  gap: 10px;
}
h1 .view-type button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;
}
h1 .view-type button svg {
  fill: rgba(22, 25, 49, 0.5);
}
h1 .view-type button.active {
  background: #F1F3F4;
}
h1 .view-type button.active svg {
  fill: #000;
}
.course {
  position: relative;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.header .left {
  display: flex;
  align-items: baseline;
  gap: 30px;
}
.header .left .course-name {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: var(--primary-color)
}
.header .left .lessons-count {
  font-weight: 600;
  font-size: 16px;
  color: var(--accent-color)
}
.lessons {
  display: flex;
  gap: 20px;
  border: 0.5px solid var(--primary-color);
  border-radius: 10px;
  padding: 20px 0;
  overflow-x: scroll;
  margin-bottom: 30px;
  scroll-snap-type: x proximity;
  position: relative;
}
.lessons::-webkit-scrollbar {
  display: none;
}
.lesson {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-snap-align: start;
  scroll-margin: 20px;
}
.lesson .card {
  min-height: 145px;
  background: #F1F3F4;
  border-radius: 10px;
  padding: 13px;
  transition: all .2s ease;
  cursor: pointer;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.lesson .card:hover {
  box-shadow: inset 0px 0px 0px 1px #161931;
}
.card .lesson-name {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1;
  color: var(--primary-color);
  text-overflow: ellipsis;
  overflow: hidden;
}
.lesson-category {
  display: block;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: var(--cancel-color);
  margin-bottom: 6px;
}
.lesson-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.lesson-info .lesson-position {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.lesson-info .lesson-homeworks-count {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.status {
  display: flex;
  align-items: center;
  gap: 5px;
}
.status span {
  color: #16193180;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}
.status.completed span {
  color: #00DB7F;
}

.lessons-list {
  border: 0.5px solid var(--primary-color);
  border-radius: 10px;
  padding: 20px 0;
  display: grid;
  grid-auto-rows: 50px;
  margin-bottom: 30px;
}
.lessons-list .row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--hover-color);
  align-items: center;
  padding: 0px 20px;
}
.lessons-list .row:hover {
  background: var(--hover-color);
}
.lessons-list .row .lesson-name {
  height: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  text-overflow: ellipsis;
  overflow: hidden;
}

.lesson-enter-active,
.lesson-leave-active {
  transition: all 1s ease;
}
.lesson-enter-from {
  opacity: 0;
}
.lesson-leave-to {
  opacity: 0;
}
.listType {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow-x: none;
  padding-bottom: 0;
}
.listType .lesson {
  display: grid;
  grid-template-columns: 1fr 100px;
  border-bottom: 1px solid #F3F3F5;
  padding-bottom: 20px;
}
.listType .lesson .card {
  width: 100%;
  min-height: auto;
  display: grid;
  grid-template-columns: auto 1fr 250px;
  border-radius: 0;
  padding: 0;
  align-items: center;
  background-color: #fff;
}
.listType .lesson .card .lesson-info {
  display: grid;
  align-items: center;
  grid-template-columns: 80px 1fr;
}
.listType .lesson .card:hover {
  box-shadow: none;
}
.listType .lesson .lesson-name {
  height: auto;
  width: 79%;
}
.listType .lesson .lesson-category {
  display: none;
}
.listType .lesson-info span,
.listType .status span {
  white-space: pre;
}

/* SKELETON */
.disabled {
  pointer-events: none;
}
.disabled .card{
  background-color: #fff !important;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
}
.disabled .lesson-name{
  widows: 70%;
}

/* No Courses */

.not_courses{
  min-height: 78vh;
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.not_courses .preview{
  display: block;
  height: 160px;
  width: 160px;
  margin-bottom: 40px;
}
.not_courses .title{
  color: #161931;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.not_courses .subTitle{
  color: #000000;
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 20px;
}
.not_courses .btn{
  display: inline-flex;
  align-items: center;
  padding: 9px 17px;
  border: 2px solid #0082FA;
  border-radius: 3px;
  background-color: #0082FA;
  transition: background-color .2s linear;
}
.not_courses .btn span{
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 17px;
  transition: color .2s linear;
}
.not_courses .btn svg{
  display: block;
  height: 32px;
  width: 32px;
  fill: #fff;
  transition: transform .2s linear, fill .2s linear;
}
.not_courses .btn:hover{
  background-color: #fff;
}
.not_courses .btn:hover span{
  color: #0082FA;
}
.not_courses .btn:hover svg{
  fill: #0082FA;
  transform: translateX(7px);
}
.scroll-controller {
  position: absolute;
  top: 50%;
  z-index: 1;
}
.scroll-controller.l {
  left: 0;
}
.scroll-controller.r {
  right: 0;
}
</style>
