const checkFullAccess = (courseId, user) => {
  let found = false
  if (courseId && user) {
    user.courses.forEach(course => {
      if (
        !found &&
        course.id === Number(courseId) &&
        (course.permission.role === 'admin' || course.permission.role === 'teacher')
      ) found = true
    })
  }
  return found
}

export default checkFullAccess
