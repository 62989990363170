<template>
  <div class="assessment">
    <div class="wrapper-system" v-if="data">
      <button class="back" @click="back">
        <SvgIcon name="back" :viewboxHeight="18" :viewboxWidth="18" :height="18" :width="18"/>
        <span>Назад</span>
      </button>
      <div class="title">
        <h1>{{ data.homework.lesson.course.name }}</h1>
        <h1>{{ data.homework.lesson.name }}</h1>
      </div>
      <section>
        <div class="answer-block">
          <h3 class="answer-title">{{ data.homework.name }}</h3>
          <p class="answer-subtitle">Это часовая лекция, которая поможет людям, начинающим заниматься веб-дизайном, составить план дальнейших действий. Всего за 1 час можно узнать о таких элементарных вещах, как виды хостингов, типы разработок, современные технологии в дизайне и многое другое.</p>
          <div class="answer-item" v-for="(answer, idx) in data.answers" :key="answer" >
            <div class="answer-num">
              <span>{{idx + 1}})</span>
              <div :innerHTML="answer.task.content"></div>
            </div>
            <div class="answer-content">
              <div class="answer-secondTitle">Ответ</div>
              <p class="answer-text" v-html="answer.answer"/>
            </div>
            <div class="checking-hw-btns">
              <button
                class="checking-hw-btn right"
                :class="{active: answer.is_correct === 1}"
                @click="() => { checkedHomeWork(answer.id, true) }"
                :disabled="answer.task.type === 'test'"
              >
                <SvgIcon
                  name="checking"
                  :viewboxHeight="17"
                  :viewboxWidth="17"
                  :height="17"
                  :width="16"
                />
                <span>Правильно!</span>
              </button>
              <button
                class="checking-hw-btn no-right"
                :class="{active: answer.is_correct === 0}"
                @click="() => { checkedHomeWork(answer.id, false) }"
                :disabled="answer.task.type === 'test'"
              >
                <SvgIcon
                  name="checking"
                  :viewboxHeight="17"
                  :viewboxWidth="17"
                  :height="17"
                  :width="16"
                />
                <span>Неправильно</span>
              </button>
            </div>
          </div>

          <!-- <div class="answer-item">
            <div class="answer-num">3) Верный ответ</div>
            <div class="answer-content">
              <div class="answer-option"><input type="radio" checked><span>A</span></div>
              <div class="answer-photo"></div>
              <img src="/img/tmp/Assessment.png" alt="">
            </div>
          </div> -->
        </div>
        <div class="footer">
          <div class="title">Оцените домашнее задание студента</div>
          <div class="evaluate">
            <input class="input" id="input" type="number" placeholder="Введите балл" v-model="inputtedData.grade" @input="validateGrade">
            <div class="score">
              <span>{{ inputtedData.grade ? (inputtedData.grade > 100 ? 100 : inputtedData.grade) : "0" }} из 100 </span>
              <div class="score-progress">
                <div class="score-progress__background" :style="{width: (inputtedData.grade ? (inputtedData.grade > 100 ? 100 : inputtedData.grade)  : '0') + '%'}"></div>
              </div>
            </div>
          </div>
          <textarea class="message" id="take-comment" rows="3" placeholder="Комментарий" v-model="inputtedData.comment"></textarea>
          <div class="btns">
            <Button class="submit" @click="setGrade" :loading="loading">Отправить</Button>
            <Button type="close" class="close" @click="() => {router.push('/business/homeworks')}">Отмена</Button>
          </div>
        </div>
      </section>
    </div>

    <div class="wrapper-system" v-else>
      <button class="back" @click="back">
        <SvgIcon name="back" :viewboxHeight="18" :viewboxWidth="18" :height="18" :width="18"/>
        <span>Назад</span>
      </button>
      <div class="title disabled">
        <h1 class="skeleton small-text"></h1>
        <h1 class="skeleton middle-text"></h1>
      </div>
      <section class="disabled">
        <div class="answer-block">
          <h3 class="answer-title skeleton middle-text"></h3>
          <p class="answer-subtitle skeleton"></p>
          <div class="answer-item" v-for="value in 5" :key="value">
            <div class="answer-num skeleton middle-text"></div>
            <div class="answer-content">
              <div class="answer-secondTitle skeleton small-text"></div>
              <div class="answer-text skeleton"></div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="title answer-title skeleton dark middle-text"></div>
          <div class="evaluate">
            <div class="input skeleton dark"></div>
            <div class="score">
              <span class="skeleton dark small-text"></span>
              <div class="score-progress skeleton dark">
              </div>
            </div>
          </div>
          <div class="message skeleton dark"></div>
          <div class="btns">
            <button class="submit skeleton dark">Отправить</button>
            <button class="close skeleton dark">Отмена</button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { reactive, ref } from 'vue-demi'

import api from '@/modules/api'
import { useToast } from '@/modules/toast'

import { verifiedAnswer } from '@/actions/uiBusiness/assessment'

const router = useRouter()
const route = useRoute()
const { toast } = useToast()

const data = ref()
const inputtedData = reactive({
  grade: null,
  comment: ''
})
const loading = ref(false)

const back = () => {
  router.go(-1)
}

api.requestGet(
  `/api/takes/${route.params.take_id}`,
  {},
  response => {
    data.value = response.data.data
    data.value.answers.forEach(answer => {
      if (answer.task.type === 'test' && answer.is_correct === null) {
        answer.is_correct = answer.task_answer.is_correct
      }
    })
    inputtedData.grade = data.value.grade
  }
)

const checkedHomeWork = async (answerId, mark) => {
  await verifiedAnswer(answerId, mark)
  data.value.answers.forEach(answer => {
    if (answer.id === answerId) answer.is_correct = mark ? 1 : 0
  })
}

const setGrade = async () => {
  if (inputtedData.grade) {
    loading.value = true
    try {
      await api.requestPut(
        `/api/takes/${route.params.take_id}`,
        inputtedData,
        response => {
          router.push('/business/homeworks')
          toast({
            message: 'Оценка успешно выставлена!',
            type: 'success'
          })
        }
      )
    } finally {
      loading.value = false
    }
  } else {
    toast({
      message: 'Поля не заполнены!',
      type: 'danger'
    })
  }
}

const validateGrade = () => {
  if (inputtedData.grade > 100) inputtedData.grade = 100
  if (inputtedData.grade < 0) inputtedData.grade = 0
}
</script>

<style scoped>
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all .3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
.title{
  color: #000 !important;
  margin-bottom: 15px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.19;
  letter-spacing: 0.02em;
  color: #000 !important;
}
section {
  background-color: #fff;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  margin-bottom: 15px;
  overflow: hidden;
}
.answer-block{
  padding: 30px;
  padding-right: 220px;
  color: #000 !important;
}
.answer-block .answer-item:last-child{
  margin-bottom: 0;
}
.answer-item{
  margin-bottom: 32px;
}
.answer-title{
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 14px;
}
.answer-subtitle,
.answer-text{
  font-size: 16px;
  line-height: 1.18;
  margin-bottom: 30px;
}
.answer-num{
  display: flex;
  grid-gap: 2px;
  margin-bottom: 14px;
}
.answer-content{
  padding-left: 14px;
}
.answer-secondTitle{
  font-size: 14px;
  font-weight: 300;
  color: rgba(#161931, .5);
  padding-top: 14px;
  margin-bottom: 15px;
}
.answer-option{
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.answer-option input{
  width: 12.8px;
  height: 12.8px;
  margin-right: 7px;
}
.answer-option span{
  font-size: 14px;
  line-height: 1.19;
}
.answer-photo{
  width: 340px;
}
/* Checking HomeWork Button */
.checking-hw-btns{
  display: flex;
  grid-gap: 15px;
}
.checking-hw-btn{
  display: flex;
  grid-gap: 10px;
  align-items: center;
  padding: 7px 16px 9px;
  border-radius: 3px;
  background: rgba(148, 150, 166, 0.2);
  transition: all .25s ease-in-out;
}
.checking-hw-btn[disabled]{
  pointer-events: none;
}
.checking-hw-btn svg {
  fill: #9496A6;
  transition: all .2s ease-in-out;
}
.checking-hw-btn span{
  font-weight: 500;
  font-size: 14px;
  color: #9496A6;
  transition: all .2s ease-in-out;
}
/* ---------- Is Active ----------- */
.checking-hw-btn.right.active {
  background: #00DB7F !important;
}
.checking-hw-btn.no-right.active {
  background: #FA7171 !important;
}
.checking-hw-btn.active span{
  color: #fff !important;
}
.checking-hw-btn.active svg{
  fill: #fff !important;
}

/* Checking HomeWork Button Right Is Active */
/* ---------- Hover ----------- */
.checking-hw-btn.right:hover {
  background: rgba(0, 219, 127, 0.2);
}
.checking-hw-btn.right:hover span{
  color: #00854D;
}
.checking-hw-btn.right:hover svg{
  fill: #00854D;
}

/* Checking HomeWork Button No Right */
/* ---------- Hover ----------- */
.checking-hw-btn.no-right:hover {
  background: rgba(250, 113, 113, 0.2);
}
.checking-hw-btn.no-right:hover span{
  color: #FA7171;
}
.checking-hw-btn.no-right:hover svg{
  fill: #FA7171;
}

.footer {
  background: var(--primary-color);
  padding: 30px;
}
.footer .title {
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  margin-bottom: 15px;
}
.input,
.message{
  width: 150px;
  padding: 16px;
  background: #fff;
  border: 0.5px solid var(--primary-color);
  border-radius: 5px;
  font-size: 14px;
  line-height: 17px;
  margin-right: 30px;
}
.evaluate{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.score span{
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.16;
}
.score-progress{
  min-width: 320px;
  height: 5px;
  background-color:#C4C4C4;
  position: relative;
  margin-top: 10px;
}
.score-progress__background{
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #00DB7F;
}
.message{
  min-width: 700px;
  min-height: 85px;
  margin-bottom: 40px;
  resize: vertical;
}
.btns{
  display: flex;
}
.submit{
  color: #fff;
  text-transform: uppercase;
  background-color: #0082FA;
  margin-right: 20px;
}
.submit:hover{
  background-color: #0469c9;
}
.close{
  color:#161931;
  background-color:#BBBCC1;
}
.close:hover{
  background-color:#9b9b9b;
}

/* SKELETON */
.disabled {
  pointer-events: none;
}
.disabled .answer-subtitle{
  height: 80px;
  border-radius: 8px;
}
.disabled.title h1:first-child{
  margin-bottom: 10px;
}
.disabled .answer-text{
  height: 60px;
  border-radius: 8px;
}
.disabled .message{
  resize: none;
}
</style>
<style>
.assessment .submit,
.assessment .close{
  min-height: 50px;
}
.assessment .answer-num p,
.assessment .answer-num span{
  font-size: 14px;
  font-weight: 500;
  line-height: 1.19;
}
</style>
