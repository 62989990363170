<template>
  <Form @finish="updatePasswordRemember" class="password-inputs" v-if="stateRemember">
    <div class="old-password">
      <Input
        type="password"
        name="passwordOld"
        placeholder="Старый пароль"
        v-model="formData.passwordOld"
        required
      />
      <router-link to="/authorization/remember-password" class="remember-password">Забыли свой пароль?</router-link>
    </div>
    <Input
      type="password"
      name="password"
      placeholder="Новый пароль"
      v-model="formData.password"
      required
      validation="password"
    />
    <Input
      type="password"
      name="passwordConfirm"
      placeholder="Повторить новый пароль"
      required
      v-model="formData.passwordConfirm"
      validation="sameAs"
      :sameAs="formData.password"
    />
    <Button
      name="СОХРАНИТЬ"
      :loading="loading.RememberPassword"
      size="large"
      htmlType="submit"
    />
  </Form>
  <div  v-else>
    <h2>Введите новый пароль</h2>
    <Form @finish="updatePassword" class="form-updatePassword">
      <Input
        type="password"
        name="password"
        placeholder="Пароль"
        required
        v-model="formData.password"
        validation="password"
      />
      <Input
        type="password"
        name="passwordConfirm"
        placeholder="Повторитe пароль"
        required
        v-model="formData.passwordConfirm"
        validation="sameAs"
        :sameAs="formData.password"
      />
      <Button
        name="Отправить"
        :loading="loading.UpdatePassword"
        size="large"
        htmlType="submit"
      />
    </Form>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import api from '@/modules/api'
import router from '@/router/index.js'
import { useToast } from '@/modules/toast'
import Form from './common/Form.vue'

defineProps({
  stateRemember: {
    type: Boolean,
    default: false
  }
})
const { toast } = useToast()
const formData = reactive({
  password: '',
  passwordOld: '',
  passwordConfirm: ''
})
const loading = reactive({
  UpdatePassword: false,
  RememberPassword: false
})
// const hasError = reactive({
//   password: true,
//   passwordOld: false,
//   passwordConfirm: true
// })

// const handleError = fields => {
//   for (const field in fields) {
//     if (fields[field]) return true
//   }
//   return false
// }

const updatePasswordRemember = () => {
  loading.RememberPassword = true
  api.requestPut(
    '/api/user',
    {
      old_password: formData.passwordOld,
      password: formData.password
    },
    response => {
      toast({
        message: 'Пароль успешно изменен'
      })
      formData.password = ''
      formData.passwordOld = ''
      formData.passwordConfirm = ''
      loading.RememberPassword = false
    },
    _ => {
      loading.RememberPassword = false
    }
  )
}
const updatePassword = () => {
  loading.UpdatePassword = true
  api.requestPut(
    '/api/user',
    {
      password: formData.password
    },
    response => {
      toast({
        message: 'Пароль успешно изменен'
      })
      router.push('/')
      loading.RememberPassword = false
    },
    _ => {
      loading.UpdatePassword = false
    }
  )
}
</script>

<style scoped>
h2 {
  margin-bottom: 36px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #161931;
  display: block;
  text-align: center;
}

.password-inputs {
  display: grid;
  gap: 10px;
  min-width: 480px;
}
.form-updatePassword {
  display: grid;
  gap: 10px;
  margin-bottom: 30px;
  align-items: center;
  min-width: 480px;
}
.old-password{
  margin-bottom: 10px;
}
.remember-password{
  font-size: 14px;
  color: #0082FA;
}
</style>
