<template>
  <Modal
    :hide="
      () => {
        $emit('hide')
      }
    "
  >
    <Transition mode="out-in">
      <div class="online-course">
        <Form @finish="sendCourseData" :ignores="courseIsFree ? ['price'] : null">
          <div class="header">
            <div class="left">
              <img class="avatar" src="/img/tmp/online_course.svg" alt="" />
              <span>Онлайн-курс</span>
            </div>
            <SvgIcon
              :viewboxHeight="14"
              :viewboxWidth="14"
              :width="14"
              :height="14"
              @click="$emit('hide')"
            />
          </div>
          <div class="body">
            <div class="group">
              <Input
                type="text"
                name="name"
                placeholder="Название"
                v-model="formData.name"
                required
              />
              <p>
                Выберите легко читаемый и запоминающийся заголовок для вашего
                продукта. Пример: «10 дней до продуктивности» или «Маркетинговое
                мастерство». Не более 140 символов.
              </p>
            </div>

            <!-- ---------- Select Categories ---------- -->
            <Select
              name="course_category_id"
              v-model="formData.course_category_id"
              placeholder="Выберите категорию"
              :options="toOptions('name', 'id', categories)"
              required
            />

            <!-- ---------- Select Langiuages ---------- -->
            <Select
              name="language"
              v-model="formData.language"
              placeholder="Выберите на каком языке будет проходить курс"
              :options="toOptions('name', 'id', languages)"
              required
            />

            <div class="group">
              <Input
                type="textarea"
                name="description"
                required
                placeholder="Описание"
                v-model="formData.description"
              />
              <p>
                Краткое описание продукта, состоящее из одного предложения. Это
                будет отображаться на странице приветствия продукта.
              </p>
            </div>

            <!-- ---------- Select Tags ---------- -->
            <Select
              name="tags"
              v-model="formData.tags"
              placeholder="Выберите теги к курсу"
              :options="toOptions('name', 'id', tags)"
              multiple
            />

            <div class="course-is-free">
              <input
                type="checkbox"
                id="course-is-free"
                v-model="courseIsFree"
              />
              <label for="course-is-free"></label>
              <label for="course-is-free">Бесплатный курс</label>
            </div>
            <div class="course-cost" v-if="!courseIsFree">
              <Input
                v-if="!courseIsFree"
                type="number"
                name="price"
                placeholder="Стоимость курса"
                v-model="formData.price"
                :validation="
                  /^(([1-9]\d\d|[1-9]\d{3,})[.]?[0-9]+|([1-9]\d\d|[1-9]\d{3,}))/
                "
                errorText="Стоимость не должна быть ниже 100"
              />

              <!-- ---------- Select Currency ---------- -->
              <Select
                name="currency"
                v-model="formData.currency"
                placeholder="Выберите валюту"
                :options="[{label: 'KZT', value: 'KZT'}]"
                required
              />
            </div>
            <Input
              name="duration"
              type="number"
              v-model="formData.duration"
              placeholder="Примерная продолжительность курса (час)"
            />
          </div>
          <div class="footer">
            <span class="title">Эскиз продукта</span>
            <div class="sketch">
              <div class="left">
                <Upload v-model="formData.course_logo" preview />
              </div>
              <div class="right">
                <Button
                  name="Загрузить изображение"
                  type="dark"
                  @click="uploadImage"
                />
                <input
                  type="file"
                  class="upload-file"
                  @change="uploadImageRun"
                  accept="image/png, image/gif, image/jpeg, image/svg"
                />
                <p>
                  Это изображение будет использоваться для демонстрации этого
                  продукта, когда ваши участники будут просматривать свою
                  библиотеку.
                </p>
                <p>Рекомендуемые размеры: 720x720.</p>
              </div>
            </div>
            <div class="buttons">
              <Button name="Отмена" type="cancel" @click="$emit('hide')" />
              <Button
                name="Отправить"
                :loading="loading.saveButton"
                htmlType="submit"
                :ignoreValidate="courseIsFree ? ['price'] : null"
              />
            </div>
          </div>
        </Form>
      </div>
    </Transition>
  </Modal>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import Upload from '@/components/common/Upload.vue'
import Form from '@/components/common/Form.vue'
import Select from '@/components/common/Select/index.vue'

import { onMounted, reactive, ref } from 'vue-demi'

import { useToast } from '@/modules/toast.js'
import { useAuth } from '@/modules/auth'

import toOptions from '@/helpers/toOptions'

import { createNewCourse, editCourseData, getCourseCategories, getTags } from '@/actions/uiBusiness/courses'

const props = defineProps({
  existingCourseData: {
    type: Object,
    default: null
  }
})
const emit = defineEmits(['hide', 'success'])

const { selectedSchool } = useAuth()
const { toast } = useToast()

const formData = ref({
  school_id: selectedSchool.value.id,
  status: 'draft',
  online: true
})
const courseIsFree = ref(false)

const loading = reactive({
  saveButton: false
})

// Options
const categories = ref(null)
const tags = ref(null)
const languages = [
  { name: 'Казахский', id: 0 },
  { name: 'Русский', id: 1 },
  { name: 'Английский', id: 2 }
]

onMounted(async () => {
  if (props.existingCourseData) formData.value = { ...props.existingCourseData }
  if (formData.value.tags) {
    formData.value.tags = formData.value.tags.map(tag => {
      return tag.id
    })
  }

  languages.forEach((lang) => {
    if (lang.name === formData.value.language) formData.value.language = lang.id
  })
  if (formData.value.price === '0.00') courseIsFree.value = true

  const [categoriesRes, tagsRes] = await Promise.all([
    getCourseCategories(),
    getTags()
  ])
  categories.value = categoriesRes.data.sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  tags.value = tagsRes.data
})

const uploadImage = () => {
  document.querySelector('.upload-file').click()
}

const uploadImageRun = (event) => {
  if (event.target.files[0]) formData.value.course_logo = event.target.files[0]
}

const filterData = (courseData) => {
  const formData = { ...courseData }
  if (!formData.grade_type_id) delete formData.grade_type_id
  if (!formData.certificate_fields) delete formData.certificate_fields
  if (courseIsFree.value) formData.price = '0.00'
  if (formData.language !== null) formData.language = String(formData.language)
  return formData
}

// ----------- APIs - Create And Edit Course -------------
const sendCourseData = () => {
  loading.saveButton = true
  if (props.existingCourseData) editCourse()
  else createCourse()
}

const createCourse = async () => {
  try {
    await createNewCourse(filterData(formData.value))

    toast({ message: 'Курс успешно создан', type: 'success' })
    setTimeout(() => {
      toast({
        message:
          'Ваш курс отобразится на маркетплейсе курсов, как только вы опубликуете уроки к курсу'
      })
    }, 700)

    emit('success')
    emit('hide')
  } finally {
    loading.saveButton = false
  }
}

const editCourse = async () => {
  try {
    await editCourseData(props.existingCourseData.id, filterData(formData.value))
    toast({ message: 'Данные курса обновлены', type: 'success' })
    emit('success')
    emit('hide')
  } finally {
    loading.saveButton = false
  }
}
</script>

<style scoped>
.modal {
  display: flex;
  flex-direction: column;
}

.modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal .header .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  margin-bottom: 25px;
}

.modal .header svg {
  cursor: pointer;
}

.modal p {
  font-size: 18px;
  margin-bottom: 20px;
}

.modal .cards {
  display: flex;
  gap: 30px;
}

.modal .cards .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  padding: 25px;
  border: 1px solid #161931;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal .cards .card:hover {
  background: #f1f3f4;
}

.modal .cards .card .title {
  font-weight: bold;
  font-size: 20px;
  color: #161931;
  margin-bottom: 15px;
}

.modal .cards .card:hover button {
  gap: 20px;
}

.modal .cards .card .avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.modal .cards .card .description {
  margin-bottom: 40px;
}

.modal .cards .card button {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all 0.3s ease;
}

.modal .cards .card button span {
  color: #0082fa;
}

.online-course {
  width: 530px;
}

.online-course .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.online-course .header .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.online-course .header .left .avatar {
  max-height: 40px;
  max-width: 40px;
}

.online-course .header .left span {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
}

.online-course .header svg {
  cursor: pointer;
}

.online-course .body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.online-course .body input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: rgba(22, 25, 49, 0.5);
}

.online-course .body input {
  border: 1px solid #161931;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 16px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #161931;
  margin-bottom: 10px;
}

.online-course .body p {
  font-weight: 400;
  font-size: 12px;
  color: #161931;
}

.course-cost {
  display: flex;
  gap: 20px;
}

.course-cost input {
  margin: 0 !important;
}

.online-course .footer .title {
  font-size: 14px;
  color: #161931;
}

.online-course .footer .sketch {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  margin-bottom: 20px;
}

.online-course .footer .sketch .left {
  border: 1px solid #161931;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 225px;
  width: 225px;
  position: relative;
}

.online-course .footer .sketch .left .default-image {
  height: 150px;
  width: 150px;
}

.online-course .footer .sketch .left img {
  height: 225px;
  width: 225px;
  object-fit: cover;
}

.online-course .footer .sketch .left .delete-image {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 1;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.online-course .footer .sketch .right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.online-course .footer .sketch .right button {
  width: fit-content;
}

.online-course .footer .sketch .right p {
  font-weight: 400;
  font-size: 12px;
  color: #161931;
}

.online-course .footer .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.course-is-free {
  display: flex;
}

.course-is-free input[type='checkbox'] {
  width: fit-content;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.course-is-free input[type='checkbox'] + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.course-is-free input[type='checkbox'] + label::before {
  width: 18px;
  height: 18px;
  content: '';
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  margin-right: 0.5em;
  transition-duration: 0.2s;
  cursor: pointer;
}

.course-is-free input[type='checkbox']:checked + label::before {
  transition-duration: 0.2s;
  border-color: #161931;
  background: #161931;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.upload-file {
  display: none;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
