<template>
  <div class="wrapper-system">
    <section class="homeworks-table">
      <div class="course-homeworks">
        <div class="course">
          <span class="course-name">Знакомство с Instagram</span>
          <span class="course-category">Веб-Дизайн</span>
        </div>
        <div class="homeworks--inner">
          <div class="row" v-for="valu of 10" :key="valu" v-wave="{ duration: 0.6 }" @click="go(valu)">

            <div class="left">
              <!-- <img src="/img/tmp/avatar.png" alt="" class="course-avatar"> -->
              <div class="course-data">
                <b class="homework-name">
                  Имя урока
                </b>
                <span class="lesson-name">
                  <SvgIcon name="document" :viewboxHeight="18" :viewboxWidth="18" :width="18" :height="18"/>
                  Отрисовка человека
                </span>
              </div>
            </div>

            <div class="right">
              <!-- <div class="status">
                <span class="date-completed" v-if="value">
                  12.07.2021
                </span>
                <span class="date-not-completed" v-else>
                  12.07.2021
                </span>
                <span class="deadline-text">
                  Deadline
                </span>
                <div class="completed" v-if="value">
                  <SvgIcon name="check-fill"  :viewboxHeight="16" :viewboxWidth="16" :width="16" :height="16" fill="#00DB7F"/>
                  <span>Сделано</span>
                </div>
                <div class="not completed" v-else>
                  <SvgIcon name="check-fill"  :viewboxHeight="16" :viewboxWidth="16" :width="16" :height="16" fill="#16193133"/>
                  <span>Не сделано</span>
                </div>
              </div> -->
              <b class="score">
                <span>80</span>/100
              </b>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
const go = () => {
  console.log(null)
}
</script>
<style scoped>
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
h1 span {
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #161931;
}

.homeworks-table {
  display: grid;
  row-gap: 10px;
}
.course-homeworks .course {
  display: flex;
  gap: 30px;
  align-items: baseline;
  margin-bottom: 15px;
  padding-left: 15px;
}

.course-homeworks .course .course-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.course-homeworks .course .course-category {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--accent-color);
}

.homeworks--inner {
  border: 0.5px solid #161931;
  border-radius: 10px;
  overflow: hidden;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 10px 30px 10px 20px;
  cursor: pointer;
  position: relative;
}
.row:hover {
  background: var(--hover-color);
}
.row .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
/* .row .left img {
  width: 63px;
  height: 63px;
  object-fit: cover;
  border-radius: 10px;
} */
.row .left .course-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.row .left .course-data .homework-name {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  display: flex;
  gap: 30px;
  align-items: baseline;
}
.row .left .course-data .homework-name .course-category {
  font-size: 16px;
  line-height: 19px;
  color: var(--accent-color);
  font-weight: 600;
}
.row .left .course-data .lesson-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-color);
  display: flex;
  gap: 10px;
}

.row .right {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 50px;
}
/* .row .right .deadline {
  display: flex;
  flex-direction: column;
  gap: 15px;
} */
.row .right .status {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  column-gap: 10px;
  row-gap: 15px;
}
.row .right .status .deadline-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #C5C7D1;
  grid-column-start: 1;
  grid-row-start: 1;
}
.row .right .status .date-not-completed {
  padding: 5px 13px;
  background: #FA7171;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  display: block;
}
.row .right .status .date-completed {
  padding: 5px 13px;
  border-radius: 3px;
  background: #0082facc;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  display: block;
}
.row .right .completed {
  display: flex;
  align-items: center;
  gap: 5px;
  grid-column-start: 2;
  grid-row-start: 2;
}
.row .right .completed span {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #00DB7F
}
.row .right .not.completed span {
  color: #16193180;
}
.row .right .score {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #1619314d;
}

.row hr {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #1619310d;
  width: calc(100% - 50px);
  margin: 0 20px;
}
</style>
