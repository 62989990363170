<template>
  <Sidebar :data="data" :empty="empty">
    <router-view/>
    <Transition>
      <div class="empty" v-if="empty">
        <img src="/img/tmp/not_courses.svg" alt="">
        <span class="empty-title">
          Похоже, у этого курса еще нет уроков
        </span>
      </div>
    </Transition>
  </Sidebar>
</template>

<script setup>
import Sidebar from '@/components/uiLearning/layouts/Sidebar.vue'
import { onMounted, provide, reactive, ref, toRef, watch } from 'vue-demi'
import { useRoute } from 'vue-router'

import { useAuth } from '@/modules/auth'
import { useCourseProgress, useNextLesson } from '@/modules/learning.js'

import { getCourseLessons } from '@/actions/uiLearning/lesson'
import { getUserTakes } from '@/actions/uiLearning/take'
import { getCourse } from '@/actions/uiBusiness/courses'

import checkFullAccess from '@/helpers/checkFullAccess.js'

const { getFinishedLessons, setFinishedLessons } = useCourseProgress()
const { setNextLesson } = useNextLesson()
const { user } = useAuth()
const route = useRoute()

const empty = ref(false)
const data = reactive({
  course_data: {},
  lessons_data: [],
  takes_data: [],
  finished_lessons_count: () => getFinishedLessons()
})

provide('courseData', toRef(data, 'course_data'))

onMounted(async () => {
  data.course_data = (await getCourse(route.params.course_id)).data
  const fullAccess = checkFullAccess(route.params.course_id, user.value.data)

  data.lessons_data = await getCourseLessons(route.params.course_id, fullAccess)
  if (user.value.data?.id) {
    data.takes_data = await getUserTakes(user.value.data?.id)
  }
  setFinishedLessons(route.params.course_id)
  setNextLesson(Number(route.params.homework_id), data.lessons_data)
  empty.value = data.lessons_data.length === 0
})

watch(
  () => user.value,
  async () => {
    data.takes_data = await getUserTakes(user.value.data.id)
  },
  {
    deep: true
  }
)

watch(
  () => route.params.course_id,
  () => {
    setNextLesson(Number(route.params.homework_id), data.lessons_data)
  }
)
</script>

<style>
/* Если пусто */
.empty {
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.empty img {
  margin-bottom: 40px;
}
.empty-title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  margin-bottom: 20px;
}

.v-enter-active,
.v-leave-active {
  transition: all .2s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
