<template>
  <div>
    <div class="wrapper-system" v-if="!isEmpty">
      <h1>
      <span>Список заданий</span>
    </h1>

      <section class="homeworks-table" v-if="data.length > 0">
        <div class="course-homeworks" v-for="course of data" :key="course">
          <div
            class="course"
            v-wave
            :class="{ expanded: isExpanded(course.course.id) }"
            @click="toggleHomeworks(course.course.id)"
          >
            <span class="course-name">{{ course.course.name }}</span>
            <span class="course-category">{{
              course.course.category.name
            }}</span>
          </div>
          <Transition>
            <div
              class="homeworks--inner"
              v-collapse
              v-if="isExpanded(course.course.id)"
            >
              <div
                v-for="homework of course.homeworks"
                :key="homework"
                v-wave="{ duration: 0.6 }"
              >
                <router-link
                  class="row"
                  :to="
                    '/lesson/' + course.course.id + '/homework/' + homework.id
                  "
                >
                  <div class="left">
                    <!-- <img src="/img/tmp/avatar.png" alt="" class="course-avatar"> -->
                    <div class="course-data">
                      <b class="homework-name">
                        {{ homework.name }}
                      </b>
                      <span class="lesson-name">
                        <SvgIcon
                          name="document"
                          :viewboxHeight="18"
                          :viewboxWidth="18"
                          :width="18"
                          :height="18"
                        />
                        {{ homework.lesson.name }}
                      </span>
                    </div>
                  </div>
                  <div class="right">
                    <div class="status">
                      <span
                        :class="
                          isExpired(homework.deadline_at)
                            ? 'date-completed'
                            : 'date-not-completed'
                        "
                      >
                        {{
                          moment(homework.deadline_at).format(
                            'DD.MM.YYYY, hh:mm'
                          )
                        }}
                      </span>
                      <span class="deadline-text"> Deadline </span>
                      <div class="completed" v-if="!!homework.finished">
                        <SvgIcon
                          name="check-fill"
                          :viewboxHeight="16"
                          :viewboxWidth="16"
                          :width="16"
                          :height="16"
                          fill="#00DB7F"
                        />
                        <span>Сделано</span>
                      </div>
                      <div class="not completed" v-else>
                        <SvgIcon
                          name="check-fill"
                          :viewboxHeight="16"
                          :viewboxWidth="16"
                          :width="16"
                          :height="16"
                          fill="#16193133"
                        />
                        <span>Не сделано</span>
                      </div>
                    </div>
                    <b class="score">
                      <span v-if="homework.grade">{{ homework.grade }}</span>
                      <b class="score" v-else>0</b>/100
                    </b>
                  </div>
                </router-link>
                <hr />
              </div>
            </div>
          </Transition>
        </div>
      </section>

      <section class="homeworks-table disabled" v-else>
        <div class="course-homeworks">
          <div class="course">
            <span class="course-name skeleton middle-text"></span>
            <span class="course-category skeleton small-text"></span>
          </div>
          <div class="homeworks--inner">
            <div
              class="row"
              v-for="valu of 15"
              :key="valu"
              v-wave="{ duration: 0.6 }"
              @click="go(valu)"
            >
              <div class="left">
                <div class="course-data">
                  <b class="homework-name skeleton small-text"> </b>
                  <span class="lesson-name skeleton middle-text"> </span>
                </div>
              </div>

              <div class="right">
                <div class="status">
                  <span class="date-completed skeleton small-text"> </span>
                  <span class="deadline-text skeleton small-text"> </span>
                  <div class="completed skeleton small-text"></div>
                </div>
                <b class="score skeleton middle-text"> </b>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="not_courses" v-else>
      <img class="preview" src="/img/tmp/not_courses.svg" alt="" />
      <h2 class="title">Похоже, у вас нет пока курсов</h2>
      <p class="subTitle">
        Люди будут появляться здесь, когда они присоединяются к вашему списку,
        проходят оценку и покупают Продукты.<br />
        Для наилучшего восприятия не забудьте пометить своих участников.
      </p>
      <router-link class="btn" v-wave to="/courses/"
        ><span>приобрести курс</span
        ><SvgIcon
          name="arrow-right"
          :viewboxHeight="32"
          :viewboxWidth="32"
          :height="32"
          :width="32"
      /></router-link>
    </div>
    <router-view />
  </div>
</template>

<script setup>
import { ref } from 'vue-demi'
import { useRouter } from 'vue-router'
import api from '@/modules/api'
import moment from 'moment'

const router = useRouter()
const data = ref([])
const expandedHomeworks = ref([])
const isEmpty = ref(false)

const go = (item) => {
  router.push(`/learning/homeworks/${item}`)
}

api.requestGet('/api/student/homework', {}, (response) => {
  const homeworks = response.data.data

  data.value = homeworks.reduce((acc, homework) => {
    if (
      acc.findIndex(
        (value) => value.course.id === homework.lesson.course.id
      ) === -1
    ) {
      return [...acc, {
        course: homework.lesson.course,
        homeworks: [homework]
      }]
    } else {
      const index = acc.findIndex(element => element.course.id === homework.lesson.course.id)
      acc[index].homeworks.push(homework)
      return acc
    }
  }, [])

  if (data.value.length === 0) isEmpty.value = true
})

const isExpired = (date) => {
  const now = new Date()
  const deadline = new Date(date)
  return now < deadline
}

const isExpanded = (courseId) => expandedHomeworks.value.includes(courseId)

const toggleHomeworks = (courseId) => {
  const arr = expandedHomeworks.value
  if (arr.includes(courseId)) {
    expandedHomeworks.value = arr.filter((course) => course !== courseId)
  } else expandedHomeworks.value = [...arr, courseId]
}
</script>

<style scoped>
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
h1 span {
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #161931;
}

.homeworks-table {
  display: grid;
  row-gap: 10px;
}
.course-homeworks {
  margin-bottom: 30px;
}
.course-homeworks .course {
  display: flex;
  gap: 30px;
  margin-bottom: 15px;
  align-items: baseline;
  padding: 10px 15px;
  background: #f1f3f4;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}
.course-homeworks .course::after {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 17.4969L12.0029 17.4998L21 8.57119L19.9203 7.49976L12 15.3598L4.07965 7.49976L3 8.57118L11.9971 17.4998L12 17.4969Z' fill='black'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease;
  transform: translateY(-50%) rotate(-90deg);
  cursor: pointer;
}
.course-homeworks .course.expanded::after {
  transform: translateY(-50%) rotate(0);
}
.course-homeworks .course .course-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.course-homeworks .course .course-category {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--accent-color);
}

.homeworks--inner {
  border: 0.5px solid #161931;
  border-radius: 10px;
  overflow: hidden;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 10px 30px 10px 20px;
  cursor: pointer;
  position: relative;
}
.row:hover {
  background: var(--hover-color);
}
.row .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
/* .row .left img {
  width: 63px;
  height: 63px;
  object-fit: cover;
  border-radius: 10px;
} */
.row .left .course-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.row .left .course-data .homework-name {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  display: flex;
  gap: 30px;
  align-items: baseline;
}
.row .left .course-data .homework-name .course-category {
  font-size: 16px;
  line-height: 19px;
  color: var(--accent-color);
  font-weight: 600;
}
.row .left .course-data .lesson-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-color);
  display: flex;
  gap: 10px;
}

.row .right {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 50px;
}
/* .row .right .deadline {
  display: flex;
  flex-direction: column;
  gap: 15px;
} */
.row .right .status {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  column-gap: 10px;
  row-gap: 15px;
}
.row .right .status .deadline-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #c5c7d1;
  grid-column-start: 1;
  grid-row-start: 1;
}
.row .right .status .date-not-completed {
  padding: 5px 13px;
  background: #fa7171;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  display: block;
}
.row .right .status .date-completed {
  padding: 5px 13px;
  border-radius: 3px;
  background: #0082facc;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  display: block;
}
.row .right .completed {
  display: flex;
  align-items: center;
  gap: 5px;
  grid-column-start: 2;
  grid-row-start: 2;
}
.row .right .completed span {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #00db7f;
}
.row .right .not.completed span {
  color: #16193180;
}
.row .right .score {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #1619314d;
}

.row hr {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #1619310d;
  width: calc(100% - 50px);
  margin: 0 20px;
}
/* SKELETON */
.disabled {
  pointer-events: none;
}

/* No Courses */
.not_courses {
  min-height: 78vh;
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.not_courses .preview {
  display: block;
  height: 160px;
  width: 160px;
  margin-bottom: 40px;
}
.not_courses .title {
  color: #161931;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.not_courses .subTitle {
  color: #000000;
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 20px;
}
.not_courses .btn {
  display: inline-flex;
  align-items: center;
  padding: 9px 17px;
  border: 2px solid #0082fa;
  border-radius: 3px;
  background-color: #0082fa;
  transition: background-color 0.2s linear;
}
.not_courses .btn span {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 17px;
  transition: color 0.2s linear;
}
.not_courses .btn svg {
  display: block;
  height: 32px;
  width: 32px;
  fill: #fff;
  transition: transform 0.2s linear, fill 0.2s linear;
}
.not_courses .btn:hover {
  background-color: #fff;
}
.not_courses .btn:hover span {
  color: #0082fa;
}
.not_courses .btn:hover svg {
  fill: #0082fa;
  transform: translateX(7px);
}
</style>
