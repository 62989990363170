<template>
  <div class="wrapper-system">
    <button class="back" @click="$router.go(-1)">
      <SvgIcon name="back" :viewboxHeight="18" :viewboxWidth="18" :height="18" :width="18"/>
      <span>Новости</span>
    </button>
    <h1>
      <span class="title" v-if="formData.name">{{ formData.name }}</span>
      <span class="title skeleton long-text" v-else></span>
      <div class="buttons" v-if="!formData.name">
        <Button name="Редактировать домашнее задание" type="dark" @click="editHomework" shadowed>
          <template v-slot:left >
              <SvgIcon name="homework" :width="16" :height="16" style="fill: white;" />
            </template>
        </Button>
        <Button @click="null" :disabled="handleError" :loading="loading" name="сохранить" shadowed />
      </div>
      <div class="buttons disabled" v-else>
          <Button name="Редактировать домашнее задание" type="dark" class="skeleton">
            <template v-slot:left >
              <SvgIcon name="" :viewboxHeight="16" :viewboxWidth="24" :width="24" :height="16" style="fill: white;" />
            </template>
          </Button>
        <Button name="Создать домашнее задание" class="skeleton" />
      </div>
    </h1>
    <div class="edit">
      <div class="left">
        <span class="title">Детали</span>
        <div class="group">
          <span class="name">Заголовок</span>
          <div>
            <Input
              class="group--input"
              v-model="formData.name"
              placeholder="Введите заголовок новости"
              staticPlaceholder
              required
              type="textarea"
              @error="hasError.name = true"
              @success="hasError.name = false"
            />
            <p class="description">Выберите легко читаемый и запоминающийся заголовок для вашеей новости. Пример: «Present Simple» или «Портретная съемка». Не более 140 символов.</p>
          </div>
        </div>
        <div class="group">
          <span class="name">Подзаголовок</span>
          <div>
            <Input
              class="group--input"
              v-model="formData.description"
              placeholder="Введите краткое описание новости"
              staticPlaceholder
              required
              type="textarea"
              @error="hasError.description = true"
              @success="hasError.description = false"
            />
            <p class="description">Краткое описание урока, состоящее из одного предложения. Это будет отображаться на посадочной странице курса.</p>
          </div>
        </div>

        <span class="title">Фотографии и видео к новостям</span>
        <div class="btns">
          <div class="uploads">
            <span>Загрузить изображение</span>
          </div>
          <button class="add-file">
            <SvgIcon name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" style="fill: #0082FA;" />
            <input type="file" id="upload-lesson-files" multiple style="display: none" @change="uploadFiles">
            <label for="upload-lesson-files">Добавить файлы</label>
          </button>
        </div>

        <span class="title">Текст</span>
         <Input
          type="editor"
          v-model="formData.content"
          staticPlaceholder
          required
          placeholder="Введите контент урока"
          @error="hasError.content = true"
          @success="hasError.content = false"
        />
      </div>
      <div class="right">
        <section class="uploads">
          <span class="title">Загрузки</span>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from '@vue/reactivity'
import { reactive, ref } from 'vue'

import { useRoute } from 'vue-router'

const route = useRoute()
const formData = ref({
  name: null,
  description: null,
  content: null,
  course_id: Number(route.params.course_id),
  status: 'draft',
  lesson_files: []
})

const loading = ref(false)
const hasError = reactive({
  name: true,
  description: true,
  content: true
})
const handleError = computed(() => {
  for (const field in hasError) {
    if (hasError[field]) return true
  }
  return false
})
</script>

<style scoped>
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all .3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 20px;
}
h1 .buttons {
  display: flex;
  gap: 10px;
}

p.description{
  font-weight: 400;
  font-size: 12px;
  color: #161931;
}
.edit {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 20px;
  width: calc(100% - 20px);
}
.edit .left {
  background: #FFFFFF;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 40px;
}
.edit .left .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
}
.edit .left .group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.edit .left .group .group--input{
  margin-bottom: 8px;
}
.edit .left input {
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 16px;
}
.edit .left input:focus {
  border: 1px solid var(--accent-color);

}
.edit .left .upload {
  display: flex;
  gap: 20px;
}
.edit .left .upload button {
  padding: 13px 30px;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all .3s ease;
}
.edit .left .upload button svg {
  transition: all .3s ease;
  fill: black;
}
.edit .left .upload button span {
  transition: all .3s ease;
  font-size: 16px;
}
.edit .left .upload button:hover {
  border: 1px solid #0082FA;
}
.edit .left .upload button:hover svg {
  fill: #0082FA;
}
.edit .left .upload button:hover span {
  color: #0082FA;
}
.edit .right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.edit .right .avatar {
  background: #FFFFFF;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
}
.edit .right .avatar .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 20px;
}
.edit .right .avatar .image {
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
.edit .right .avatar .image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.edit .right .avatar .image .default-img {
  height: 150px;
}
.edit .right .avatar p {
  font-weight: 400;
  font-size: 12px;
  color: #161931;
  margin-bottom: 10px;
}
.edit .right .avatar .buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.edit .right .avatar .buttons button {
  font-weight: 500;
  font-size: 14px;
  color: #FF5656;
}
.tip {
  padding-top: 10px;
  color: rgba(22, 25, 49, 0.8);
  font-size: 12px;
}
.edit .right .statuses {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
}
.edit .right .statuses .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 20px;
}
.edit .right .statuses .group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.edit .right .statuses .status {
  border-radius: 20px;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all .3s ease;
  position: relative;
}
.edit .right .status.published {
  background: rgba(0, 219, 127, 0.15);
}
.edit .right .status.published svg {
  fill: #00854D;
}
.edit .right .status.published span {
  color: #00854D;
}
.edit .right .status.draft {
  background: rgba(22, 25, 49, 0.15);
}
.edit .right .status.draft svg {
  fill: rgba(22, 25, 49, 0.8);
}
.edit .right .status.draft span{
  color: rgba(22, 25, 49, 0.8);
}
.edit .delete-lesson {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
.edit .delete-lesson span {
  color: #FF5656;
}
.edit .delete-lesson svg {
  fill: #FF5656;
}

.upload-file {
  display: none;
}
.delete-image {
  padding: 5px 10px;
  border-radius: 3px;
}

.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.modal .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
}
.modal .buttons {
  display: flex;
  gap: 20px;
}

.modal-enter-active,
.modal-leave-active {
  transition: all .3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.disabled {
  pointer-events: none;
}
section.uploads {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
}
section.uploads .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 10px;
}
section.uploads .uploads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 10px;
}
section.uploads .uploads .file {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 60%;
}
section.uploads .uploads .file img {
  height: 24px;
  width: 24px;
}
section.uploads .uploads span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  line-height: 1em;
  height: 1em;
  white-space: pre;
}
section.uploads .actions {
  display: flex;
  gap: 15px;
}
section.uploads .actions svg {
  cursor: pointer;
  transition: all .3s ease;
}
section.uploads .actions svg:hover {
  fill: #0082FA;
}
section.uploads .actions .x:hover {
  fill: #FF5656;;
}
.uploads .add-file {
  width: fit-content;
  display: flex;
  gap: 7px;
  align-items: center;
}
.uploads .add-file label {
  transition: all .4s ease;
  color: #0082FA;
  cursor: pointer;
}
.uploads .add-file:hover label {
  transform: translateX(5px);
}
.uploads .add-file svg {
  fill: #0082FA;
  transition: all .4s ease;
}
.uploads .add-file:hover svg {
  transform: rotate(90deg);
}
</style>
