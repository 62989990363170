<template>
  <div>
    <div class="wrapper-system">
      <button class="back" @click="back">
        <SvgIcon
          name="back"
          :viewboxHeight="18"
          :viewboxWidth="18"
          :height="18"
          :width="18"
        />
        <span>Уроки</span>
      </button>
      <div class="table-wrapper">
        <h1>
          <span class="title">Итоговый экзамен</span>
          <Button name="ДОБАВИТЬ время">
            <template v-slot:left >
              <SvgIcon name="time" :viewboxHeight="18" :viewboxWidth="18" :width="18" :height="18" fill="#fff" style="margin-right: 10px;"/>
            </template>
          </Button>
        </h1>
        <div class="tests-table">
          <Transition>
            <div class="message" v-if="showMessage" v-collapse>
              <SvgIcon name="exclamation-mark" :viewboxHeight="32" :viewboxWidth="32" :width="32" :height="32"/>
              <div class="text">Итоговая оценка за экзамен будет считаться автоматический, в зависимости от правильных ответов указанными преподавателем при создании вопроса. При создании вопроса преподаватель должен указать балл за вопрос.</div>
              <button class="close" @click="() => {showMessage = false}">
                <SvgIcon name="x" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" fill="#000"/>
              </button>
            </div>
          </Transition>
          <div class="exam-input">
            <span class="name">Название</span>
            <Input
              v-model="formData.name"
              placeholder="Введите название экзамена"
              staticPlaceholder
              required
            />
          </div>

          <!-- ============ Tasks ============ -->
          <div class="test-tasks">
            <div v-for="(task, i) of formData.tasks" :key="task">
              <Transition>
                <div class="test-task" v-collapse>
                  <div class="title">
                    <h3>
                      <span>Тест-вопрос {{i + 1}}</span>
                      <button v-if="formData.tasks.length > 1" class="delete-task" @click="deleteTask(i)" v-wave>
                        <SvgIcon name='x' fill="#FF5656" :viewboxHeight="14" :viewboxWidth="14" :height="14" :width="14" />
                      </button>
                    </h3>
                    <div class="test-task-input">
                      <CKEditor v-model="task.question"/>
                    </div>
                    <!-- <div class="uploads">
                      <button class="add-image">
                        <SvgIcon name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" style="fill: #0082FA;" />
                        <input type="file" id="upload-lesson-files" multiple style="display: none" @change="uploadFiles">
                        <label for="upload-lesson-files">Добавить файлы</label>
                      </button>
                    </div> -->
                  </div>

                  <!-- ============ Answers ============ -->
                  <div class="test-answers">
                    <div v-for="(answer, j) in task.answers" :key="answer">
                      <Transition>
                        <div class="test-answer" v-collapse>
                          <div class="checkbox">
                            <input
                              type="radio"
                              :name="`status--${i+1}-${j+1}`"
                              :id="`answer--${i+1}-${j+1}`"
                              :value="answer.is_correct"
                              :checked="answer.is_correct === 1"
                              @input="event => clickChecbox(i, j)"
                            >
                            <label :for="`answer--${i+1}-${j+1}`">{{toLetters(j + 1)}}</label>
                          </div>
                          <input
                            v-model="answer.answer"
                            type="text"
                            :class="`test-answer-text answer-input--${i+1}-${j+1}`"
                            @keyup.enter="j < 10 ? addAnswerOnEnter(i, j) : null"
                            placeholder="Введите текст"
                            required
                          />
                          <button v-if="j < 10 && j + 1 === task.answers.length" @click="addAnswer(i)" class="add" type="button">
                            <SvgIcon name="plus" fill="#fff" :viewboxHeight="14" :viewboxWidth="14" :width="18" :height="18"/>
                          </button>
                          <button v-else @click="deleteAnswer(i, j)" class="delete" type="button">
                            <SvgIcon name="minus" fill="#fff" :viewboxHeight="4" :viewboxWidth="32" :width="18" :height="18"/>
                          </button>
                        </div>
                      </Transition>
                    </div>
                  </div>

                  <!-- ============ Test Point ============ -->
                  <div class="exam-input">
                    <span class="name">Балл за вопрос</span>
                    <select class="select-point" name="1" id="">
                      <option
                        v-for="point of 10"
                        :key="point"
                        :value="point"
                        @click="() => {task.grade = point}"
                      >
                          {{point}}
                      </option>
                    </select>
                  </div>
                </div>
              </Transition>
            </div>
          </div>

          <!-- ============ Button Add Task ============ -->
          <Button
            @click="addTask"
            type="dark"
            size="large"
            :icon="{
              name: 'list',
              viewboxHeight: 16,
              viewboxWidth: 16,
              width: 16,
              height: 16,
              fill: 'white'
            }"
            :style="{
              marginBottom: '25px'
            }"
          >Добавить тест-вопросы</Button>

          <!-- ============ Test Point ============ -->
          <div class="exam-input" v-if="formData.tasks.length >= 10">
            <span class="name">Количество вопросов в тесте</span>
            <select class="select-point" name="10" id="">
              <option v-for="point of 20" :key="point" :value="point">{{point}}</option>
            </select>
          </div>

          <!-- ============ Send or Cancel ============ -->
          <div class="buttons">
            <Button name="Отмена" @click="back" type="cancel"/>
            <Button name="СОХРАНИТЬ" @click="createExam" :loading="loading"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CKEditor from '@/components/common/CKEditor.vue'

import { reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useToast } from '@/modules/toast'
import { postExam } from '@/actions/uiBusiness/exams'

const router = useRouter()
const route = useRoute()
const { toast } = useToast()
const formData = reactive(
  {
    name: '',
    lesson_id: route.params.exam_id,
    type: 'exam',
    time_limit: null,
    tasks_num: 5,
    deadline_at: null,
    tasks: [
      {
        question: 'what is app',
        type: 'test',
        task_file: '',
        grade: 1,
        answers: [
          {
            answer: '',
            is_correct: 1
          }
        ]
      }
    ]
  }
)
const loading = ref(false)
const showMessage = ref(true)
const toLetters = (num) => {
  const mod = num % 26
  let pow = num / 26 | 0
  const out = mod ? String.fromCharCode(64 + mod) : (--pow)
  return pow ? toLetters(pow) + out : out
}

const back = () => {
  router.go(-1)
}

// Checbox Event
const clickChecbox = (i, j) => {
  formData.tasks[i].answers.forEach(answer => {
    answer.is_correct = 0
  })
  formData.tasks[i].answers[j].is_correct = 1
}

// ========== Add Task ===========
const addTask = () => {
  formData.tasks.push(
    {
      question: '',
      type: 'test',
      task_file: '',
      answers: [
        {
          answer: '',
          is_correct: 1
        }
      ]
    }
  )
}
const deleteTask = (i) => {
  formData.tasks.splice(i, 1)
}

// ========== Add Answer ===========
const addAnswer = (i) => {
  formData.tasks[i].answers.push(
    {
      answer: '',
      is_correct: 0
    }
  )
}
const addAnswerOnEnter = (i) => {
  formData.tasks[i].answers.push(
    {
      answer: '',
      is_correct: 0
    }
  )
  setTimeout(() => {
    const nextInput = document.querySelector(`.answer-input--${i + 1}-${formData.tasks[i].answers.length}`)
    nextInput.focus()
  })
}
const deleteAnswer = (i, j) => {
  if (formData.tasks[i].answers[j].is_correct === 1) {
    formData.tasks[i].answers.splice(j, 1)
    formData.tasks[i].answers[0].is_correct = 1
  } else {
    formData.tasks[i].answers.splice(j, 1)
  }
}

// ========== API Post Final Exam ===========
const createExam = async () => {
  loading.value = true
  try {
    await postExam(formData)
    toast({
      message: 'Экзамен успешно создан',
      type: 'success'
    })
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
/* -------------- Если пусто  ------------ */

.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all 0.3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 20px;
}
.table-wrapper {
  margin-bottom: 250px;
}

/* -------------- Test Table  ------------ */
.tests-table {
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  margin-bottom: 15px;
}
.tests-table .buttons{
  display: flex;
  grid-gap: 20px;
}
.tests-table .buttons button{
  height: 50px;
}
.tests-table > .exam-input{
  margin-bottom: 25px;
}

/* -------------- message  ------------ */
.message {
  display: grid;
  grid-template-columns: 32px 1fr 14px;
  align-items: center;
  grid-gap: 10px;

  border-radius: 4px;
  border: 1px solid rgba(0, 130, 250, 0.5);
  background: rgba(0, 130, 250, 0.2);

  padding: 14px 22px 14px 20px;
  margin-bottom: 20px;
}
.message .text{
  max-width: 1200px;
  font-size: 16px;
  color: #000000;
  position: relative;
  z-index: 2;
}
.message .close{
  transition: all .2s linear;
}
.message .close:hover{
  transform: rotate(90deg);
}

/* -------------- Test Inputs  ------------ */
.exam-input .name{
  display: block;
  font-size: 14px;
  color: #161931;
  margin-bottom: 5px;
}

/* -------------- Tasks  ------------ */
.test-tasks > div{
  margin-bottom: 45px;
}
.test-tasks > div:last-child{
  margin-bottom: 30px;
}
.test-task h3{
  display: flex;
  align-items: center;
  grid-gap: 15px;
  margin-bottom: 20px;
}
.test-task .delete-task {
  padding: 3px;
  border-radius: 2px;
}
.test-task h3 span{
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: #161931;
}
.test-task .test-task-input{
  margin-bottom: 20px;
}
.test-task .title{
  margin-bottom: 20px;
}

/* -------------- Upload Img  ------------ */
.uploads .add-image {
  width: fit-content;
  display: flex;
  gap: 7px;
  align-items: center;
}
.uploads .add-image label {
  transition: all .4s ease;
  color: #0082FA;
  cursor: pointer;
}
.uploads .add-image:hover label {
  transform: translateX(5px);
}
.uploads .add-image svg {
  fill: #0082FA;
  transition: all .4s ease;
}
.uploads .add-image:hover svg {
  transform: rotate(90deg);
}

/* -------------- answers  ------------ */
.test-answers{
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  margin-bottom: 18px;
}
.test-answer{
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.test-answer .input{
  transform: translateY(6px);
}
.test-answer .checkbox{
  display: flex;
  align-items: center;
  grid-gap: 9px;
}
.test-answer .checkbox label{
  font-weight: 500;
  font-size: 20px;
  color: #161931;
}
.test-answer-text{
  width: 100%;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 16px;
}
.test-answer .add,
.test-answer .delete{
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0082FA;
  border-radius: 100px;
}
.test-answer .delete{
  background-color: #EC625D;
}

/* -------------- Select  ------------ */
.select-point{
  width: 200px;
  border: 1px solid #161931;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 17px;
  font-weight: 500;
  font-size: 14px;
  color: #161931;
}

/* --------------  Transition  ---------------- */
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
