<template>
  <div :class="'sidebar '+sidebarState">
    <div class="logo-details">
      <router-link to="/" class="logo-back">
        <SvgIcon class="back" name="chevron-left" :viewboxWidth="24" :viewboxHeight="40" :width="20" :height="20" />
        <span class="logo-name">A Class Online</span>
      </router-link>
      <SvgIcon name="burger" class="toggle-menu" :viewboxWidth="40" @click="toggleSidebar" />
    </div>
    <nav>
      <ul class="nav-links">
        <li>
          <router-link to="/learning/my-courses">
            <SvgIcon name="book" class="icon"/>
            <span class="link-name">Курсы</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/my-courses">Курсы</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/learning/lessons">
            <SvgIcon name="product" />
            <span class="link-name">Уроки</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/lessons">Уроки</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/learning/schedule">
            <SvgIcon name="schedule" />
            <span class="link-name">Расписание</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/schedule">Расписание</router-link></li>
          </ul>
        </li>
        <!-- <li>
          <router-link to="/learning/grades">
            <SvgIcon name="grades" />
            <span class="link-name">Оценки </span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/grades">Оценки</router-link></li>
          </ul>
        </li> -->
        <li>
          <router-link to="/learning/homeworks">
            <SvgIcon name="homework" />
            <span class="link-name">Домашнее задание</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/homeworks">Домашнее задание</router-link></li>
          </ul>
        </li>
        <!-- <li>
          <router-link to="/learning/chat">
            <SvgIcon name="chat" />
            <span class="link-name">Чат</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/chat">Чат</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/learning/forum">
            <SvgIcon name="forum" />
            <span class="link-name">Форум</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/forum">Форум</router-link></li>
          </ul>
        </li> -->
        <li>
          <router-link to="/learning/billing">
            <SvgIcon name="payments" />
            <span class="link-name">Оплата</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/learning/billing">Оплата</router-link></li>
          </ul>
        </li>
        <li>
          <button @click="showHelpModal">
            <SvgIcon name="help" />
            <span class="link-name">Помощь</span>
          </button>
          <ul class="sub-menu blank">
            <li><button class="link-name">Помощь</button></li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
  <div class="content">
    <Header />
    <slot />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import Header from '../../uiClient/layouts/Header.vue'

const sidebarState = ref('')
const state = () => {
  if (localStorage.getItem('sidebar_state') == null) {
    localStorage.setItem('sidebar_state', false)
    return false
  } else if (localStorage.getItem('sidebar_state') === 'true') return true
  else if (localStorage.getItem('sidebar_state') === 'false') return false
}
const isActive = () => {
  if (state()) sidebarState.value = 'close'
  else sidebarState.value = ''
}

isActive()

const toggleSidebar = () => {
  if (localStorage.getItem('sidebar_state') === 'true') localStorage.setItem('sidebar_state', false)
  else localStorage.setItem('sidebar_state', true)
  isActive()
}
onMounted(() => {
  const arrow = document.querySelectorAll('.arrow')
  for (var i = 0; i < arrow.length; i++) {
    arrow[i].addEventListener('click', (e) => {
      const arrowParent = e.target.parentElement.parentElement
      const arrowParentParent = e.target.parentElement.parentElement.parentElement
      arrowParent.classList.toggle('show-menu')
      arrowParentParent.classList.toggle('show-menu')
    })
  }
  // const sidebar = document.querySelector('.sidebar')
  // const sidebarBtn = document.querySelector('.toggle-menu')
  // sidebarBtn.addEventListener('click', () => {
  //   sidebar.classList.toggle('close')
  // })
})
</script>

<style scoped>
a.router-link-active .link-name {
  color: #0082FA!important;
  opacity: 1;
}

a.router-link-active svg {
  fill: #0082FA!important;
}
.sidebar.close .nav-links .router-link-active {
  background: #06050c;
  transition: all .2s ease;
}
.sidebar.close .nav-links .sub-menu.blank .link-name:hover {
  background: transparent!important;
}
.sidebar.close .nav-links .sub-menu.blank .router-link-active {
  background: none;
  color: var(--accent-color);
}
.content {
  position: relative;
  left: 260px;
  transition: all .5s ease;
  max-width: calc( 100% - 260px);
  box-sizing: border-box;
  overflow-y: overlay;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  background: #161931;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.5s ease;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.sidebar.close {
  width: 78px;
}
.sidebar.close ~ .content {
  left: 78px;
  transition: all .5s ease;
  max-width: calc( 100% - 78px);
  position: relative;
}
.sidebar.close .nav-links{
  overflow: visible;
}
.sidebar.close .logo-name {
  display: none;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 26px;
}
.sidebar .logo-details svg {
  fill: #fff;
  cursor: pointer;
}
.sidebar .logo-details .logo-back {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .logo-back:hover .back {
  opacity: 1;
  pointer-events: all;
}
.sidebar .logo-details .logo-back:hover .logo-name {
  transform: translateX(30px);
}
.sidebar .logo-details .logo-back .back {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: all .2s ease;
}
.sidebar .logo-details .logo-name {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  border-bottom: 2px solid #fff;
  white-space: pre;
  transition: all .2s ease;
}
.sidebar .nav-links {
  height: 100%;
  padding-top: 30px;
  overflow-x: hidden;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all .4s ease;
}
.sidebar .nav-links li:hover {
  background: #06050c;
}
.sidebar .nav-links li .link-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar .nav-links li a,
.sidebar .nav-links li button {
  display: flex;
  align-items: center;
}
.sidebar .nav-links li svg {
  min-width: 78px;
  fill: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li svg.arrow {
  min-width: 0;
  margin-right: 26px;
}
.sidebar .nav-links li.show-menu svg.arrow {
  transform: rotate(-180deg);
}
.sidebar .nav-links li .arrow {
  min-width: 0;
  margin-right: 26px;
}
.sidebar.close .nav-links li svg.arrow {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .link-name {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  height: 70px;
  line-height: 70px;
  text-align: center;
  transition: all 0.4s ease;
  white-space: nowrap;
}
.sidebar .nav-links li .sub-menu a.router-link-active {
  opacity: 1;
}
.sidebar.close .nav-links li a .link-name {
  opacity: 0;
  pointer-events: none;
}
.sidebar.close .nav-links li .link-dropdown a {
  width: 78px;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.show-menu .sub-menu {
 display: block;
}
.sidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu .link-name { /* Чет не работатает */
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu .link-name {
  display: none;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 0;
  pointer-events: none;
  padding: 3px 20px 6px 16px;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
</style>
