<template>
<div>
  <h2>Введите номер телефона. Туда придет одноразовый пароль</h2>
  <Form
      @finish="rememberPassword"
      class="form-remember-password"
    >
      <Input
        type="tel"
        name="phone"
        placeholder="Номер телефона"
        required
        validation="phone"
      />
      <Button name="Далее" htmlType="submit" :loading="loading"/>
      <a @click="$router.back()" class="back">Назад</a>
    </Form>
</div>
</template>

<script setup>
import { ref } from 'vue-demi'
import { useReCaptcha } from 'vue-recaptcha-v3'
import api from '@/modules/api'
import { useToast } from '@/modules/toast.js'
import formatPhone from '@/helpers/formatPhone.js'
import Form from '@/components/common/Form.vue'

const emit = defineEmits(['success'])
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
const { toast } = useToast()

const loading = ref(false)

const recaptcha = async () => {
  await recaptchaLoaded()
  return await executeRecaptcha('login')
}

const rememberPassword = async ({ phone }) => {
  loading.value = true
  api.requestPost(
    '/api/registration/remember-password',
    {
      phone_number: formatPhone(phone),
      recaptcha_token: await recaptcha()
    },
    response => {
      toast({
        message: 'На номер ' + phone + ' отправлен SMS код'
      })
      loading.value = false
      emit('success')
    },
    () => {
      loading.value = false
    }
  )
}
</script>

<style scoped>
h2 {
  margin-bottom: 36px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #161931;
  display: block;
  text-align: center;
}

.form-remember-password {
  display: grid;
  gap: 10px;
}

.form-remember-password button[type='submit'],
.form-remember-password a.back,
.login {
  justify-content: center;
  height: 52px;
  grid-column-start: 1;
  width: 100%;
}
.form-remember-password a.back {
  background: #0082fa;
  border-radius: 3px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form-remember-password a.back {
  background-color: #161931;
  opacity: .2;
  transition: opacity .2s linear;
}
.form-remember-password a.back:hover {
  opacity: 1;
}
</style>
