<template>
  <div class="main" :style="checkCode ? 'filter: blur(10px);' : ''">
    <SvgIcon
      class="close"
      name="x"
      :viewboxHeight="14"
      :viewboxWidth="21"
      :width="24"
      :height="24"
      @click="close"
    />
    <div class="container">
      <router-link to="/" class="logo">
        <SvgIcon name="logo" :viewboxWidth="149" :width="149" :viewboxHeight="39" :height="39" />
      </router-link>
      <transition mode="out-in">
        <UpdatePassword v-if="disposable"/>
        <Registration
          v-else-if="pageState === 'register'"
          @check="checkCode = true"
          @success="checkCode = false"
          @error="checkCode = false"
        />
        <Login
          v-else-if="pageState === 'login'"
          @disposable="disposable = true"
        />
        <RememberPassword
          v-else-if="pageState === 'remember'"
          @success="pageState = 'login'"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import router from '@/router/index.js'
import { useRoute } from 'vue-router'
import { onUnmounted, watch } from 'vue-demi'

import Registration from './Registration.vue'
import Login from './Login.vue'
import UpdatePassword from '@/components/UpdatePassword.vue'
import RememberPassword from './RememberPassword.vue'

export default {
  name: 'Authorization',
  components: {
    UpdatePassword,
    Registration,
    Login,
    RememberPassword
  },
  props: {},
  beforeRouteEnter: (to, from) => {
    if (from.fullPath !== '/' && !localStorage.getItem('savedRoute')) localStorage.setItem('savedRoute', from.fullPath)
  },
  setup () {
    // ====================  VARIABLES  ====================

    const route = useRoute()
    const disposable = ref(false)
    const checkCode = ref(false)
    // ====================  LOGIN OR REGISTER  ====================

    const close = () => {
      router.push(localStorage.getItem('savedRoute') ? localStorage.getItem('savedRoute') : '/')
    }
    const pageState = ref('register')

    const changePageState = () => {
      if (route.params.logOrReg === 'login') pageState.value = 'login'
      else if (route.params.logOrReg === 'register') pageState.value = 'register'
      else if (route.params.logOrReg === 'remember-password') pageState.value = 'remember'
    }

    const goLogin = () => {
      pageState.value = 'login'
    }
    const goReg = () => {
      pageState.value = 'register'
    }

    changePageState()

    watch(
      () => route.params.logOrReg,
      () => changePageState()
    )

    onUnmounted(() => {
      localStorage.removeItem('savedRoute')
    })

    return {
      goLogin,
      goReg,
      close,
      pageState,
      disposable,
      checkCode
    }
  }
}
</script>
<style scoped>
.main {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all .5s ease;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.container {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  padding: 30px 0;
}
.logo {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 50px;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}
.v-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
</style>
