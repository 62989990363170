import api, { appUrl } from '@/modules/api'
import { useRouter } from 'vue-router'

const router = useRouter()

export const addToOrders = async courseId => {
  return (await api.asyncPost(
    '/api/orders',
    {
      subscription_id: 4,
      payable_id: courseId
    }
  )).data.data.id
}

export const deleteFromOrders = async courseId =>
  api.asyncDelete(`/api/orders/${courseId}`)

export const getBasket = async (userId) => {
  return (await api.asyncGet('/api/orders', {
    finished: 0,
    user_id: userId
  })).data.data
}

export const goToPayment = async courseId => {
  const orderId = await addToOrders(courseId)

  const redirectUrl = (await api.asyncPost(
    '/api/payments/paybox/init-payment',
    {
      order_id: orderId,
      success_url: `${appUrl}/learning/my-courses`,
      failure_url: `${appUrl}/course/${courseId}`
    }
  )).data.data
  window.location.href = redirectUrl.redirect_url
}

export const goGetCourse = async courseId => {
  await api.asyncPost(
    `/api/courses/${courseId}/enroll`,
    { role: 'student' }
  )
  return router.push('/learning/my-courses')
}

export const payWithEpay = async courseId => {
  const orderId = await addToOrders(courseId)

  const { postlink_url: redirectUrl, epay_token: token } = await api.asyncPost(
    '/api/payments/epay/pay',
    {
      order_id: orderId
      // success_url: `${appUrl}/learning/my-courses`,
      // failure_url: `${appUrl}/course/${courseId}`
    }
  )
  console.log(token)
  window.location.href = `${redirectUrl}`
}

export const enrollToFreeCourse = async courseId =>
  await api.asyncPost(
    `/api/courses/${courseId}/enroll`,
    {
      role: 'student'
    }
  )
